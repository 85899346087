import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faPercent, faSave, faTimes, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Card, Form, Button, ButtonGroup, OverlayTrigger, Tooltip, InputGroup } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';

//actions
import { calificarMateria, modificarCalificacion } from '../../redux/actions/Materias.actions';
//end-actions

/* Componentes */
import ReporteTareas from './ReporteTareas';
import ReporteCalificaciones from './ReporteCalificaciones';
import SessionExpireLabel from '../recursos/SessionExpireLabel';
/* end Componentes */

const Calificaciones = () => {

    const materia = useSelector(state => state.Materias.materia);

    const dispatch = useDispatch();
    const history = useHistory();
    const refReportTareas = useRef();
    const refReportCalificaciones = useRef();

    const handlePrintTareas = useReactToPrint({
        content: () => refReportTareas.current,
        documentTitle: 'Calificación tareas',
        pageStyle: () => {
           return  "@page { size: auto; margin:s 20mm }"
        }
    });

    const handlePrintCalificaciones = useReactToPrint({
        content: () => refReportCalificaciones.current,
        documentTitle: 'Calificaciones finales',
        pageStyle: () => {
           return  "@page { size: auto; margin:s 20mm }"
        }
    });

    const [cargaId, setCargaId] = useState(null);
    const [calificacion, setCalificacion] = useState(null);
    const [observaciones, setObservaciones] = useState(null)
    const [validacion, setValidacion] = useState(false);


    const handleCalificacion = (calificacion) => {
        
        if ( calificacion !== '' ) {
            setCalificacion( Number(calificacion) );

            if (Number(calificacion) >= 0 && Number(calificacion) <= 10) {
                setValidacion(false);
            } else {
                setCalificacion( null );
                setValidacion(true);
            } 

        } else {

            setCalificacion( null );
            setValidacion(false);

        }

    }

    const registrarCalificacion = (carga_id) => {

        if(calificacion !== null) {
            dispatch( calificarMateria(carga_id, calificacion, observaciones) );
            setCalificacion( null );
            setValidacion(false);
            setCargaId(null);
        } else {
            setValidacion(true);
        }

    }

    const modificarCalificacionFront = (carga_id) => {
        
        if(calificacion !== null) {
            dispatch( modificarCalificacion(carga_id, calificacion, observaciones) );
            setCalificacion( null );
            setValidacion(false);
            setCargaId(null);
        } else {
            setValidacion(true);
        }

    }

    return ( 
        <>
        <title>CCH | Calificaciones</title>
        <div className="row animated fadeIn">
            <div className="col-lg-12 mt-3">
                    <div className="container-fluid">
                        <div className="row ">
                            <div className="col-lg-12">
                                <div class="callout callout-info">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h5><FontAwesomeIcon icon={faPercent}/> Calificaciones</h5>
                                        </div>
                                        <div className="col-lg-12">
                                            <strong>Materia:</strong> {materia && materia.materia_nombre.nombre_materia}
                                        </div>
                                        <div className="col-lg-12">
                                            <strong>Grupo:</strong> {materia && materia.grupo.nombre}
                                        </div>
                                        <div className="col-lg-12">
                                            <strong>Sede:</strong> {materia && materia.sede.nombre_sede}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <div className="col-lg-12">
                <hr></hr>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-12 mb-3">
                <SessionExpireLabel/>
                <button className="btn btn-link float-right" onClick={() => history.push('/cursos/materia')}>
                    <FontAwesomeIcon icon={faArrowLeft}/> Volver
                </button>
            </div>
            <div className="col-lg-12">
                
                <Card className="shadow">
                    <Card.Header>
                        <Card.Title>Calificaciones Tareas</Card.Title>
                        <div class="card-tools">
                            <OverlayTrigger overlay={<Tooltip id={(1000).toString()}>Descargar PDF</Tooltip>}>
                                <button class="btn btn-tool btn-sm" onClick={handlePrintTareas}>
                                    <i class="fas fa-download"></i>
                                </button>
                            </OverlayTrigger>
                            <button type="button" class="btn btn-tool" data-card-widget="maximize">
                                <i class="fas fa-expand"></i>
                            </button>
                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                <i class="fas fa-minus"></i>
                            </button>
                        </div>
                    </Card.Header>
                    <Card.Body className="table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <thead>
                            <tr>
                                <th>Nombre</th>
                                {materia.cargas.length > 0 ? materia.cargas[0].tareas.map(tarea => {
                                    return (
                                        <th className="text-center">Tarea {tarea.no_tarea}</th>
                                    )
                                }) : null}                    
                            </tr>
                            </thead>
                            <tbody>
                            
                                {materia.cargas.map(carga => {
                                    return (
                                        <tr>
                                            <td>
                                                {carga.alumno.nombre} {carga.alumno.apellido_paterno} {carga.alumno.apellido_materno}
                                            </td>
                                            {carga.tareas.map(tarea => {
                                                return (<td className="text-center">{tarea.calificacion ? tarea.calificacion : '0.0'}</td>)
                                            })}
                                        </tr>
                                    )
                                })} 
                            </tbody>
                        </table>
                        <div hidden>
                            <ReporteTareas materia={materia} refReportTareas={refReportTareas}/>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <div className="col-lg-12">
                <Card className="shadow">
                    <Card.Header>
                        <Card.Title>Calificaciones Finales</Card.Title>
                        <div class="card-tools">
                            <OverlayTrigger overlay={<Tooltip id={(1000).toString()}>Descargar PDF</Tooltip>}>
                                <button class="btn btn-tool btn-sm" onClick={handlePrintCalificaciones}>
                                    <i class="fas fa-download"></i>
                                </button>
                            </OverlayTrigger>
                            <button type="button" class="btn btn-tool" data-card-widget="maximize">
                                <i class="fas fa-expand"></i>
                            </button>
                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                <i class="fas fa-minus"></i>
                            </button>
                        </div>
                    </Card.Header>
                    <Card.Body className="table-responsive p-0">
                        
                    <table class="table table-striped table-valign-middle">
                            <thead>
                            <tr>
                                <th style={{ width: '40%' }}>Nombre</th>
                                <th className="text-center" style={{ width: '20%' }}>Calificación</th>
                                <th className="text-center" style={{ width: '20%' }}>Observaciones</th> 
                                <th style={{ width: '20%' }}></th>          
                            </tr>
                            </thead>
                            <tbody>
                            
                                {materia.cargas.map(carga => {
                                    return (
                                        <tr>
                                            <td>
                                                {carga.alumno.nombre} {carga.alumno.apellido_paterno} {carga.alumno.apellido_materno}
                                            </td>
                                            <td className="text-center">
                                                {(cargaId !== null && cargaId === carga.id) ? 
                                                <Form>
                                                    <Form.Group className="mb-3" controlId="calificacionId">
                                                    <InputGroup hasValidation>
                                                        <Form.Control 
                                                            type="number" 
                                                            placeholder="Ingrese la calificación" 
                                                            name="calificacion"
                                                            onChange={(e) => handleCalificacion(e.target.value)}
                                                            value={calificacion !== null ? calificacion : ''}
                                                            isInvalid={validacion}
                                                            size="sm"
                                                        />
                                                        <Form.Control.Feedback type="invalid" tooltip>El valor ingresado no es una calificación válida.</Form.Control.Feedback>
                                                    </InputGroup>
                                                    </Form.Group>
                                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                                        <Form.Control as="textarea" rows={3} placeholder="Observaciones" size="sm" onChange={(e) => setObservaciones(e.target.value)}/>
                                                    </Form.Group>
                                                </Form>
                                                
                                                : 
                                                carga.calificaciones.length > 0 ?
                                                    carga.calificaciones[0].calificacion 
                                                : '0.0'}
                                            </td>
                                            <td className="text-center">{carga.calificaciones.length > 0 ? carga.calificaciones[0].observaciones : '--'}</td>
                                            <th className="text-center">
                                            <ButtonGroup size="sm">
                                                {(carga.calificaciones.length === 0 && cargaId === carga.id) ? 
                                                <OverlayTrigger overlay={<Tooltip id={carga.id}>Registrar Calificación</Tooltip>}>
                                                    <Button variant="primary" onClick={() => registrarCalificacion(carga.id)} disabled={validacion}><FontAwesomeIcon icon={faSave}/> Registrar</Button>
                                                </OverlayTrigger>
                                                : true}
                                                {(carga.calificaciones.length > 0 && cargaId === carga.id) ? 
                                                <OverlayTrigger overlay={<Tooltip id={carga.id}>Modificar Calificación</Tooltip>}>
                                                    <Button variant="warning" onClick={() => modificarCalificacionFront(carga.id)} disabled={validacion}><FontAwesomeIcon icon={faEdit}/> Modificar</Button>
                                                </OverlayTrigger>
                                                : false}
                                                {cargaId !== carga.id ?
                                                    <Button variant="secondary" onClick={() => setCargaId(carga.id)}><FontAwesomeIcon icon={faEye}/> Ver opciones</Button> 
                                                : null}
                                                {(cargaId !== null && carga.id === cargaId) ?
                                                    <OverlayTrigger overlay={<Tooltip id={carga.id}>Cancelar</Tooltip>}>
                                                        <Button variant="danger" onClick={() => {setCargaId(null); setValidacion(false); setCalificacion(null); } }><FontAwesomeIcon icon={faTimes}/> Cancelar</Button>
                                                    </OverlayTrigger>
                                                : null}
                                                
                                            </ButtonGroup>
                                            </th>
                                        </tr>
                                    )
                                })} 
                            </tbody>
                        </table>
                        <div hidden>
                            <ReporteCalificaciones 
                                materia={materia} 
                                refReportCalificaciones={refReportCalificaciones}
                            />
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </div>
        </>
     );
}
 
export default Calificaciones;