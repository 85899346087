import React from 'react';
import { Card } from 'react-bootstrap';

const ReporteTareas = ({ materia, refReportTareas }) => {
    return ( 
        <>
            <div className="container" ref={refReportTareas}>
                <div className="row">
                    <div className="col-lg-12 mb-3 text-center">
                        <img src="/img/cch.jpg" alt="..." width="150"/> 
                    </div>
                    <div className="col-lg-12 mb-3 text-center">
                        <h3>Centro de Crecimiento Humanista</h3>
                        <h5 className="text-muted">REPORTE CALIFICACIONES TAREAS</h5>
                        <h6 className="text-muted">{materia.programa.nombre_programa}</h6>
                        <h6 className="text-muted">{materia.sede.nombre_sede}</h6>
                        <h6 className="text-muted">{materia.grupo.nombre}</h6>
                    </div>
                    <div className="col-lg-12 mb-3">
                        <hr></hr>
                    </div>
                    <div className="col-lg-12" >
                        <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                {materia.cargas.length > 0 ?materia.cargas[0].tareas.map(tarea => {
                                    return (
                                        <th className="text-center">Tarea {tarea.no_tarea}</th>
                                    )
                                }) : null}                    
                            </tr>
                            </thead>
                            <tbody>
                                {materia.cargas.map(carga => {
                                    return (
                                        <tr>
                                            <td>
                                                {carga.alumno.nombre} {carga.alumno.apellido_paterno} {carga.alumno.apellido_materno}
                                            </td>
                                            {carga.tareas.map(tarea => {
                                                return (<td className="text-center">{tarea.calificacion ? tarea.calificacion : '0.0'}</td>)
                                            })}
                                        </tr>
                                    )
                                })} 
                            </tbody>
                        </table>  
                    </div>
                </div>
                
            </div>
        </> 
    );
}
 
export default ReporteTareas;