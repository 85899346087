import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RESOURCE_URL } from '../recursos/url';


const AlumnosEdit = props => {

    const { open, vistaPrevia, alumno, alumnoValidacion } = props;
    const { setOpenModalAlumno, changeImg, handleChangeAlumno, guardarDatosAlumno, handleChangeFiles } = props;
    const loading = useSelector(state => state.Alumnos.loading);

    return (
        <Modal
            size="lg"
            show={open}
            onHide={() => setOpenModalAlumno(false) }
            aria-labelledby="example-modal-sizes-title-lg"
            scrollable
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
            <h4><i className="fas fa-user"></i> {alumno !== null ? alumno.id !== null ? 'Modificar' : 'Registrar' : 'Registrar'} Datos del Alumno</h4>
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ?
                    <div className="alert alert-info text-center" style={{ opacity: 0.5 }}>
                        <i className="fas fa-spinner fa-spin"></i> Guardando información, por favor espere...
                    </div>
                : null}
                <form>
                    <div class="form-row">
                        <div class="form-group col-lg-2 text-center">
                            {vistaPrevia !== null ? 
                            <img src={vistaPrevia} style={{ width: '90px', height: '90px' }} className='img-circle elevation-2'></img>
                            : 
                            (alumno.foto !== null && vistaPrevia === null) ?
                                <img src={`${RESOURCE_URL}/img/doc_alumno/${alumno.user_id}/${alumno.foto}`} style={{ width: '90px', height: '90px' }}
                                className='img-circle elevation-2 mx-auto'></img>
                            :
                            <img src={`${RESOURCE_URL}/img/perfil.png`} style={{ width: '90px', height: '90px' }} className='img-circle elevation-2'></img>
                            }
                        </div>
                        <div class="form-group col-lg-10">
                            <label for="foto">Foto del alumno</label>
                            <div class="custom-file">
                                <input 
                                    type="file" 
                                    className="custom-file-input" 
                                    id="foto" 
                                    name="foto" 
                                    lang="es"
                                    onChange={e => changeImg(e)}
                                    disabled={loading}
                                />
                                <label class="custom-file-label" for="foto">
                                    {alumno !== null ? 
                                        (alumno.foto !== null && alumno.foto !== {}) ? 
                                            alumno.foto.name ? alumno.foto.name : alumno.foto
                                        : 'Seleccionar archivo'
                                    : 'Seleccionar archivo'}
                                </label>
                            </div> 
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-lg-4">
                            <label for="nombre">Nombre *</label>
                            <input 
                                type="text" 
                                className={`form-control ${alumnoValidacion.nombre ? 'is-invalid' : ''}`} 
                                id="nombre" 
                                name="nombre" 
                                placeholder="Nombre" 
                                value={alumno !== null && alumno.nombre !== null ? alumno.nombre : ''}
                                onChange={e => handleChangeAlumno(e)}
                                disabled={loading}  
                            />
                            {alumnoValidacion.nombre ? 
                            <div className="text-danger text-sm">
                            Este campo es obligatorio.
                            </div>
                            : null }
                        </div>
                        <div class="form-group col-lg-4">
                            <label for="apellido_paterno">Apellido Paterno *</label>
                            <input 
                                type="text" 
                                className={`form-control ${alumnoValidacion.apellidoP ? 'is-invalid' : ''}`} 
                                id="apellido_paterno" 
                                name="apellido_paterno" 
                                placeholder="Apellido Paterno" 
                                onChange={e => handleChangeAlumno(e)}
                                value={alumno !== null && alumno.apellido_paterno !== null ? alumno.apellido_paterno : ''}
                                disabled={loading}
                            />
                            {alumnoValidacion.apellidoP ? 
                            <div className="text-danger text-sm">
                                Este campo es obligatorio.
                            </div>
                            : null}
                        </div>
                        <div class="form-group col-lg-4">
                            <label for="apellido_materno">Apellido Materno</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                id="apellido_materno" 
                                name="apellido_materno" 
                                placeholder="Apellido Materno" 
                                onChange={e => handleChangeAlumno(e)}
                                value={alumno !== null && alumno.apellido_materno !== null ? alumno.apellido_materno : ''}
                                disabled={loading}    
                            />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group input-group col-lg-6">
                            <label for="email">Correo electrónico *</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fa fa-envelope"></i></span>
                                </div>
                                <input 
                                    type="email" 
                                    className={`form-control ${alumnoValidacion.correo ? 'is-invalid' : ''}`} 
                                    id="email" 
                                    name="email" 
                                    placeholder="Correo electrónico" 
                                    onChange={e => handleChangeAlumno(e)}
                                    value={alumno !== null && alumno.email !== null ? alumno.email : ''}
                                    disabled={loading} 
                                />
                            </div>
                            
                            {alumnoValidacion.correo ? 
                            <div className="text-danger text-sm">
                                Este campo es obligatorio.
                            </div>
                            : null}
                            {alumnoValidacion.correoInvalido ? 
                            <div className="text-danger text-sm">
                                Correo invalido.
                            </div>
                            : null}
                        </div>
                        <div class="form-group input-group col-lg-6">
                            <label for="telefono">Teléfono</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fas fa-phone"></i></span>
                                </div>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    id="telefono" 
                                    name="telefono" 
                                    placeholder="Teléfono" 
                                    onChange={e => handleChangeAlumno(e)}
                                    value={alumno !== null && alumno.telefono !== null ? alumno.telefono : ''}
                                    disabled={loading}
                                    data-inputmask='"mask": "(999) 999-9999"' data-mask
                                    maxlength="10"
                                />
                            </div>
                            
                        </div>
                        
                    </div>
                    
                    <div class="form-group">
                        <label for="acta_nacimiento">Acta de nacimiento</label>
                        <div class="custom-file">
                            <input 
                                type="file" 
                                className="custom-file-input" 
                                id="acta_nacimiento" 
                                name="acta_nacimiento" 
                                lang="es"
                                onChange={handleChangeFiles}
                                disabled={loading}
                            />
                            <label class="custom-file-label" for="fileActa">
                                {alumno !== null ? 
                                    (alumno.acta_nacimiento !== null && alumno.acta_nacimiento !== {}) ? 
                                        alumno.acta_nacimiento.name ? alumno.acta_nacimiento.name : alumno.acta_nacimiento
                                    : 'Seleccionar archivo'
                                : 'Seleccionar archivo'}
                            </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="curp">CURP</label>
                        <div class="custom-file">
                            <input 
                                type="file" 
                                className="custom-file-input" 
                                id="curp" 
                                name="curp" 
                                lang="es"
                                onChange={handleChangeFiles}
                                disabled={loading}    
                            />
                            <label class="custom-file-label" for="curp">
                                {alumno ? 
                                    (alumno.curp !== null) ? 
                                        alumno.curp.name ? alumno.curp.name : alumno.curp
                                    : 'Seleccionar archivo'
                                : 'Seleccionar archivo'}
                            </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="titulo">Título</label>
                        <div class="custom-file">
                            <input 
                                type="file" 
                                className="custom-file-input" 
                                id="titulo" 
                                name="titulo" 
                                lang="es"
                                onChange={handleChangeFiles}
                                disabled={loading}
                            />
                            <label class="custom-file-label" for="titulo">
                                {alumno !== null ? 
                                    (alumno.titulo !== null && alumno.titulo !== {}) ? 
                                        alumno.titulo.name ? alumno.titulo.name : alumno.titulo
                                    : 'Seleccionar archivo'
                                : 'Seleccionar archivo'}
                            </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="certificado">Certificado</label>
                        <div class="custom-file">
                            <input 
                                type="file" 
                                className="custom-file-input" 
                                id="certificado" 
                                name="certificado" 
                                lang="es"
                                onChange={handleChangeFiles}
                                disabled={loading}    
                            />
                            <label class="custom-file-label" for="certificado">
                                {alumno !== null ? 
                                    (alumno.certificado !== null && alumno.certificado !== {}) ? 
                                        alumno.certificado.name ? alumno.certificado.name : alumno.certificado
                                    : 'Seleccionar archivo'
                                : 'Seleccionar archivo'}
                            </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="certificado">Cédula</label>
                        <div class="custom-file">
                            <input 
                                type="file" 
                                className="custom-file-input" 
                                id="cedula" 
                                name="cedula" 
                                lang="es"
                                onChange={handleChangeFiles}
                                disabled={loading}    
                            />
                            <label class="custom-file-label" for="cedula">
                                {alumno !== null ? 
                                    (alumno.cedula !== null && alumno.cedula !== {}) ? 
                                        alumno.cedula.name ? alumno.cedula.name : alumno.cedula
                                    : 'Seleccionar archivo'
                                : 'Seleccionar archivo'}
                            </label>
                        </div>
                    </div>
                    
                </form>  
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={() => setOpenModalAlumno(false) }><i className="fa fa-times"></i> Cancelar</button>
                <button type="button" className="btn btn-success" onClick={ guardarDatosAlumno } disabled={alumnoValidacion.correoInvalido || loading}><i className="fa fa-save"></i> Guardar</button>
            </Modal.Footer>
        </Modal>
    ); 
}

export default AlumnosEdit;
