import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCloudUploadAlt, faCoffee, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, Form, Modal, InputGroup } from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';

/* actions */
import { calificarTarea } from '../../redux/actions/Tareas.action';
/* end actions */

const CalificarTarea = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const editorRef = useRef(null);

    /* const [texto, setTexto] = useState(null);
    const [archivo, setArchivo] = useState(null); */
    const [open, setOpen] = useState(false);
    const [calificacion, setCalificacion] = useState(null);
    const [validacion, setValidacion] = useState(false);

    const materia = useSelector(state => state.Materias.materia);
    const tarea = useSelector(state => state.Tareas.tarea);
    const alumno = useSelector(state => state.Alumnos.alumno);
    
    const handleCalificacion = (calificacion) => {
        
        if ( calificacion !== '' ) {
            setCalificacion( Number(calificacion) );

            if (Number(calificacion) >= 0 && Number(calificacion) <= 10) {
                setValidacion(false);
            } else {
                setCalificacion( null );
                setValidacion(true);
            } 

        } else {

            setCalificacion( null );
            setValidacion(false);

        }

    }

    const guardarCalificacion = () => {
        if(calificacion !== null) {
            dispatch( calificarTarea(tarea.id, calificacion, tarea.carga_id) );
            setCalificacion( null );
            setValidacion(false);
            history.push('/curso/alumno/tareas');
        } else {
            setValidacion(true);
        }
        
        
    }
    
    return ( 
        <>
        <title>CCH | Tarea</title>
        <div className="row animated fadeIn">
        
            <div className="col-lg-12">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row ">
                            <div className="col-lg-12">
                                <div class="callout callout-info">
                                <div className="row">
                                        <div className="col-lg-12 mb-1">
                                            <h5><FontAwesomeIcon icon={faCoffee}/> Calificar Tarea </h5>
                                        </div>
                                        <div className="col-lg-12">
                                            <strong>Alumno:</strong> {alumno.nombre} {alumno.apellido_paterno} {alumno.apellido_materno}
                                        </div>
                                        <div className="col-lg-12">
                                            <strong>Materia:</strong> {materia && materia.materia_nombre.nombre_materia}
                                        </div>
                                        <div className="col-lg-12">
                                            <strong>Grupo:</strong> {materia && materia.grupo.nombre}
                                        </div>
                                        <div className="col-lg-12">
                                            <strong>Sede:</strong> {materia && materia.sede.nombre_sede}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="col-lg-12">
                <hr></hr>
            </div>

            <div className="col-lg-12 mb-3">
                <button className="btn btn-link float-right" onClick={() => history.push('/curso/alumno/tareas')}>
                    <FontAwesomeIcon icon={faArrowLeft}/> Volver
                </button>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-12 mb-3">
                <Card>
                    <Card.Header>
                        {tarea.titulo}
                        {tarea.calificacion === null ?
                            (tarea.texto !== null || tarea.archivo !== null) ? 
                            <div class="card-tools">
                                <button class="btn btn-success btn-sm" onClick={() => setOpen(true)}>
                                    <FontAwesomeIcon icon={faCloudUploadAlt}/> Calificar Tarea
                                </button>
                            </div>
                            : null
                        : 
                        <div class="card-tools">
                            <button class="btn btn-warning btn-sm" onClick={() => setOpen(true)}>
                                <FontAwesomeIcon icon={faCloudUploadAlt}/> Modifcar calificación tarea
                            </button>
                        </div>}
                        
                    </Card.Header>
                    <Card.Body >
                        <div className="row">
                            <div className="col-lg-12 mt-3">
                                <strong>Fecha límite de entrega:</strong> {tarea.fecha_entrega}
                            </div>
                            <div className="col-lg-12">
                                <strong>Descripción:</strong><br></br>{tarea.descripcion}
                            </div>
                            {(tarea.archivo === null && tarea.texto === null ) &&
                            <div className="col-lg-12 text-center mb-3">
                                <hr></hr>
                                <h5>No se encontro información disponible.</h5>
                            </div>}
                            {tarea.texto !== null &&
                            <div className="col-lg-12">
                                <hr></hr>
                                <Editor 
                                onInit={(evt, editor) => editorRef.current = editor }
                                value={tarea.texto}
                                init={{
                                height: 400,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image fullscreen media charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount',
                                    
                                ],
                                toolbar: 'fullscreen | ',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                                />
                            </div>}
                            {tarea.archivo !== null &&
                            <div className="col-lg-12">
                                <hr></hr>
                                <strong>Archivos disponibles:</strong><br></br>
                                <ul class="mailbox-attachments d-flex align-items-stretch clearfix" >
                                    <li hidden={tarea.archivo !== null ? false : true}>
                                        <span class="mailbox-attachment-icon"><i class="far fa-file-pdf"></i></span>

                                        <div class="mailbox-attachment-info">
                                            <a href={`http://localhost:8001/img/doc_alumno/${alumno.user.id}/tareas/${tarea.archivo}`} target="_blank" class="mailbox-attachment-name"><i class="fas fa-paperclip"></i> {tarea.archivo}</a>
                                            <span class="mailbox-attachment-size clearfix mt-1">
                                            <span>1,245 KB</span>
                                            <a href={`http://localhost:8001/img/doc_alumno/${alumno.user.id}/tareas/${tarea.archivo}`} target="_blank" class="btn btn-default btn-sm float-right"><i class="fas fa-cloud-download-alt"></i></a>
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            }
                            
                        </div>
                    </Card.Body>
                   
                </Card>
                <Modal
                    show={open}
                    size="sm"
                    onHide={() => { setOpen(false); setCalificacion(null) } }
                    centered
                    >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        Calificar tarea
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                <Form>
                                    <Form.Group className="mb-3" controlId="calificacionId">
                                    <InputGroup hasValidation>
                                        <Form.Control 
                                            type="number" 
                                            placeholder="Ingrese la calificación" 
                                            name="calificacion"
                                            onChange={(e) => handleCalificacion(e.target.value)}
                                            value={calificacion !== null ? calificacion : ''}
                                            isInvalid={validacion}
                                            size="sm"
                                        />
                                        <Form.Control.Feedback type="invalid" tooltip>El valor ingresado no es una calificación válida.</Form.Control.Feedback>
                                    </InputGroup>
                                    </Form.Group>
                                </Form>
                                </div>
                            </div>
                            
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" size='sm' onClick={() => { setOpen(false); setCalificacion(null) }}><FontAwesomeIcon icon={faTimes}/> Cancelar</Button>
                        <Button variant="success" size='sm' onClick={guardarCalificacion}><FontAwesomeIcon icon={faSave}/> Guardar</Button>
                    </Modal.Footer>
                    </Modal>
            </div>
        </div>
        </> 
    );
}
 
export default CalificarTarea;