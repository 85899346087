import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity, faArrowRight, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { obtenerMateriasDocente, resetMaterias, obtenerDatosMateria } from '../../redux/actions/Materias.actions';
import { obtenerListaTareasMaterias } from '../../redux/actions/Tareas.action';
import { obtenerBanderaAsistencia, obtenerDiasCalificaciones } from '../../redux/actions/Asistencias.actions';
import { Card, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Encabezado from '../recursos/Encabezado';

const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
    } ) => (
    <div className="btn-group" role="group">
    {
        options.map((option) => {

        const isSelect = currSizePerPage === `${option.page}`;
        return (
            <button
                key={ option.text }
                type="button"
                onClick={ () => onSizePerPageChange(option.page) }
                className={ `btn ${isSelect ? 'btn-secondary' : 'btn-primary'}` }
            >
            { option.text }
            </button>
        );
        })
    }
    </div>
);

const options = {
    sizePerPageRenderer
}

const { SearchBar } = Search;

const Cursos = () => {

    const { materiasList, loadingList } = useSelector(state => state.Materias);
    const { docente } = useSelector(state => state.Docentes);
    
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        if (docente !== null) {
            dispatch( resetMaterias() );
            dispatch( obtenerMateriasDocente(docente.id) ); 
        }
    }, []);

    useEffect(() => {
       
        return () => {
            dispatch( resetMaterias() );
        }
    }, []);

    const selectMateria = (id) => {
        dispatch( obtenerDatosMateria(id) );
        dispatch( obtenerListaTareasMaterias(id) );
        dispatch( obtenerBanderaAsistencia(id) );
        dispatch( obtenerDiasCalificaciones(id) );
        history.push('/cursos/materia');
    };

    const afterSearch = (newResult) => {
        console.log(newResult);
    };

    const columns = [ {
        dataField: 'nombre',
        text: 'Materia',
        sort: true,
        align: 'left',
        headerAlign: 'center'
      }, {
        dataField: 'programa',
        text: 'Programa',
        sort: true,
        align: 'left',
        headerAlign: 'center',
      },{
        dataField: 'sede',
        text: 'Sede',
        sort: true,
        align: 'left',
        headerAlign: 'center'
      }, {
        dataField: 'grupo',
        text: 'Grupo',
        sort: true,
        align: 'left',
        headerAlign: 'center'
      }, {
        dataField: '3',

        text: 'Acciones',
        sort: true,
        align: 'center', 
        headerAlign: 'center',
        formatter: (cell, row) => {
            return (
                   
                <Button variant="primary" onClick={() => selectMateria(row.materia_id)} size="sm">
                    Entrar <FontAwesomeIcon icon={faArrowCircleRight} />
                </Button>
                   
            );
        }
      }];

    return ( 
        <>
        <title>CCH | Cursos</title>
        <Encabezado/>
        <div className="row animated fadeIn">
            <div className="col-lg-12">
                <Card className="shadow">
                    <Card.Body>
                        <ToolkitProvider
                        keyField="materia_id"
                        data={ materiasList }
                        columns={ columns }
                        search={ { afterSearch } }
                        >
                        {
                            props => (
                            <div className="">
                                <SearchBar { ...props.searchProps } placeholder="Buscar"/>
                                <hr />
                                <BootstrapTable
                                classes="table table-striped table-hover" 
                                { ...props.baseProps }
                                pagination={ paginationFactory(options) }
                                noDataIndication={loadingList ? "Cargando información, por favor espere..." : "No se encontrarón resultados."}
                                />
                            </div>
                            )
                        }
                        </ToolkitProvider>
                    </Card.Body>
                </Card>
            </div>
        </div>
        </>
     );
}
 
export default Cursos;