import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity, faUserClock, faCalendar, faCoffee, faCheckSquare, faTasks, faPercent, faClock, faBookOpen  } from '@fortawesome/free-solid-svg-icons';

const MenuDocente = ({ date }) => {

    const location = useLocation();
    const { materia } = useSelector(state => state.Materias)
    return ( 
        <>
            {/* <ul className="nav nav-pills nav-sidebar flex-column nav-legacy nav-child-indent" data-widget="treeview" role="menu" data-accordion="false"> */}
                
                <li className="nav-item">
                  <Link to="/cursos" className={`nav-link ${location.pathname === '/cursos' ? 'active' : null} `}>
                    <FontAwesomeIcon icon={faUniversity} className="nav-icon"/>
                    <p>
                      Cursos
                    </p>           
                  </Link>
                </li>
                <li class="nav-item menu-is-opening menu-open" hidden={materia !== null ? false : true}>
                
                    <a href="#" className={`nav-link ${location.pathname === '/cursos/materia' ? 'active' : null} ${location.pathname === '/cursos/materia/tareas' ? 'active' : null}`}>
                        <p>
                            <FontAwesomeIcon icon={faBookOpen} className="nav-icon"/>  
                            <p>Materia</p>
                            <i class="right fas fa-angle-left"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview" style={{ display: 'block' }}>
                        <li class="nav-item">
                            <Link to="/cursos/materia/tareas" className={`nav-link ${location.pathname === '/cursos/materia/tareas' ? 'active' : null} `}>
                              <FontAwesomeIcon icon={faCoffee} className="nav-icon"/>
                              <p>
                              Tareas
                              </p>           
                            </Link>
                        </li>
                        <li class="nav-item">
                            <Link to="/cursos/materia/asistencia" className={`nav-link ${location.pathname === '/cursos/materia/asistencia' ? 'active' : null} `}>
                              <FontAwesomeIcon icon={faTasks} className="nav-icon"/>
                              <p>
                              Reporte de Asistencia
                              </p>           
                            </Link>
                        </li>
                        <li class="nav-item">
                            <Link to="/cursos/materia/calificaciones" className={`nav-link ${location.pathname === '/cursos/materia/calificaciones' ? 'active' : null} `}>
                              <FontAwesomeIcon icon={faPercent} className="nav-icon"/>
                              <p>Calificaciones</p>           
                            </Link>
                        </li>
                    </ul>
                </li>
                <li className="nav-item">
                <Link to="/docente/horario" className={`nav-link ${location.pathname === '/docente/horario' ? 'active' : null} `}>
                  <FontAwesomeIcon icon={faUserClock} className="nav-icon"/>
                  <p>
                    Horarios
                  </p>           
                </Link>
                </li>
                <li className="nav-item">
                <Link to="/docente/calendario" className={`nav-link ${location.pathname === '/docente/calendario' ? 'active' : null} `}>
                    <FontAwesomeIcon icon={faCalendar} className="nav-icon"/>
                    <p>
                      Calendario
                    </p>           
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link">
                    <FontAwesomeIcon icon={faClock} className="nav-icon"/>
                    <p>
                    {date.toLocaleTimeString('en-US')}
                    </p>
                  </a>
                </li>
                
              {/* </ul> */}
        </>
     );
}
 
export default MenuDocente;