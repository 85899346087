import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCalendarTimes, faCheck, faTasks, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import SessionExpireLabel from '../recursos/SessionExpireLabel';
import { RESOURCE_URL } from '../recursos/url';

const MateriaAsistencia = () => {

    const { materia } = useSelector(state => state.Materias);
    const componentRef = useRef();
    const history = useHistory();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Lista de Asistencia',
        pageStyle: () => {
           return  "@page { size: auto; margin:s 20mm }"
        }
    });
    const getAsistencia = (asistencias, fechaModulo) => {
        let asistencia = null;
        let f = new Date();
        if (asistencias.length > 0) {
            asistencias.forEach(asist => {
                if (asist.fecha === fechaModulo) {
                    asistencia = asist.tipo;
                }
            });
        }

        return asistencia;

    }
    return ( 
        <>
        <title>CCH | Asistencia</title>
        <div className="row animated fadeIn">
            <div className="col-lg-12">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row ">
                            <div className="col-lg-12">
                                <div class="callout callout-info">
                                <div className="row">
                                        <div className="col-lg-12 mb-1">
                                            <h5><FontAwesomeIcon icon={faTasks}/> Reporte de Asistencia</h5>
                                        </div>
                                        <div className="col-lg-12">
                                            <strong>Materia:</strong> {materia && materia.materia_nombre.nombre_materia}
                                        </div>
                                        <div className="col-lg-12">
                                            <strong>Grupo:</strong> {materia && materia.grupo.nombre}
                                        </div>
                                        <div className="col-lg-12">
                                            <strong>Sede:</strong> {materia && materia.sede.nombre_sede}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="col-lg-12">
                <hr></hr>
            </div>
        </div>
        <div className="row animated fadeIn">
            <div className="col-lg-12 mb-3">
                <SessionExpireLabel/>
                <button className="btn btn-link float-right" onClick={() => history.push('/cursos/materia')}>
                    <FontAwesomeIcon icon={faArrowLeft}/> Volver
                </button>
            </div>
            <div className="col-lg-12">
                
                <Card className="card-solid shadow">
                    <Card.Header>
                        <Card.Title>Asistencia</Card.Title>
                        <div class="card-tools">
                            <OverlayTrigger overlay={<Tooltip id={(1000).toString()}>Descargar PDF</Tooltip>}>
                                <button class="btn btn-tool btn-sm" onClick={handlePrint}>
                                    <i class="fas fa-download"></i>
                                </button>
                            </OverlayTrigger>
                        </div>
                    </Card.Header>
                    <Card.Body className="table-responsive p-0">
                    <table class="table table-striped table-valign-middle">
                        <thead>
                        <tr>
                            <th>Nombre</th>
                            {materia !== null &&
                                materia.planeacion.modulos.map(modulo =>{
                                    return (
                                        <th>{modulo.fecha}</th>
                                    )
                                })
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {materia !== null &&
                            materia.cargas.map(carga => {
                                return (
                                    <tr>
                                        <td>
                                            {carga.alumno !== null ? 
                                                carga.alumno.foto !== null ?
                                                    <img src={`${RESOURCE_URL}/img/doc_alumno/${carga.alumno.user_id}/${carga.alumno.foto}`} alt="Product 1" class="img-circle img-size-32 mr-2"/>
                                                : <img src={`${RESOURCE_URL}/img/perfil.png`} alt="Product 1" class="img-circle img-size-32 mr-2"/>
                                            : <img src={`${RESOURCE_URL}/img/perfil.png`} alt="Product 1" class="img-circle img-size-32 mr-2"/>}
                                            {carga.alumno.nombre} {carga.alumno.apellido_paterno} {carga.alumno.apellido_materno}
                                        </td>
                                        {materia.planeacion.modulos.map(modulo => {
                                            return(
                                                <td>
                                                    <small class="text-success mr-1">
                                                    {getAsistencia(carga.alumno.asistencias, modulo.fecha) === 1 && <FontAwesomeIcon icon={faCheck}/>}
                                                    {getAsistencia(carga.alumno.asistencias, modulo.fecha) === 2 && <FontAwesomeIcon icon={faTimes} color='red'/>}
                                                    {getAsistencia(carga.alumno.asistencias, modulo.fecha) === 3 && <FontAwesomeIcon icon={faCalendarTimes} color='yellow'/>}
                                                    </small>
                                                    {getAsistencia(carga.alumno.asistencias, modulo.fecha) === 1 && 'Asistencia'}
                                                    {getAsistencia(carga.alumno.asistencias, modulo.fecha) === 2 && 'Falta'}
                                                    {getAsistencia(carga.alumno.asistencias, modulo.fecha) === 3 && 'Retardo'}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>  
                    </Card.Body>
                </Card>
            </div>

            <div className="col-lg-12" hidden>
                <Card>
                    <Card.Body ref={componentRef}>
                    <div className="row">
                        <div className="col-lg-12 mb-3 text-center">
                            <img src="/img/cch.jpg" alt="..." width="150"/> 
                        </div>
                        <div className="col-lg-12 mb-3 text-center">
                            <h3>Centro de Crecimiento Humanista</h3>
                            <h5 className="text-muted">REPORTE DE ASISTENCIA</h5>
                            <h6 className="text-muted">{materia.programa.nombre_programa}</h6>
                            <h6 className="text-muted">{materia.sede.nombre_sede}</h6>
                            <h6 className="text-muted">{materia.grupo.nombre}</h6>
                        </div>
                        <div className="col-lg-12 mb-3">
                            <hr></hr>
                        </div>
                    <div className="col-lg-12" >
                        <table class="table table-bordered">
                            <thead>
                            <tr>
                                <th>Nombre</th>
                                {materia !== null &&
                                    materia.planeacion.modulos.map(modulo =>{
                                        return (
                                            <th>{modulo.fecha}</th>
                                        )
                                    })
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {materia !== null &&
                                materia.cargas.map(carga => {
                                    return (
                                        <tr>
                                            <td>
                                                
                                                {carga.alumno.nombre} {carga.alumno.apellido_paterno} {carga.alumno.apellido_materno}
                                            </td>
                                            {materia.planeacion.modulos.map(modulo => {
                                                return(
                                                    <td>
                                                        <small class="text-success mr-1">
                                                        {getAsistencia(carga.alumno.asistencias, modulo.fecha) === 1 && <FontAwesomeIcon icon={faCheck}/>}
                                                        {getAsistencia(carga.alumno.asistencias, modulo.fecha) === 2 && <FontAwesomeIcon icon={faTimes} color='red'/>}
                                                        {getAsistencia(carga.alumno.asistencias, modulo.fecha) === 3 && <FontAwesomeIcon icon={faCalendarTimes} color='yellow'/>}
                                                        </small>
                                                        {getAsistencia(carga.alumno.asistencias, modulo.fecha) === 1 && 'Asistencia'}
                                                        {getAsistencia(carga.alumno.asistencias, modulo.fecha) === 2 && 'Falta'}
                                                        {getAsistencia(carga.alumno.asistencias, modulo.fecha) === 3 && 'Retardo'}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>  
                    </div>
                    </div>
                    </Card.Body>
                </Card>
            </div>
        </div>
        </>
     );
}
 
export default MateriaAsistencia;