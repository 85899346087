import {
    CARGAR_PROGRAMAS,
    LOADING_PROGRAMAS,
    ERROR_PROGRAMAS,
    RESET_PROGRAMAS
} from '../../types';

const initialState = {
    programasList: [],
    programa: {},
    errorListProgramas: false,
    loadingList: false,    
    errorProgramas: false,
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CARGAR_PROGRAMAS:
            return {
                ...state,
                programasList: action.payload
            }
        case RESET_PROGRAMAS: 
            return {
                ...state,
                programasList: [],
                programas: {},
                errorListProgramas: false,
                loadingList: false,    
                errorProgramas: false,
                loading: false
            }
        case LOADING_PROGRAMAS:
            return {
                ...state,
                loadingList: action.payload
            }
        case ERROR_PROGRAMAS:
            return {
                ...state,
                errorListProgramas: action.payload
            }
        default:
            return state;
    }
}