import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { faAddressCard, faInfoCircle, faKey, faSave, faUserLock, faUserTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Card, Form } from 'react-bootstrap';
import Encabezado from '../recursos/Encabezado';

/* actions */
import { updateUsuario, updatePassword } from '../../redux/actions/Login.actions';
import { RESOURCE_URL } from '../recursos/url';
/* end actions */

const Configuracion = () => {

    const dispatch = useDispatch();
    const docente = useSelector(state => state.Docentes.docente);
    const alumno = useSelector(state => state.Alumnos.alumno);
    const usuario = useSelector(state => state.Authentication.usuario);

    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [error, setError] = useState(null);
    const [password, setPassword] = useState(null);
    const [newPasword, setnewPasword] = useState(null);
    const [confirmPasword, setconfirmPasword] = useState(null);
    const [errorPSW, seterrorPSW] = useState(false)
    const [show, setShow] = useState(false);
    const [showPSW, setShowPSW] = useState(false);

    const validarEmail = (valor) => {
        let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if (emailRegex.test(valor)){
            return true;
        } else {
            return false;
        }

    };

    const guardarDatos = () => {

        let emailValido = false;
        let error = null;

        if (email !== null || name !== null) {

            if (email !== null) {
                emailValido = validarEmail(email);
                if (emailValido) {
                    dispatch( updateUsuario( name, email, usuario.id ) );
                    setShow(false);
                    setEmail(null);
                    setName(null);
                } else {
                    error = 'El email no es un correo valido.';
                    setShow(true);
                }
            } else {
                dispatch( updateUsuario( name, email, usuario.id ) );
                setEmail(null);
                setName(null);
            }
            
            
        } else {
            error = 'Es necesario llenar alguno de los datos.';   
            setShow(true); 
        }
        setError(error);
        
    };

    const guardarPassword = () => {
        if (password !== null && newPasword !== null && confirmPasword !== null) {
            dispatch( updatePassword(password, newPasword, confirmPasword, usuario.id) );
            seterrorPSW(false);
            setPassword(null);
            setnewPasword(null);
            setconfirmPasword(null);
        } else {
            seterrorPSW(true);
        } 
    }

    return ( 
        <>
        <title>CCH | Configuración</title>
        <Encabezado/>
        <div className="row justify-content-center">
            <div className="col-lg-10">
                
                <div class="card card-widget widget-user">
                    <div class="widget-user-header bg-dark">
                        <h3 class="widget-user-username">
                        {Number(usuario.role_id) === 1 &&  usuario.name}
                        {Number(usuario.role_id) === 2 &&  docente.nombre} 
                        {Number(usuario.role_id) === 3 &&  alumno.nombre} 
                        </h3>
                        <h5 class="widget-user-desc">
                        {Number(usuario.role_id) === 2 &&  docente.apellido_paterno} {Number(usuario.role_id) === 2 &&  docente.apellido_materno}
                        {Number(usuario.role_id) === 3 &&  alumno.apellido_paterno} {Number(usuario.role_id) === 3 &&  alumno.apellido_materno}
                        </h5>
                    </div>
                    <div class="widget-user-image">
                        
                        <img 
                            class="img-circle elevation-2"
                            src={`${RESOURCE_URL}/img/perfil.png`}
                            alt="User Avatar"
                        />
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-sm-4 border-right">
                                <div class="description-block">
                                <h5 class="description-header"><i class="fa fa-envelope"></i>  Email</h5>
                                <span>{usuario.email}</span>
                                </div>
                            </div>
                            <div class="col-sm-4 border-right">
                                <div class="description-block">
                                <h5 class="description-header"><FontAwesomeIcon icon={faUserLock}/> Permisos</h5>
                                    <span>
                                    {Number(usuario.role_id) === 1 &&  'Administrador'}
                                    {Number(usuario.role_id) === 2 &&  'Docente'}
                                    {Number(usuario.role_id) === 3 &&  'Alumno'}
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="description-block">
                                <h5 class="description-header"><i class="fa fa-user-circle"></i> Usuario</h5>
                                <span>{usuario.name}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row justify-content-center">
            <div className="col-lg-5">
                <Card className="shadow collapsed-card">
                    <Card.Header className="bg-dark">
                        <Card.Title>
                            <FontAwesomeIcon icon={faAddressCard}/> Modificar Datos
                        </Card.Title>
                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                <i class="fas fa-plus"></i>
                            </button>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Alert variant="danger" onClose={() => setShow(false)} dismissible hidden={!show} className="animated fadeIn">
                            <Alert.Heading>
                                <FontAwesomeIcon icon={faInfoCircle}/> Alerta
                            </Alert.Heading>
                            <p>{error}</p>
                        </Alert>
                        <Form.Group className="mb-3">
                            <Form.Label>Nombre de usuario</Form.Label>
                            <Form.Control type="text" placeholder="Ingresa el nombre de usuario" onChange={e => setName(e.target.value !== '' ? e.target.value : null)} value={name !== null ? name : ""}/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Ingresa el email" onChange={e => setEmail(e.target.value !== '' ? e.target.value : null)} value={email !== null ? email : ""}/>
                        </Form.Group>
                    </Card.Body>
                    <Card.Footer>
                        <Button variant="primary" className="float-right" onClick={guardarDatos}>
                            <FontAwesomeIcon icon={faSave}/> Guardar
                        </Button>
                    </Card.Footer>
                </Card>
            </div>
            <div className="col-lg-5">
                <Card className="shadow collapsed-card">
                    <Card.Header className="bg-dark">
                        <Card.Title>
                            <FontAwesomeIcon icon={faKey}/> Cambiar Contraseñas
                        </Card.Title>
                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                <i class="fas fa-plus"></i>
                            </button>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Form.Group className="mb-3">
                            <Form.Label>Contraseña actual</Form.Label>
                            <Form.Control type="password" placeholder="Ingresa contraseña actual" onChange={e => setPassword(e.target.value !== '' ? e.target.value : null)} value={password !== null ? password : ''}/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Nueva contrasena</Form.Label>
                            <Form.Control type="password" placeholder="Ingresa nueva contraseña" onChange={e => setnewPasword(e.target.value !== '' ? e.target.value : null)} value={newPasword !== null ? newPasword : ''}/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Confirmar nueva contraseña</Form.Label>
                            <Form.Control type="password" placeholder="Ingresa confirmación nueva contraseña" onChange={e => setconfirmPasword(e.target.value !== '' ? e.target.value : null)} value={confirmPasword !== null ? confirmPasword : ''}/>
                        </Form.Group>
                    </Card.Body>
                    <Card.Footer>
                        <Button variant="primary" className="float-right" onClick={guardarPassword}>
                            <FontAwesomeIcon icon={faSave}/> Guardar
                        </Button>
                    </Card.Footer>
                </Card>
            </div>
        </div>
        </>
     );
}
 
export default Configuracion;