import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity, faArrowRight, faCoffee } from '@fortawesome/free-solid-svg-icons';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { scroller, Element } from 'react-scroll';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

/* actions */
import { obtenerMateriasAlumno, obtenerDatosMateria } from '../../redux/actions/Materias.actions';
import { obtenerListaTareasMateriasAlumno } from '../../redux/actions/Tareas.action';
import Encabezado from '../recursos/Encabezado';
/* end action */

import { useReactToPrint } from 'react-to-print';


const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
    } ) => (
    <div className="btn-group" role="group">
    {
        options.map((option) => {

        const isSelect = currSizePerPage === `${option.page}`;
        return (
            <button
                key={ option.text }
                type="button"
                onClick={ () => onSizePerPageChange(option.page) }
                className={ `btn ${isSelect ? 'btn-secondary' : 'btn-primary'}` }
            >
            { option.text }
            </button>
        );
        })
    }
    </div>
);

const options = {
    sizePerPageRenderer
}

const { SearchBar } = Search;

const PrincipalCursosAlumno = () => {

    const refReportCalificaciones = useRef();
    const handlePrintCalificaciones = useReactToPrint({
        content: () => refReportCalificaciones.current,
        documentTitle: 'Kardex',
        pageStyle: () => {
           return  "@page { size: auto; margin:s 20mm }"
        }
    });

    const dispatch = useDispatch();
    const history = useHistory();
    const { usuario } = useSelector(state => state.Authentication);
    const { materiasList, loadingList } = useSelector(state => state.Materias);
    const alumnoId = useSelector(state => state.Alumnos.alumno.id);
    const alumno = useSelector(state => state.Alumnos.alumno);
    const [materiasListlocal, setmateriasListlocal] = useState([])
    
    useEffect(() => {
        dispatch( obtenerMateriasAlumno(usuario.alumno.id) );
    }, []);

    useEffect(() => {
        if (materiasList.length > 0) {
            setmateriasListlocal(materiasList);
        }
    }, [materiasList]);

    const afterSearch = (newResult) => {
        /* console.log(newResult); */
    };

    const handleChangeMatricula = (matriculaId) => {
        if (matriculaId !== '0') {
           setmateriasListlocal(materiasList.filter(materia => materia.matricula_id === Number(matriculaId)));
        } else {
            setmateriasListlocal(materiasList);
        }
    }

    const selectMateria = (cargaId, materiaId) => {
        dispatch( obtenerListaTareasMateriasAlumno(cargaId) );
        dispatch( obtenerDatosMateria(materiaId) );
        history.push('/alumno/tareas'); 
    }

    const getTotalTareas = () => {
        let noTareas = 0;

        materiasList.forEach(materia => {
            noTareas = noTareas+materia.no_tareas;
        });

        return noTareas;
    }

    const columns = [ {
        dataField: 'nombre',
        text: 'Materia',
        sort: true,
        align: 'left', 
        headerAlign: 'left',
        headerStyle: {
            width: '40%'
        }
      }, /* {
        dataField: 'programa',
        text: 'Programa',
        sort: true,
        align: 'left',
        headerAlign: 'center',
        headerStyle: {
            width: '30%'
        }
      },{
        dataField: 'sede',
        text: 'Sede',
        sort: true,
        align: 'left',
        headerAlign: 'middle',
        headerStyle: {
            width: '20%'
        }
      } ,*/
      {
        dataField: 'calificacion',
        text: 'Calificación',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        headerStyle: {
            width: '20%'
        }
      } , {
        dataField: '3',

        text: 'Acciones',
        sort: true,
        align: 'center', 
        headerAlign: 'center',
        headerStyle: {
            width: '10%'
        },
        formatter: (cell, row) => {
            return (
                <button class="btn btn-sm btn-app bg-success" onClick={() => selectMateria(row.carga_id, row.materia_id)}>
                    <span class="badge bg-purple" hidden={row.no_tareas > 0 ? false : true}><strong>{row.no_tareas}</strong></span>
                    <i class="fas fa-coffee"></i> Tareas
                </button>                   
            );
        }
      }];

    return ( 
        <>
        <title>CCH | Cursos</title>
        <Encabezado/>
        <div className="row animated fadeIn">{/* justify-content-center */}
            <div class="col-md-3 col-sm-6 col-12 animated fadeIn">
                <div class="small-box bg-purple">
                <div class="inner">
                    <h3>{getTotalTareas()}</h3>
                    <p>Nuevas tareas</p>
                </div>
                <div class="icon">
                    
                    <i class="fas fa-coffee"></i>
                </div>
                <a href="#" class="small-box-footer" onClick={() => scroller.scrollTo("cursos", {duration: 1000,delay: 100, smooth: true})}>
                    Más información <i class="fas fa-arrow-circle-down"></i>
                </a>
                </div>
            </div>
            {/* <div class="col-md-3 col-sm-6 col-12 animated fadeIn">
                <div class="small-box bg-info">
                <div class="inner">
                    <h3>2021-12-24</h3>
                    <p>Pago del mes</p>
                </div>
                <div class="icon">
                    
                    <i class="fas fa-dollar-sign"></i>
                </div>
                <a href="#" class="small-box-footer">Más información <i class="fas fa-arrow-circle-down"></i></a>
                </div>
            </div> */}
            {/* <div class="col-md-3 col-sm-6 col-12 ">
                <div class="info-box bg-purple shadow">
                <span class="info-box-icon"><FontAwesomeIcon icon={faCoffee}/></span>

                <div class="info-box-content">
                    <span class="info-box-text">Nuevas tareas</span>
                    <span class="info-box-number">Pendientes 4</span>
                </div>
                </div>
                
            </div> */}
        </div>
        <Element name="cursos"></Element>
        <div className="row">
            <div className="col-lg-12 mb-2">
                <Card className="shadow">
                    <Card.Header>
                    Cursos a los que estàs inscrito
                        <div class="card-tools">
                            <OverlayTrigger overlay={<Tooltip id={(1000).toString()}>Descargar Kardex</Tooltip>}>
                                <button class="btn btn-tool btn-sm" onClick={handlePrintCalificaciones}>
                                    <i class="fas fa-download"></i>
                                </button>
                            </OverlayTrigger>
                        </div>
                    </Card.Header>
                    <Card.Body className="p-1">
                        <ToolkitProvider
                            keyField="carga_id"
                            data={ materiasListlocal }
                            columns={ columns }
                            search={ { afterSearch } }
                            
                            >
                            {
                                props => (
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="form-group">
                                            <label>Materia</label><br></br>
                                            <SearchBar style={{ width: '100%' }} className="form-control form-control-sm" { ...props.searchProps } placeholder="Buscar"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div class="form-group">
                                            <label>Programas</label>
                                            <select className="form-control form-control-sm" onChange={(e) => handleChangeMatricula(e.target.value)}>
                                            <option value='0'>Todos</option>
                                            {alumno.matriculas.map(matricula => {
                                                return (
                                                    <option value={matricula.id}>{matricula.matricula} - {matricula.carga_programa ?  matricula.carga_programa.programa ? matricula.carga_programa.programa.nombre_programa : null : null}</option>
                                                )
                                            })}
                                            </select>
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-12">
                                        <BootstrapTable
                                        classes="table table-striped table-hover"
                                        bordered={ false } 
                                        { ...props.baseProps }
                                        pagination={ paginationFactory(options) }
                                        noDataIndication={loadingList ? "Cargando información, por favor espere..." : "No se encontrarón resultados."}
                                        />
                                    </div>
                                    
                                    
                                </div>
                                )
                            }
                        </ToolkitProvider>
                    </Card.Body>
                </Card>
            </div>
        </div>
        <div hidden>
        <div className="container" ref={refReportCalificaciones}>
            <div className="row">
                <div className="col-lg-12 mb-3 text-center">
                    <img src="/img/cch.jpg" alt="..." width="150"/> 
                </div>
                <div className="col-lg-12 mb-3 text-center">
                    <h3>Centro de Crecimiento Humanista</h3>
                    <h5 className="text-muted">Kardex</h5>
                </div>
                <div className="col-lg-12 mb-3">
                    <hr></hr>
                </div>
                <div className="col-lg-12" >
                    <table class="table table-bordered">
                        <thead>
                        <tr>
                            <th >Materia</th>
                            <th className="text-center">Calificación</th>      
                        </tr>
                        </thead>
                        <tbody>
                            {materiasListlocal.map(materia => {
                                return (
                                    <tr>
                                        <td>{materia.nombre}</td>
                                        <td className="text-center">{materia.calificacion}</td>
                                    </tr>
                                )
                            })}
                            
                        </tbody>
                    </table>  
                </div>
            </div>
        </div>
        </div>
        
        </>
     );
}
 
export default PrincipalCursosAlumno;