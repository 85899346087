import React from 'react';
import { faEdit, faPlusCircle, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';

const MateriaTareaModal = (props) => {
    
    const {openEdit, tareaEdit, validacionEdit } = props;
    const {setOpenEdit, handleChange, guardarTarea} = props;

    return ( 
        <>
            <Modal
                show={openEdit}
                onHide={() => setOpenEdit(false)}
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    {tareaEdit.id === 0 ? 
                        <Modal.Title id="contained-modal-title-vcenter">
                            <FontAwesomeIcon icon={faPlusCircle}/> Nueva Tarea
                        </Modal.Title>
                    :
                        <Modal.Title id="contained-modal-title-vcenter">
                            <FontAwesomeIcon icon={faEdit}/> Modificar Tarea
                        </Modal.Title>
                    }
                    
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="tituloId">
                            <Form.Label>Título</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Ingresa el título" 
                                    name="titulo"
                                    value={tareaEdit.titulo}
                                    onChange={handleChange}
                                    isInvalid={validacionEdit.titulo}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>El título es un dato obligatorio</Form.Control.Feedback>
                            </InputGroup>
                            
                            
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Fecha límite de entrega</Form.Label>
                            <InputGroup hasValidation>
                                <input 
                                    type="datetime-local" 
                                    class={`form-control ${validacionEdit.fecha_entrega ? 'is-invalid': ''}`}
                                    name="fecha_entrega"
                                    value={tareaEdit.fecha_entrega}
                                    onChange={handleChange} 
                                />
                                <Form.Control.Feedback type="invalid" tooltip>La fecha límite de entrega es un dato obligatorio</Form.Control.Feedback>
                            </InputGroup>
                            
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="descripcionId">
                            <Form.Label>Descripción</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control  
                                    as="textarea" 
                                    rows={3} 
                                    name="descripcion"
                                    value={tareaEdit.descripcion}
                                    onChange={handleChange}  
                                    isInvalid={validacionEdit.descripcion}  
                                />
                                <Form.Control.Feedback type="invalid" tooltip>La descripción es un dato obligatorio</Form.Control.Feedback>
                            </InputGroup>
                            
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setOpenEdit(false)}><FontAwesomeIcon icon={faTimes}/> Cancelar</Button>
                    <Button variant="success" onClick={guardarTarea}><FontAwesomeIcon icon={faSave}/> Guardar</Button>
                </Modal.Footer>
            </Modal>
        </>
     );
}
 
export default MateriaTareaModal;