import clienteAxios from '../../config/axios';
import { mensajeCentral, mensajeInfoToastify, mensajeSuccessToastify, mensajeWarningToastify, mensajeDangerToastify, msjError } from '../../components/recursos/mensajes';
import store from "../../store";
import {
    LISTA_DOCENTES,
    LOADING_DOCENTES,
    LOADING_DOCENTE,
    RESET_DOCENTES,
    ERROR_DOCENTES,
    ERROR_DOCENTE,
    HORARIO_DOCENTE,
    LOADING_HORARIO_DOCENTE,
    DATOS_DOCENTE
} from '../../types';

const token = `${store.getState().Authentication.courrentUser.token_type} ${store.getState().Authentication.courrentUser.access_token}`;

export function obtenerListaDocentes (filtro) {
    
    return ( dispatch) => {
        
        dispatch( loadingDocentes (true) );
        clienteAxios.get('docentes/all',
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {

            let listaDocentes = response.data;
            
            dispatch ( setListaDocentes(listaDocentes) );
            dispatch( loadingDocentes(false) );
            dispatch( errorDocentes(false) );

        }).catch(error => {
            if (error.response) {//el servidor respondió con un código de estado que cae fuera del rango de 2xx
                
                mensajeDangerToastify('Error');

            } else if (error.request) {//Se realizó la solicitud pero no se recibió respuesta

                mensajeDangerToastify('Se realizó la solicitud pero no se recibió respuesta');
                
            } else {// Algo sucedió al configurar la solicitud y provocó un error
                
                mensajeDangerToastify('Algo sucedió al configurar la solicitud');

            }

            dispatch( errorDocentes(true) );  

        }).finally(final => {

            dispatch( loadingDocentes(false) );

        });
    };

}

export const obtenerHorarioDocente = (id) => {
    return dispatch => {
        dispatch( setLoadingHorario(true) );
        clienteAxios.get('/pdf/horario-docente/'+id,
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {
            dispatch( setHorario(response.data) );
        }).catch(error => {
            msjError(error);
        }).finally(final => {
            dispatch( setLoadingHorario(false) );
        });
    }
};

export function registrarDatosDocente (datosDocente) {
    return (dispatch) => {
        
        dispatch( loadingDocente(true) );
        
        clienteAxios.post('/docente/registro',
        datosDocente,
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {
            
            if (response.data.success) {
                mensajeSuccessToastify(response.data.message);
                dispatch ( errorDocente ( false ) );
                dispatch( obtenerListaDocentes({}) );
            } else {
                mensajeWarningToastify(response.data.message);
                dispatch ( errorDocente ( true ) );
            }

        }).catch(error => {

            if (error.response) {//el servidor respondió con un código de estado que cae fuera del rango de 2xx
                
                mensajeDangerToastify('Error');

            } else if (error.request) {//Se realizó la solicitud pero no se recibió respuesta

                mensajeDangerToastify('Se realizó la solicitud pero no se recibió respuesta');
                
            } else {// Algo sucedió al configurar la solicitud y provocó un error
                
                mensajeDangerToastify('Algo sucedió al configurar la solicitud');

            }
            dispatch ( errorDocente ( true ) );

        }).finally(final => {
            
            dispatch ( loadingDocente(false) );
            
            
        });

    }
        
}

export function modificarDatosDocente (datosDocente, id) {
    return (dispatch) => {

        const headers = { 
            'Authorization': token/* ,
            'Content-Type': 'multipart/form-data' */
        };

        dispatch( loadingDocente(true) );
        clienteAxios.post('/docente/modificar-datos/'+id, datosDocente, { headers }).then(response => {
            
            if (response.data.success) {
                mensajeSuccessToastify(response.data.message);
                dispatch ( errorDocente ( false ) );
                dispatch( obtenerListaDocentes({}) );
            } else {
                mensajeWarningToastify(response.data.message);
                dispatch ( errorDocente ( true ) );
            }
                
        }).catch(error => {

            if (error.response) {//el servidor respondió con un código de estado que cae fuera del rango de 2xx
                mensajeDangerToastify('Ocurrio un error, intente nuevamente.');
            } else if (error.request) {//Se realizó la solicitud pero no se recibió respuesta
                mensajeDangerToastify('Se realizó la solicitud pero no se recibió respuesta.');
            } else {// Algo sucedió al configurar la solicitud y provocó un error
                mensajeDangerToastify('Algo sucedió al configurar la solicitud.');
            }
            dispatch ( errorDocente ( true ) );

        }).finally(final => {
            dispatch ( loadingDocente(false) );
        });

    }
        
}

export const solicitudCambioContrasena = (id) => {
    return dispatch => {
        const headers = { 
            'Authorization': token
        };
        clienteAxios.post('usuario/solicitud/contrasena/'+id, { headers }).then(response => {
            
            if (response.data.success) {
                mensajeInfoToastify(response.data.message);
            } else {
                mensajeWarningToastify(response.data.message);
            }
                
        }).catch(error => {

            msjError(error);
            dispatch ( errorDocente ( true ) );

        });
    }
}

export const setListaDocentes = (listaDocentes) => {
    return {
        type: LISTA_DOCENTES,
        payload: listaDocentes
    }
}

export const setDocente = (docente) => {
    return {
        type: DATOS_DOCENTE,
        payload: docente
    }
}

export const resetDocentes = () => {
    return {
        type: RESET_DOCENTES
    }
}

export const loadingDocentes = (loading) => {
    return {
        type: LOADING_DOCENTES,
        payload: loading
    }
}

export const errorDocentes = (error) => {
    return {
        type: ERROR_DOCENTES,
        payload: error
    }
}

export const errorDocente = (error) => {
    return {
        type: ERROR_DOCENTE,
        payload: error
    }
}
export const loadingDocente = (loading) => {
    return {
        type: LOADING_DOCENTE,
        payload: loading
    }
}

export const setHorario = (horario) => {
    return {
        type: HORARIO_DOCENTE,
        payload: horario
    }
};

export const setLoadingHorario = (flag) => {
    return {
        type: LOADING_HORARIO_DOCENTE,
        payload: flag
    }
};


