import { combineReducers } from 'redux';
import { reduxTokenAuthReducer } from 'redux-token-auth';
import LoginReducer from './LoginReducer';
import GruposReducer from './GruposReducer';
import SedesReducer from './SedesReducer';
import ProgramasReducer from './ProgramasReducer';
import ValidacionReducer from './ValidacionReducer';
import PlaneacionReducer from './PlaneacionReducer';
import MateriasReducer from './MateriasReducer';
import ModulosReducer from './ModulosReducer';
import DocentesReducer from './DocentesReducer';
import AlumnosReducer from './AlumnosReducer';
import TareasReducer from './TareasReducer';
import AsistenciasReducer from './AsistenciasReducer';
import SolicitudesReducer from './SolicitudesReducer';

const rootReducer = combineReducers({
    Authentication: LoginReducer,
    Grupos: GruposReducer,
    Sedes: SedesReducer,
    Programas: ProgramasReducer,
    Planeacion: PlaneacionReducer,
    Validacion: ValidacionReducer,
    Materias: MateriasReducer,
    Modulos: ModulosReducer,
    Docentes: DocentesReducer,
    Alumnos: AlumnosReducer,
    Tareas: TareasReducer,
    Asistencias: AsistenciasReducer,
    Solicitudes: SolicitudesReducer
});

export default rootReducer;