import {
    LISTA_MATERIAS,
    DATOS_MATERIA,
    LOADING_MATERIAS,
    LOADING_MATERIA,
    RESET_MATERIAS,
    ERROR_MATERIAS,
    ERROR_MATERIA
} from '../../types';

const initialState = {
    materiasList: [],
    materia: null,
    loading: false,
    loadingList: false,
    error: false, 
    errorList: false,
    calificacionesList: [],
    loadingCalificaciones: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case DATOS_MATERIA: 
            return {
                ...state, 
                materia: action.payload
            }
        case LISTA_MATERIAS:
            return {
                ...state, 
                materiasList: action.payload
            }
        case LOADING_MATERIAS:
            return {
                ...state, 
                loadingList: action.payload
            }
        case LOADING_MATERIA:
            return {
                ...state,
                loading: action.payload
            }
        case ERROR_MATERIAS:
            return {
                ...state,
                errorList: action.payload
            }
        case ERROR_MATERIA:
            return {
                ...state,
                error: action.payload
            }
        case 'SET_CALIFICACIONES': 
            return {
                ...state,
                calificacionesList: action.payload
            }
        case 'SET_LOADING_CALIFICACIONES':
            return {
                ...state,
                loadingCalificaciones: action.payload
            }
        case RESET_MATERIAS:
            return {
                ...state,
                materiasList: [],
                materia: null,
                loading: false,
                loadingList: false,
                error: false, 
                errorList: false
            }
        default:
            return state;
    }
}