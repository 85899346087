import { faEdit, faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Modal } from 'react-bootstrap'
import { Form, InputNumber, Button, Spin } from 'antd';
import { cargarListaProgramasDetalle } from '../../redux/actions/Programas.actions';
import { editCalificacionProv } from '../../redux/actions/Materias.actions';
import { setDatosAlumno } from '../../redux/actions/Alumnos.action';

const ModalCalificacionesAlumno = ({ open, setOpen}) => {

    const dispatch = useDispatch();
    const programasList = useSelector(state => state.Programas.programasList);
    const [semestresList, setSemestresList] = useState([]);
    const [disabled_id, setdisabled_id] = useState(null);

    const alumno = useSelector(state => state.Alumnos.alumno);
    const loading = useSelector(state => state.Alumnos.loadingList);
    const loadingCal = useSelector(state => state.Materias.loading);

    useEffect(() => {
        dispatch( cargarListaProgramasDetalle() );
    }, []);

    useEffect(() => {
        setSemestresList([]);
    }, [open]);

    const onChangePrograma = (id) => {
        let list = programasList.filter(prog => Number(prog.id) === Number(id) );
        list = list[0];
        if (list.semestres_programas) {
            setSemestresList( list.semestres_programas );
        }
        
    } 
    

    const guardarDatos = (values, programa_id, semestre_id, materia_id) => {
        dispatch( editCalificacionProv({
            id: disabled_id,
            calificacion: values.calificacion,
            programa_id: programa_id,
            semestre_id: semestre_id,
            materia_id: materia_id,
            alumno_id: alumno.id
        }) );
        
        if (disabled_id !== null) {
            let calificacion = alumno.calificaciones_prov.filter(cal => 
                cal.programa_id === programa_id &&
                cal.semestre_id === semestre_id &&
                cal.materia_id === materia_id
            )[0];
            let alumnoNew = alumno;
            let ListCal = alumnoNew.calificaciones_prov.map(cal => {
                if (cal.id === calificacion.id) {
                    cal.calificacion = values.calificacion;
                }
                return cal;
            });

            alumnoNew.calificaciones_prov = ListCal;

            dispatch( setDatosAlumno(alumnoNew) );

        } else {

            let alumnoNew = alumno;
            alumnoNew.calificaciones_prov.push({
                id: null,
                calificacion: values.calificacion, 
                programa_id: programa_id,
                semestre_id: semestre_id,
                materia_id: materia_id,
                alumno_id: alumno.id
            });

            dispatch( setDatosAlumno(alumnoNew) );

        }

        setdisabled_id(null);
    }

    const calificacionValue = (materia_id) => {
        let calificacion = alumno.calificaciones_prov ? alumno.calificaciones_prov.filter(cal => Number(cal.materia_id) === Number(materia_id)) : [];

        if (calificacion.length > 0) {
            return Number(calificacion[0].calificacion);
        } else {
            return null;
        }
    }

    const isDisabled = (materia_id) => {
        let calificacion = alumno.calificaciones_prov ? alumno.calificaciones_prov.filter(cal => Number(cal.materia_id) === Number(materia_id)) : [];

        if (calificacion.length > 0) {
            if (disabled_id === materia_id) {
                return false;
            } else {
                return true;
            }
        } else {
            if (disabled_id !== null) {
                return true
            } else {
                return false;
            }
            
        }
    }

    const isDisabledEdit = (materia_id) => {
        let calificacion = alumno.calificaciones_prov ? alumno.calificaciones_prov.filter(cal => Number(cal.materia_id) === Number(materia_id)) : [];
         
        if (calificacion.length > 0) {
            if (disabled_id !== null) {
                if (disabled_id === materia_id) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false
            }
            
        } else {
            return true;
        }
        
    }

  return (
    <Modal
        size="lg"
        show={open}
        onHide={() => setOpen(false) }
        
        dialogClassName="modal-dialog modal-xl"
        backdrop="static"
        keyboard={false}
    >
        <Modal.Header closeButton>
            
            <h4>Calificaciones</h4>
            
        </Modal.Header>
        <Modal.Body>
            <div className="container">
                <div className='row'>
                    <div className="col-lg-6 form-group">
                        <label>Programa</label>
                        <select className="form-control" name="sede_id" onChange={e => onChangePrograma(e.target.value)}>
                            <option value={0}>Selecciona una opción</option>
                            {programasList.map((data) => {
                                return (
                                <option value={data.id} key={data.id}>{data.nombre_programa}</option>
                                );
                            })}
                        </select>
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className='col-lg-12  mb-3'>
                        <hr></hr>
                    </div>
                    <div className='col-lg-12 text-center h4 mb-3'>
                        {alumno.nombre} {alumno.apellido_paterno} {alumno.apellido_materno}
                    </div>
                    {semestresList.map(sem => {
                        return (
                        <div className='col-lg-8'>
                        <Card>
                            <Card.Header className='text-uppercase'>
                                <Card.Title>{sem.nombre}</Card.Title>
                            </Card.Header>
                            <Card.Body>
                            <Spin tip="Guardando calificación, por favor espere..." spinning={loadingCal}>
                                {sem.materia_nombres.map(materia => {
                                    return (
                                    <div className='row mb-1'>
                                        <div className='col-lg-7'>
                                            {materia.nombre_materia}
                                        </div>
                                        <div className='col-lg-5'>
                                            <Form
                                                layout='horizontal'
                                                fields={[
                                                    { name: ['calificacion'], value: calificacionValue(materia.id) },
                                                ]}
                                                onFinish={values => guardarDatos(values, sem.programa_id, sem.id, materia.id) }
                                            >
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                    <Form.Item 
                                                        name='calificacion' 
                                                        rules={[
                                                            { type: 'number', min: 0, max: 10, message: 'La calificación valida es de 0 a 10.' }, 
                                                            {
                                                                required: true,
                                                                message: 'Por favor ingresa la calificación!'
                                                            },
                                                        ]}
                                                    >
                                                        <InputNumber disabled={isDisabled(materia.id)}/>
                                                    </Form.Item>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                    <Form.Item>
                                                        <Button 
                                                            onClick={() => {
                                                                if (disabled_id !== null) {
                                                                    setdisabled_id(null);
                                                                } else {
                                                                    setdisabled_id(materia.id);
                                                                }
                                                            }}
                                                            disabled={isDisabledEdit(materia.id)}
                                                        >
                                                            {disabled_id !== null ?  (
                                                                <FontAwesomeIcon icon={faTimes}/>
                                                            ) : (
                                                                <FontAwesomeIcon icon={faEdit}/>
                                                            )}
                                                            
                                                        </Button>
                                                        <Button 
                                                            htmlType='submit' 
                                                            type="primary"
                                                        >
                                                            <FontAwesomeIcon icon={faSave}/>
                                                        </Button>
                                                    </Form.Item>
                                                    </div>
                                                </div>                                                
                                            </Form>
                                            
                                        </div>
                                    </div>
                                    )
                                })}
                            </Spin>   
                            </Card.Body>
                        </Card>
                    </div>
                        )
                    })}
                    
                </div>
            </div>
        </Modal.Body>
    </Modal>
  )
}

export default ModalCalificacionesAlumno