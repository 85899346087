import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    Button, 
    ButtonGroup, 
    OverlayTrigger, 
    Tooltip, 
    Card, 
    Modal,
    Form,
    InputGroup
 } from 'react-bootstrap';
import {  faBookOpen, faCalendarDay, faCalendarPlus, faCalendarTimes, faCheck, faClock, faCoffee, faEdit, faEnvelope, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';

import { scroller, Element } from 'react-scroll';

/* actions */
import { setTareas } from '../../redux/actions/Tareas.action';
import { registrarAsistenciaMateria } from '../../redux/actions/Asistencias.actions';
import { setDatosAlumno } from '../../redux/actions/Alumnos.action';
import { solicitudModulo } from '../../redux/actions/Modulos.actions';
import SessionExpireLabel from '../recursos/SessionExpireLabel';
import { RESOURCE_URL } from '../recursos/url';
 /* end actions */

const MateriaDocente = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    /* REDUX */
    const { materia } = useSelector(state => state.Materias);
    const docenteId = useSelector(state => state.Docentes.docente.id);
    const tareasList = useSelector(state => state.Tareas.tareasList);
    const banderaAsistencia = useSelector(state => state.Asistencias.banderaAsistencia);
    const diasCalificaciones = useSelector(state => state.Asistencias.diasCalificaciones);
    /* END-REDUX */

    /* VARIABLES LOCALES */
    const [openSolicitud, setOpenSolicitud] = useState(false);
    const [fecha, setFecha] = useState(null);
    const [hora, setHora] = useState(null);
    const [fechaVal, setFechaVal] = useState(false);
    const [horaVal, setHoraVal] = useState(false);
    /* const [tipoSolicitud, setTipoSolicitud] = useState(1); */
    /*  */

    const getFecha = (fecha) => {
        let f = new Date(fecha);
        const MESES = [ "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
        const DIAS = ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"];
        return `${DIAS[f.getDay()]} ${f.getDate()+1} de ${MESES[f.getMonth()]} del ${f.getFullYear()}`;
    };

    const getAsistencia = (asistencias) => {
        let asistencia = null;
        let f = new Date();
        if (asistencias.length > 0) {
            asistencias.forEach(asist => {
                if (asist.fecha === `${f.getFullYear()}-${(f.getMonth()+1) > 10 ? (f.getMonth()+1) : '0'+(f.getMonth()+1)}-${f.getDate() > 10 ? f.getDate() : '0'+f.getDate()}`) {
                    asistencia = asist.tipo;
                }
            });
        }
        return asistencia;
    }

    const tareasPorRevistar = (tareas) => {
        let noTareas = 0;
        tareas.forEach(tarea => {
            if(tarea.calificacion === null){
                if (tarea.texto !== null || tarea.archivo !== null) {
                    noTareas = noTareas+1;
                }     
            }
        });
        return noTareas;
    }

    const totalTareasPorRevisar = () => {
        let noTareas = 0;
        if (materia !== null) {
            materia.cargas.forEach(carga => {
                carga.tareas.forEach(tarea => {
                    if(tarea.calificacion === null) {
                        if (tarea.texto !== null || tarea.archivo !== null) {
                            noTareas = noTareas+1;
                        } 
                    }
                    
                })
            });
        }
        return noTareas;
    }

    const selectAlumnoTarea = (tareas, alumno) => {
        dispatch( setTareas(tareas) );
        dispatch( setDatosAlumno(alumno) );
        history.push('/curso/alumno/tareas');
    }

    const openModalSolicitud = (open) => {
        setOpenSolicitud(open);
        if (open) {
            setFecha(null);
            setHora(null);
            setFechaVal(false);
            setHoraVal(false);
        }
        
    } 

    const enviarSolicitud = () => {
        
        if (fecha !== null && hora !== null) {
            dispatch( solicitudModulo(1, materia.planeacion.id, docenteId, fecha, hora) );
            setOpenSolicitud(false);
        } else {
            setFechaVal(fecha !== null ? false : true);
            setHoraVal(hora !== null ? false : true);
        }
        
    }

    return ( 
        <>
        <title>CCH | Materia</title>
        <div className="row animated fadeIn">
            <div className="col-lg-12">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row ">
                            <div className="col-lg-12">
                                <div class="callout callout-info">
                                <div className="row">
                                        <div className="col-lg-12 mb-1">
                                            <h5><FontAwesomeIcon icon={faBookOpen}/> Materia</h5>
                                        </div>
                                        <div className="col-lg-12">
                                            <strong>Materia:</strong> {materia && materia.materia_nombre.nombre_materia}
                                        </div>
                                        <div className="col-lg-12">
                                            <strong>Grupo:</strong> {materia && materia.grupo.nombre}
                                        </div>
                                        <div className="col-lg-12">
                                            <strong>Sede:</strong> {materia && materia.sede.nombre_sede}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="col-lg-12">
                <hr></hr>
            </div>
            <div className="col-lg-12 mb-3">
                <SessionExpireLabel/>
            </div>
        </div>
        
        <div className="row">
            <div class="col animated fadeIn">
                <div class="small-box bg-secondary">
                <div class="inner">
                    <h3>{materia !== null && materia.cargas.length}</h3>
                    <p>Alumnos Inscritos al Curso</p>
                </div>
                <div class="icon">
                    
                    <i class="fas fa-user-check"></i>
                </div>
                <a href="#" class="small-box-footer" onClick={() => scroller.scrollTo("alumnos", {duration: 1000,delay: 100, smooth: true})}>Más información <i class="fas fa-arrow-circle-down"></i></a>
                </div>
            </div>
            <div class="col animated fadeIn">
                <div class="small-box bg-success">
                <div class="inner">
                    <h3>{tareasList.length}</h3>

                    <p>Tareas Asignadas al Grupo</p>
                </div>
                <div class="icon">
                    <i class="fas fa-coffee"></i>
                </div>
                <Link className="small-box-footer" to="/cursos/materia/tareas">Más información <i class="fas fa-arrow-circle-right"></i></Link>
                </div>
            </div>
            
            <div class="col animated fadeIn">
                <div class="small-box bg-purple">
                <div class="inner">
                    <h3>{totalTareasPorRevisar()}</h3>
                    <p>Tareas por Calificar</p>
                </div>
                <div class="icon">
                    <i class="fas fa-thumbtack"></i>
                </div>
                <a href="#" class="small-box-footer" onClick={() => scroller.scrollTo("alumnos", {duration: 1000,delay: 100, smooth: true})}>
                    Más información <i class="fas fa-arrow-circle-down"></i>
                </a>
                </div>
            </div>

            {diasCalificaciones === 0 &&
            <div class="col animated fadeIn">
            
                <div class="small-box bg-danger">
                <div class="inner">
                    <h3>Hoy</h3>
        
                    <p>Último día para entregar calificaciones</p>
                </div>
                <div class="icon">
                    <i class="fas fa-percent"></i>
                </div>
                <Link className="small-box-footer" to="/cursos/materia/calificaciones">Más información <i class="fas fa-arrow-circle-right"></i></Link>
                </div>
            </div>}

            {(diasCalificaciones <= 15 && diasCalificaciones !== 0) &&
            <div class="col animated fadeIn">
            
                <div class="small-box bg-warning">
                <div class="inner">
                    <h3>{diasCalificaciones}</h3>
        
                    <p>Días límite para entregar calificaciones finales</p>
                </div>
                <div class="icon">
                    <i class="fas fa-percent"></i>
                </div>
                <Link className="small-box-footer" to="/cursos/materia/calificaciones">Más información <i class="fas fa-arrow-circle-right"></i></Link>
                </div>
            </div>}


        </div>
        {banderaAsistencia &&
        <div className="row animated fadeIn">
            <div className="col-lg-12">
                <div class="alert alert-info alert-dismissible">
                <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                <h5><i class="icon fas fa-info"></i> Aviso!</h5>
                Hoy es la fecha del curso, cuenta hasta el final del día para registrar la asistencia de cada alumno.<br></br>
                Encontrara los siguientes símbolos  en la lista de los alumnos. <FontAwesomeIcon icon={faCheck}/> asistencia, <FontAwesomeIcon icon={faTimes}/> falta, <FontAwesomeIcon icon={faClock}/> retardo. Seleccione la opción para cada alumno según  corresponda.
                </div>
            </div>
        </div>}
        <Element name="alumnos"></Element>
        <div className="row animated fadeIn">
            <div className="col-lg-8">
                <Card className="shadow">
                    <Card.Header><h5>Alumnos</h5></Card.Header>
                    <Card.Body className="card-body p-0">
                        <table class="table table-striped">
                            <thead>
                                <tr >
                                    <th></th>
                                    <th>Nombre</th>
                                    <th>Correo</th>
                                    {banderaAsistencia &&
                                    <th className="text-center">Asistencia</th>}
                                    <th className="text-center">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {materia !== null ?
                                    materia.cargas.map(carga => {
                                        return (
                                            <tr>
                                                <td className="text-center ">
                                                    {carga.alumno !== null ? 
                                                        carga.alumno.foto !== null ?
                                                            <img 
                                                                src={`${RESOURCE_URL}/img/doc_alumno/${carga.alumno.user_id}/${carga.alumno.foto}`}
                                                                style={{ width: '50px', height: '50px' }}
                                                                className='img-circle elevation-2' 
                                                            />
                                                        : <img src={`${RESOURCE_URL}/img/perfil.png`} style={{ width: '50px', height: '50px' }} className='img-circle elevation-2'></img>
                                                    :<img src={`${RESOURCE_URL}/img/perfil.png`} style={{ width: '50px', height: '50px' }} className='img-circle elevation-2'></img>}
                                                    
                                                </td>
                                                <td className="align-middle">{carga.alumno.nombre} {carga.alumno.apellido_paterno} {carga.alumno.apellido_materno}</td>
                                                <td className="align-middle">{carga.alumno.email}</td>
                                                {banderaAsistencia &&
                                                <td className="align-middle text-center">
                                                    <ButtonGroup aria-label="Basic example" size='sm'>
                                                        <OverlayTrigger overlay={<Tooltip id={(1).toString()}>Asistencia</Tooltip>}>
                                                            <Button 
                                                                variant={getAsistencia(carga.alumno.asistencias) === 1 ? 'primary' : 'secondary'}
                                                                onClick={() => dispatch(registrarAsistenciaMateria(materia.id, docenteId, carga.alumno.id, 1))}
                                                            >
                                                                <FontAwesomeIcon icon={faCheck}/>
                                                            </Button>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger overlay={<Tooltip id={(2).toString()}>Falta</Tooltip>}>
                                                            <Button variant={getAsistencia(carga.alumno.asistencias) === 2 ? 'danger' : 'secondary'} onClick={() => dispatch(registrarAsistenciaMateria(materia.id, docenteId, carga.alumno.id, 2))}><FontAwesomeIcon icon={faTimes}/></Button>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger overlay={<Tooltip id={(3).toString()}>Retardo</Tooltip>}>
                                                            <Button variant={getAsistencia(carga.alumno.asistencias) === 3 ? 'warning' : 'secondary'} onClick={() => dispatch(registrarAsistenciaMateria(materia.id, docenteId, carga.alumno.id, 3))}><FontAwesomeIcon icon={faClock}/></Button>
                                                        </OverlayTrigger>
                                                    </ButtonGroup>
                                                </td>}
                                                <td className="align-middle text-center">
                                                    
                                                    <button class="btn btn-sm btn-app bg-success" onClick={() => selectAlumnoTarea(carga.tareas, carga.alumno)}>
                                                        <span class="badge bg-purple" hidden={tareasPorRevistar(carga.tareas) > 0 ? false : true}><strong>{tareasPorRevistar(carga.tareas)}</strong></span>
                                                        <i class="fas fa-coffee"></i> Tareas
                                                    </button>  
                                                    
                                                </td>
                                            </tr>
                                        )
                                    })   
                                : null}
                            </tbody>
                        </table>
                    </Card.Body>
                </Card>
            </div>
            <div className="col-lg-4">
                <Card className="shadow">
                    <Card.Header><h5>Modulos</h5></Card.Header>
                    <Card.Body className="card-body p-0">
                        <table class="table">
                            <thead>
                                <tr>
                                <th>Fecha</th>
                                </tr>
                            </thead>
                            <tbody>
                                {materia !== null ? 
                                    materia.planeacion.modulos.map(modulo => {
                                        return (
                                            <tr>
                                                <td>{getFecha(modulo.fecha)}</td>
                                            </tr>
                                        )
                                    })
                                : null}
                            </tbody>
                        </table>
                    </Card.Body>
                    <Card.Footer className="bg-primary">
                        <button className="btn btn-sm btn-block btn-primary" onClick={() => openModalSolicitud(true)}>
                            <FontAwesomeIcon icon={faCalendarPlus}/> Solicitar
                        </button>
                    </Card.Footer>
                </Card>
            </div>

            
            <Modal
                show={openSolicitud}
                onHide={() => openModalSolicitud(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Solicitud de Módulos
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12 mb-3">
                                <div class="alert alert-info alert-dismissible">
                                    <h5><i class="icon fas fa-info"></i> Info!</h5>
                                    La solicitud de módulos le permitira solicitar una fecha para un nuevo módulo.
                                </div>
                            </div>
                        </div>
                        <div className="row animated fadeIn">
                            <div className="col-lg-12"> <hr></hr></div>
                            <div className="col-lg-12 text-center mb-3">
                               
                                <label>Ingrese la fecha y hora deseados</label>
                            </div>
                            <div className="col-lg-6">
                                <Form.Group>
                                    <Form.Label>Fecha</Form.Label>
                                    <InputGroup hasValidation>
                                        <input 
                                            type="date" 
                                            class={`form-control ${fechaVal ? 'is-invalid': ''}`}
                                            name="fecha_entrega"
                                            value={fecha}
                                            onChange={(e) => setFecha(e.target.value)} 
                                        />
                                        <Form.Control.Feedback type="invalid" tooltip>La fecha es un dato obligatorio</Form.Control.Feedback>
                                    </InputGroup>
                                    
                                </Form.Group>
                            </div>
                            <div className="col-lg-6">
                                <Form.Group>
                                    <Form.Label>Hora</Form.Label>
                                    <InputGroup hasValidation>
                                        <input 
                                            type="time" 
                                            class={`form-control ${horaVal ? 'is-invalid': ''}`}
                                            name="fecha_entrega"
                                            value={hora}
                                            onChange={(e) => setHora(e.target.value)} 
                                        />
                                        <Form.Control.Feedback type="invalid" tooltip>La hora es un dato obligatorio</Form.Control.Feedback>
                                    </InputGroup>
                                    
                                </Form.Group>
                            </div>
                            
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => openModalSolicitud(false) }>
                        <FontAwesomeIcon icon={faTimes} /> Cancelar
                    </Button>
                    <Button variant="primary" onClick={enviarSolicitud}><FontAwesomeIcon icon={faEnvelope}/> Enviar</Button>
                </Modal.Footer>
            </Modal>
        </div>
        </>
     );
}
 
export default MateriaDocente;