import { Redirect } from 'react-router-dom';
import clienteAxios from '../../config/axios';
import { setDocente } from '../actions/Docentes.actions';
import { setDatosAlumno } from './Alumnos.action';
import {
    AUTENTICACION_LOGIN,
    AUTENTICACION_EXITO,
    AUTENTICACION_ERROR,
    DATOS_USUARIO,
    RESET_AUTH,
    RESET_USUARIO
} from '../../types';
import { msjError, mensajeWarningToastify, mensajeSuccessToastify } from '../../components/recursos/mensajes';
import store from "../../store";
const token = `${store.getState().Authentication.courrentUser.token_type} ${store.getState().Authentication.courrentUser.access_token}`;

export const updateUsuario = (name, email, id) => {
    return dispatch => {
        dispatch( loading_login (true) );
        clienteAxios.post('usuario/update/'+id,
        { name, email }, {
            headers: {
                'Authorization' : token
            }
        }).then( response => {

            if (response.data.success) {
                dispatch(
                    setUsuario({
                        id: response.data.usuario.id,
                        name: response.data.usuario.name,
                        email: response.data.usuario.email,
                        role_id: response.data.usuario.role_id
                    })
                );
                mensajeSuccessToastify(response.data.message);
            } else {
                mensajeWarningToastify(response.data.message);
            }
            
            dispatch( loading_login (false) );

        }).catch( error => {
            dispatch( loading_login (false) );
            msjError(error);
        });  
    }
}

export const updatePassword = (password, new_password, confirm_password, id) => {
    return dispatch => {
        dispatch( loading_login (true) );
        clienteAxios.post('usuario/update-password/'+id,
        { password, new_password, confirm_password }, {
            headers: {
                'Authorization' : token
            }
        }).then( response => {

            if (response.data.success) {
                mensajeSuccessToastify(response.data.message);
            } else {
                mensajeWarningToastify(response.data.message);
            }
            
            dispatch( loading_login (false) );

        }).catch( error => {
            dispatch( loading_login (false) );
            msjError(error);
        });  
    }
}

export function login_acceso ( username, password) {
    return ( dispatch ) => {
        dispatch( loading_login (true) );
        clienteAxios.post('login',{ 'username': username, 'password': password }).then( response => {

            const hoy = Date.now();
            const f = hoy + (response.data.expires_in * 1000);
            
            let currentUser = { 
                isSignedIn: true,
                token_type: response.data.token_type,
                expires_in: response.data.expires_in,
                dateExpire: f,
                access_token: response.data.access_token,
                refresh_token: response.data.refresh_token
            };
            if (response.status === 200) {
                dispatch( crearAcceso (currentUser) );
                dispatch( getUsuario(username) );
                
            } else {
                dispatch( ErrorAuth(true) );
            }
            
            dispatch( loading_login (false) );

        }).catch( error => {
            dispatch( ErrorAuth(true) );
            dispatch( loading_login (false) );
        });  

    }
};

export const getUsuario = (username) => {
    return (dispatch) => {
        clienteAxios.post('usuario', { username: username }).then( response => {
                    
            dispatch( setUsuario(response.data.usuario ) );
            if(response.data.usuario.role_id == '1'){
                window.location.replace('/grupos');
            }
            if (response.data.usuario.role_id == '2') {
                dispatch( setDocente(response.data.relacion) );
                window.location.replace('/cursos');
            }
            if (response.data.usuario.role_id == '3') {
                dispatch( setDatosAlumno(response.data.relacion) );
                window.location.replace('/alumno/cursos');
            }
                
        });
    }
}

export function resetUsuario ( ) {
    
    return ( dispatch) => {
        dispatch ( limpiarDatosUsuario() );
    }
}

export function resetAuth () {
    return (dispatch) => {
        /* localStorage.clear(); */
        dispatch( limpiarDatosAcceso() );
    }
}

export function limpiarDatosUsuario () {
    return {
        type: RESET_USUARIO
    }
}

export const crearAcceso = (currentUser) => {
    return {
        type: AUTENTICACION_EXITO,
        payload: currentUser
    }
}

export const loading_login = (loading) => {
    return {
        type: AUTENTICACION_LOGIN,
        payload: loading
    }
}

export const setUsuario = (usuario) => {
    return {
        type: DATOS_USUARIO,
        payload: usuario
    }
}

export const limpiarDatosAcceso = () => {
    return {
        type: RESET_AUTH
    }
}

export const ErrorAuth = (error) => {
    return {
        type: AUTENTICACION_ERROR, 
        payload: error
    }
}

