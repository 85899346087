import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { scroller } from 'react-scroll';

import { 
    obtenerListaDocentes, 
    registrarDatosDocente, 
    modificarDatosDocente, 
    obtenerHorarioDocente, 
    solicitudCambioContrasena,
    setHorario 
} from '../../redux/actions/Docentes.actions'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { OverlayTrigger, Button, Tooltip, Modal } from 'react-bootstrap';
import DocentesEdit from './DocentesEdit';
import DocentesConsulta from './DocentesConsulta';
import { useReactToPrint } from 'react-to-print';
import Encabezado from '../recursos/Encabezado';


const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
    } ) => (
    <div className="btn-group" role="group">
    {
        options.map((option) => {

        const isSelect = currSizePerPage === `${option.page}`;
        return (
            <button
                key={ option.text }
                type="button"
                onClick={ () => onSizePerPageChange(option.page) }
                className={ `btn ${isSelect ? 'btn-secondary' : 'btn-primary'}` }
            >
            { option.text }
            </button>
        );
        })
    }
    </div>
);

const options = {
    sizePerPageRenderer
}

const PrincipalDocentes = () => {

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Horario',
        pageStyle: () => {
           return  "@page { size: auto; margin:s 20mm }"
        }
    });

    const [openHorario, setopenHorario] = useState(false);
    const [controlModalSolicitud, setControlModalSolicitud] = useState(false);
    const [solicitudUserId, setSolicitudUserId] = useState(null);
    const [docente, setdocente] = useState({
        id: null,
        nombre: null,
        apellidoP: null, 
        apellidoM: null,
        correo: null,
        telefono: null, 
        imgPerfil: null,
        fileCedula: null, 
        fileActa: null,
        fileCertificado: null
    });
    const [docenteConsulta, setdocenteConsulta] = useState({
        id: null,
        nombre: null,
        apellidoP: null, 
        apellidoM: null,
        correo: null,
        telefono: null, 
        imgPerfil: null,
        fileCedula: null, 
        fileActa: null,
        fileCertificado: null
    });
    const [docenteValidacion, setdocenteValidacion] = useState({
        nombre: false,
        apellidoP: false,
        correo: false,
        correoInvalido: false
    });
    const [open, setopen] = useState(false);
    const [openConsulta, setopenConsulta] = useState(false);
    const [vistaPrevia, setvistaPrevia] = useState(null);
    
    const auth = useSelector(storeState => storeState.Authentication.courrentUser);
    const { horario } = useSelector(storeState => storeState.Docentes);
    const { docentesList, errorDocente, loading, loadingHorario, loadingList } = useSelector(storeState => storeState.Docentes);
    
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch( obtenerListaDocentes([]) );
    }, []);

    useEffect(() => {
        if(!loading) {
            if (!errorDocente) {
                setopen(false);
            }
        }
    }, [loading]);

    const changeFiles = (e) => {
        console.log(e.target.files);
        setdocente({ ...docente, [e.target.name]: e.target.files[0] });
    }

    const changeImg = (e) => {
        console.log(e.target.files);
        const img = e.target.files[0];
        const imgURL = URL.createObjectURL(img);
        setvistaPrevia(imgURL);
        setdocente({ ...docente, [e.target.name]: e.target.files[0] });
    }

    const changeInput = (e) => {

        if (e.target.name === 'correo') {

            let campo = e.target;
            let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
            
            if (emailRegex.test(campo.value)) {

                setdocenteValidacion({ ...docenteValidacion, correoInvalido: false });
            
            } else {

                setdocenteValidacion({ ...docenteValidacion, correoInvalido: true});

            }
        }     
        
        setdocente({ ...docente, [e.target.name]: e.target.value !== '' ? e.target.value : null });
    };

    const openClose = (open) => {
        setopen(open);
        setdocente({
            id: null,
            nombre: null,
            apellidoP: null, 
            apellidoM: null,
            correo: null,
            telefono: null, 
            imgPerfil: null,
            fileCedula: null, 
            fileActa: null,
            fileCertificado: null
        });
        setdocenteValidacion({
            nombre: false,
            apellidoP: false,
            correo: false,
            correoInvalido: false
        });
        setvistaPrevia(null);
        
    };

    const openEditarDocente = (row) => {
        setopen(true);
        setdocente({
            id:row.id,
            nombre: row.nombre,
            apellidoP: row.apellido_paterno,
            apellidoM: row.apellido_materno,
            correo: row.email,
            telefono: row.telefono,
            imgPerfil: row.foto,
            fileCedula: row.cedula, 
            fileActa: row.acta_nacimiento,
            fileCertificado: row.certificado,
            user: row.user
        }); 
    }

    const openConsultaDocente = (open, row) => {
        setopenConsulta(open);
        setdocenteConsulta({
            id: open ? row.id : null,
            nombre: open ? row.nombre : null,
            apellidoP: open ? row.apellido_paterno: null, 
            apellidoM: open ? row.apellido_materno : null,
            correo: open ? row.email : null,
            telefono: open ? row.telefono : null, 
            imgPerfil: open ? row.foto : null,
            fileCedula: open ? row.cedula: null, 
            fileActa: open ? row.acta_nacimiento : null,
            fileCertificado: open ? row.certificado : null,
            user: open ? row.user : null
        });
    }

    const guardarDatosDocente = () => {
        setdocenteValidacion({
            ...docenteValidacion, 
            nombre: docente.nombre !== null ? false : true,
            apellidoP: docente.apellidoP !== null ? false : true,
            correo: docente.correo !== null ? false : true
        });
    
        if (docente.nombre !== null && docente.apellidoP && docente.correo !== null && !docenteValidacion.correoInvalido) {
            
            let formData  = new FormData();
            formData.append('id',docente.id);
            formData.append('nombre',docente.nombre);
            formData.append('apellido_paterno',docente.apellidoP);
            formData.append('apellido_materno',docente.apellidoM === null ? '' : docente.apellidoM);
            formData.append('email',docente.correo);
            formData.append('telefono',docente.telefono === null ? '' : docente.telefono );
            formData.append('foto', 
                docente.imgPerfil !== null ? 
                    docente.imgPerfil.name ? docente.imgPerfil : ''
                : ''
            );
            formData.append('cedula', 
                docente.fileCedula !== null ? 
                    docente.fileCedula.name ? docente.fileCedula : '' 
                : ''
            );
            formData.append('acta_nacimiento', 
                docente.fileActa !== null ? 
                    docente.fileActa.name ? docente.fileActa : ''
                : ''
            );
            formData.append('certificado', 
                docente.fileCertificado !==  null ? 
                    docente.fileCertificado.name ? docente.fileCertificado : ''
                : ''
            );
            
            if (docente.id === null) {
                dispatch( registrarDatosDocente( formData) );
            } else {
                dispatch( modificarDatosDocente(formData, docente.id) );
            }
            
        }

    };

    const openModalHorario = (id) => {
        setopenHorario(true);
        dispatch( obtenerHorarioDocente(id) );
    };

    const openModalSolicitud = (user_id) => {
        setSolicitudUserId(user_id);
        setControlModalSolicitud(true);        
    }

    const solicitarContrasena = () => {
        dispatch( solicitudCambioContrasena(solicitudUserId) );
        setSolicitudUserId(null);
        setControlModalSolicitud(false);
    }

    const columns = [ {
        dataField: 'nombre',
        text: 'Nombre',
        sort: true,
        align: 'left',
        headerAlign: 'center',
        style: {
            width: '10%',
        },
        filter: textFilter({ placeholder: 'Nombre', className: 'form-control-sm' })
      }, {
        dataField: 'apellido_paterno',
        text: 'Apellido paterno',
        sort: true,
        align: 'left',
        headerAlign: 'center',
        style: {
            width: '15%',
        },
        filter: textFilter({ placeholder: 'Apellido paterno', className: 'form-control-sm' })
      },{
        dataField: 'apellido_materno',
        text: 'Apellido materno',
        sort: true,
        align: 'left',
        headerAlign: 'center',
        style: {
            width: '15%',
        },
        formatter: (cell, row) => (row.apellido_materno !== null ? row.apellido_materno : '--'),
        filter: textFilter({ placeholder: 'Apellido materno', className: 'form-control-sm' })
      }, {
        dataField: 'email',
        text: 'Correo',
        sort: true,
        align: 'left',
        headerAlign: 'center',
        style: {
            width: '20%'
        },
        filter: textFilter({ placeholder: 'Correo electrónico', className: 'form-control-sm'})
      }, {
        dataField: 'telefono',
        text: 'Teléfono',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        style: {
            width: '10%'
        },
        formatter: (cell, row) => (row.telefono !== null ? row.telefono : '--'),
        filter: textFilter({ placeholder: 'Teléfono', className: 'form-control-sm' })
      }, {
        dataField: '1',
        text: 'Estatus de registro',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        style: {
            width: '15%'
        },
        formatter: (cell, row) => {
            let contador = 0;
            if (row.nombre !== null) {
                contador++;
            }
            if (row.apellido_paterno !== null) {
                contador++;
            }
            if (row.apellido_materno !== null) {
                contador++;
            }
            if (row.email !== null) {
                contador++;
            }
            if (row.telefono !== null) {
                contador++;
            }
            if (row.cedula !== null) {
                contador++;
            }
            if (row.certificado !== null) {
                contador++;
            }
            if (row.acta_nacimiento !== null) {
                contador++;
            }
            if (row.foto !== null) {
                contador++;
            }
            let porcentaje = ((contador/9)*100).toFixed(2);
            let color = 'bg-danger';

            if(porcentaje > 0 && porcentaje < 50){
                color = 'bg-danger';
            }
            if(porcentaje > 50 && porcentaje < 100){
                color = 'bg-primary';
            }
            if(porcentaje >= 100){
                color = 'bg-success';
            }

            return (
                <div className="progress">
                    <div className={`progress-bar ${color}`} style={{width: porcentaje+'%'}}>{porcentaje+'%'}</div>
                </div>
            );
        }
      }, {
        dataField: '3',

        text: 'Acciones',
        sort: true,
        aling: 'center', 
        headerAlign: 'center',
        align: 'center',
        style: {
            width: '15%'
        },
        formatter: (cell, row) => {
            return (
                <div className="btn-group">
                    <OverlayTrigger overlay={<Tooltip id={row.id}>Modificar datos</Tooltip>}>
                        <Button variant="warning" onClick={() => openEditarDocente(row)} size="sm"><i class="fa fa-edit"></i></Button>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip id={row.id}>Consultar datos</Tooltip>}>
                        <Button variant="secondary" onClick={() => openConsultaDocente(true, row)} size="sm"><i class="fa fa-address-card"></i></Button>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip id={row.id}>Consultar horario</Tooltip>}>
                        <Button variant="primary" onClick={() =>  openModalHorario(row.id)} size="sm"><i class="fa fa-clock"></i></Button>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip id={row.id} >Solicitar nueva contraseña</Tooltip>}>
                        <Button variant="danger" onClick={() => openModalSolicitud(row.user_id) } size="sm"><i class="fa fa-key"></i></Button>
                    </OverlayTrigger>
                    
                </div>
            );
        }
      }];

    return (
        <>
        <title>CCH | Docentes</title>
        <Encabezado/>
        <div className="row animated fadeIn">
                <div className="col-lg-12 mb-3">
                    
                    <OverlayTrigger overlay={<Tooltip id={(1000).toString()}>Registrar Docente</Tooltip>}>
                        <Button variant="primary" className="float-right" onClick={() => openClose(true)} style={{ borderRadius: '50%' }}><i className="fas fa-plus" ></i></Button>
                    </OverlayTrigger>
                </div>
                <div className="col-lg-12">
                    <div className="card shadow bg-white rounded">
                     <div className="card-header">
                        <h3 className="card-title">Lista de docentes</h3>
                        {loadingList ?
                            <div className="float-right">
                                <FontAwesomeIcon icon={faSync} spin/>
                            </div>
                            
                        : null}
                    </div> 
                    <div className="card-body table-responsive p-2">
                        <BootstrapTable 
                            classes="table table-striped" 
                            keyField='id' 
                            defaultSorted={ [{
                                dataField: 'id',
                                order: 'desc'
                              }]} 
                            data={ docentesList } 
                            columns={ columns } 
                            filter={ filterFactory() }
                            pagination={ paginationFactory(options) }
                            filterPosition="top"
                            noDataIndication="No se encontrarón resultados."
                        />
                    </div>
                    
                    </div>
                   
                </div>
            </div>
            <DocentesEdit
                open={open}
                openClose={openClose}
                docente={docente} 
                vistaPrevia={vistaPrevia}
                changeFiles={changeFiles}
                changeImg={changeImg}
                changeInput={changeInput}
                docenteValidacion={docenteValidacion}
                guardarDatosDocente={guardarDatosDocente}
                loading={loading}
            />
            <DocentesConsulta
                open={openConsulta}
                openClose={openConsultaDocente}
                docente={docenteConsulta} 
            />
            <Modal
                show={controlModalSolicitud}
                onHide={() => { setControlModalSolicitud(false); setSolicitudUserId(null) } }
                centered
                backdrop="static"
                keyboard={false}
                centered
                
            >
                <Modal.Header>
                    
                    <h6>Solicitud de Cambio de Contraseña</h6>
                    
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="col-lg-12 text-center">
                        Se enviara un correo con la nueva contraseña<br></br>
                        ¿Esta seguro de cambiar las credenciales del docente seleccionado?
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-danger btn-sm " onClick={() => setControlModalSolicitud(false)}>
                        <i className="fa fa-times"></i> Cancelar
                    </button>
                    <button type="button" className="btn btn-success btn-sm " onClick={() => solicitarContrasena()}>
                        <i className="fa fa-check"></i> Confirmar
                    </button>
                </Modal.Footer>
            </Modal>
            <Modal
                dialogClassName="modal-dialog modal-xl"
                show={openHorario}
                onHide={() => setopenHorario(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                scrollable
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                <h4>Horario del Docente</h4>
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container"  ref={componentRef}>
                    <div className="row">
                    <div className="col-lg-12 mb-3 text-center">
                        <img src="/img/cch.jpg" alt="..." width="150"/> 
                        
                    </div>
                    <div className="col-lg-12 mb-3 text-center">
                    <h2>Centro de Crecimiento Humanista</h2>
                    </div>
                    <div className="col-lg-12">
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th colSpan={5} className="text-center">
                                        {horario !== null ? `${horario.docente.nombre} ${horario.docente.apellido_paterno} ${horario.docente.apellido_materno ? horario.docente.apellido_materno : ''}` : null}
                                    </th>
                                </tr>
                                <tr>
                                    <th class="text-center" scope="col">Materia</th>
                                    <th class="text-center" scope="col">Sede</th>
                                    <th class="text-center" scope="col">Grupo</th>
                                    <th class="text-center" scope="col">Fecha</th>
                                    <th class="text-center" scope="col">Hora</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!loadingHorario ?
                                    horario!== null ?
                                        horario.modulos.map(modulo => {
                                            return (
                                                <tr>
                                                    <td class="text-left">{modulo.materia}</td>
                                                    <td>{modulo.sede}</td>
                                                    <td>{modulo.grupo}</td>
                                                    <td>{modulo.fecha}</td>
                                                    <td>{modulo.hora}</td>
                                                </tr>    
                                            )
                                        })
                                    : 
                                    <tr>
                                        <td className="text-center" colSpan="5">
                                            No se encontrarón resultados
                                        </td>
                                    </tr>
                                : 
                                    <tr>
                                        <td className="text-center" colSpan="5">
                                            <i className="fa fa-spinner fa-spin"></i> Cargando horario, por favor espere...
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    </div>
                    </div>
                
                </Modal.Body>
                <Modal.Footer>
                <button type="button" className="btn btn-danger btn-sm float-right" onClick={handlePrint}>Descargar</button>
                    
                </Modal.Footer>
            </Modal>


        </>
    );

}

export default PrincipalDocentes;