import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCoffee, faSave } from '@fortawesome/free-solid-svg-icons';
import { Form, Card } from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';

/* actions */
import { setTarea, editarTareaAlumno } from '../../redux/actions/Tareas.action';
import SessionExpireLabel from '../recursos/SessionExpireLabel';
/* end actions */

const MateriaEditTareaAlumno = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const editorRef = useRef(null);

    const [texto, setTexto] = useState(null);
    const [archivo, setArchivo] = useState(null);

    const materia = useSelector(state => state.Materias.materia);
    const tarea = useSelector(state => state.Tareas.tarea);
    const user = useSelector(state => state.Authentication.usuario);

    const guardarTarea = () => {
        let formData = new FormData();
        formData.append('id',tarea.id);
        formData.append('texto',tarea.texto);
        formData.append('archivo', 
            archivo !== null ? 
                archivo.name ? archivo : ''
            : ''
        );

        dispatch( editarTareaAlumno( formData, materia.id ) );
        history.push('/alumno/tareas');
    }

    const changeArchivo = (e) => {
        setArchivo(e.target.files[0]);
    }

    const banderaFechaLimite = (fechaTarea) => {
        const cadena = fechaTarea.split(' ');
        const fechaActual = new Date();
        const fechaLimite = new Date(cadena[0]);

        if(tarea.calificacion !== null) {
            return false;
        }

        if (fechaActual <= fechaLimite) {
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        setTexto(tarea.texto);
        setArchivo(tarea.archivo);
    }, []);

    
    return ( 
        <>
        <title>CCH | Tarea</title>
        <div className="row animated fadeIn">
        
            <div className="col-lg-12">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row ">
                            <div className="col-lg-12">
                                <div class="callout callout-info">
                                <div className="row">
                                        <div className="col-lg-12 mb-1">
                                            <h5><FontAwesomeIcon icon={faCoffee}/> Tarea </h5>
                                        </div>
                                        <div className="col-lg-12">
                                            <strong>Materia:</strong> {materia && materia.materia_nombre.nombre_materia}
                                        </div>
                                        <div className="col-lg-12">
                                            <strong>Grupo:</strong> {materia && materia.grupo.nombre}
                                        </div>
                                        <div className="col-lg-12">
                                            <strong>Sede:</strong> {materia && materia.sede.nombre_sede}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="col-lg-12">
                <hr></hr>
            </div>

            <div className="col-lg-12 mb-3">
                <SessionExpireLabel/>
                <button className="btn btn-link float-right" onClick={() => history.push('/alumno/tareas')}>
                    <FontAwesomeIcon icon={faArrowLeft}/> Volver
                </button>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-12 mb-3">
                <Card>
                    <Card.Header>{tarea.titulo}</Card.Header>
                    <Card.Body >
                        <div className="row">
                            <div className="col-lg-12 mt-3">
                                <strong>Fecha límite de entrega:</strong> {tarea.fecha_entrega}
                            </div>
                            <div className="col-lg-12 mb-3 mt-3">
                                <strong>Descripción:</strong><br></br>{tarea.descripcion}
                            </div>
                            {banderaFechaLimite(tarea.fecha_entrega) &&
                            <div className="col-lg-6 mb-3">
                                <label for="imgPerfil">Puedes subír algún archivo</label>
                                <div class="custom-file">
                                    <input type="file" className="custom-file-input" id="archivo" name="archivo" lang="es" onChange={changeArchivo}/>
                                    <label class="custom-file-label" for="archivo">
                                        {archivo !== null ? 
                                            archivo.name ? archivo.name : archivo
                                        : 'Seleccionar archivo'}
                                    </label>
                                </div> 
                            </div>}
                            {!banderaFechaLimite(tarea.fecha_entrega) &&
                            <ul class="mailbox-attachments d-flex align-items-stretch clearfix" >
                            <li hidden={tarea.archivo !== null ? false : true}>
                                <span class="mailbox-attachment-icon"><i class="far fa-file-pdf"></i></span>

                                <div class="mailbox-attachment-info">
                                    <a href={`http://localhost:8001/img/doc_alumno/${user.id}/tareas/${tarea.archivo}`} target="_blank" class="mailbox-attachment-name"><i class="fas fa-paperclip"></i> {tarea.archivo}</a>
                                    <span class="mailbox-attachment-size clearfix mt-1">
                                    <span>1,245 KB</span>
                                    <a href={`http://localhost:8001/img/doc_alumno/${user.id}/tareas/${tarea.archivo}`} target="_blank" class="btn btn-default btn-sm float-right"><i class="fas fa-cloud-download-alt"></i></a>
                                    </span>
                                </div>
                            </li>
                            </ul>}
                        </div>
                        {banderaFechaLimite(tarea.fecha_entrega) &&
                        <Editor 
                            onInit={(evt, editor) => editorRef.current = editor }
                            onEditorChange={(newText) => dispatch( setTarea({...tarea, texto: newText}) )}
                            value={tarea.texto}
                            init={{
                            height: 400,
                            menubar: 'file edit view format table tc',
                            plugins: [
                                'advlist autolink lists link image fullscreen media charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount',
                                
                            ],
                            toolbar: 'undo redo | fontselect fontsizeselect | ' +
                            'bold italic backcolor forecolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | link | ' +
                            'fullscreen | ',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        />
                        }
                        {!banderaFechaLimite(tarea.fecha_entrega) &&
                        <Editor 
                            onInit={(evt, editor) => editorRef.current = editor }
                            value={tarea.texto}
                            init={{
                            height: 400,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image fullscreen media charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount',
                                
                            ],
                            toolbar: 'fullscreen | ',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        />
                        }
                        
                    </Card.Body>
                    {banderaFechaLimite(tarea.fecha_entrega) &&
                    <Card.Footer>
                        <button className="btn btn-primary float-right" onClick={guardarTarea}><FontAwesomeIcon icon={faSave}/> Guardar</button>
                    </Card.Footer>
                    }
                    
                </Card>
            </div>
        </div>
        </> 
    );
}
 
export default MateriaEditTareaAlumno;