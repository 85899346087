export const AUTENTICACION_LOGIN = 'AUTENTICACION_LOGIN';
export const AUTENTICACION_EXITO = 'AUTENTICACION_EXITO';
export const AUTENTICACION_ERROR = 'AUTENTICACION_ERROR';
export const DATOS_USUARIO = 'DATOS_USUARIO';
export const HORA_EXPIRA = 'HORA_EXPIRA';
export const RESET_AUTH = 'RESET_AUTH';
export const RESET_USUARIO = 'RESET_USUARIO';

export const LISTA_GRUPOS = 'LISTA_GRUPOS';
export const DATOS_GRUPO = 'DATOS_GRUPO';
export const LOADING_GRUPOS = 'LOADING_GRUPOS';
export const LOADING_GRUPO = 'LOADING_GRUPO';
export const RESET_GRUPOS = 'RESET_GRUPOS';
export const ERROR_GRUPOS = 'ERROR_GRUPOS';
export const ERROR_GRUPO = 'ERROR_GRUPO';
export const REGISTRO_EXITO = 'REGISTRO_EXITO';
export const REGISTRO_FALLIDO = 'REGISTRO_FALLIDO';

export const CARGAR_SEDES = 'CARGAR_SEDES';
export const LOADING_SEDES = 'LOADING_SEDES';
export const ERROR_SEDES = 'ERROR_SEDES';
export const RESET_SEDES = 'RESET_SEDES';

export const CARGAR_PROGRAMAS = 'CARGAR_PROGRAMAS';
export const LOADING_PROGRAMAS = 'LOADING_PROGRAMAS';
export const ERROR_PROGRAMAS = 'ERROR_PROGRAMAS';
export const RESET_PROGRAMAS = 'RESET_PROGRAMAS';

export const VALIDACION_GRUPO = 'VALIDACION_GRUPO';
export const RESET_VALIDACION = 'RESET_VALIDACION';

export const URL_PARAMETROS = 'URL_PARAMETROS';
export const RESET_PLANEACION = 'RESET_PLANEACION';
export const CARGAR_PLANEACION_GRUPO = 'CARGAR_PLANEACION_GRUPO';
export const ERROR_PLANEACION_GRUPO = 'ERROR_PLANEACION_GRUPO'; 
export const ERROR_PLANEACION_MODULOS = 'ERROR_PLANEACION_MODULOS'; 
export const LOADING_PLANEACION_GRUPO = 'LOADING_PLANEACION_GRUPO';
export const LOADING_PLANEACION_MODULOS = 'LOADING_PLANEACION_MODULOS';
export const REGISTRAR_PLANEACION_MODULOS = 'REGISTRAR_PLANEACION_MODULOS';
export const REGISTRO_FALLIDO_MODULO = 'REGISTRO_FALLIDO_MODULO';


export const LISTA_MATERIAS = 'LISTA_MATERIAS';
export const DATOS_MATERIA = 'DATOS_MATERIA';
export const LOADING_MATERIAS = 'LOADING_MATERIAS';
export const LOADING_MATERIA = 'LOADING_MATERIA';
export const RESET_MATERIAS = 'RESET_MATERIAS';
export const ERROR_MATERIAS = 'ERROR_MATERIAS';
export const ERROR_MATERIA = 'ERROR_MATERIA';

export const CARGAR_MODULOS = 'CARGAR_MODULOS';
export const LOADING_MODULOS = 'LOADING_MODULOS';
export const ERROR_MODULO = 'ERROR_MODULO';
export const CARGAR_MODULO = 'CARGAR_MODULO';
export const LOADING_MODULO = 'LOADING_MODULO';
export const ERROR_MODULOS = 'ERROR_MODULOS';
export const RESET_MODULOS = 'RESET_MODULOS';


export const LISTA_DOCENTES = 'LISTA_DOCENTES';
export const DATOS_DOCENTE = 'DATOS_DOCENTE';
export const LOADING_DOCENTES = 'LOADING_DOCENTES';
export const LOADING_DOCENTE = 'LOADING_DOCENTE';
export const RESET_DOCENTES = 'RESET_DOCENTES';
export const ERROR_DOCENTES = 'ERROR_DOCENTES';
export const RESET_DOCENTE = 'RESET_DOCENTE';
export const ERROR_DOCENTE = 'ERROR_DOCENTE';
export const HORARIO_DOCENTE = 'HORARIO_DOCENTE';
export const LOADING_HORARIO_DOCENTE = 'LOADING_HORARIO_DOCENTE';

export const VALIDACION_DOCENTE = 'VALIDACION_GRUPO';
export const RESET_VALIDACION_DOCENTE = 'RESET_VALIDACION';

export const SET_LISTA_ALUMNOS = 'SET_LISTA_ALUMNOS';
export const SET_ALUMNO = 'SET_ALUMNO';
export const SET_LOADING_ALUMNOS = 'SET_LOADING_ALUMNOS';
export const SET_LOADING_ALUMNO = 'SET_LOADING_ALUMNO';
export const RESET_ALUMNOS = 'RESET_ALUMNOS';
export const RESET_ALUMNO = 'RESET_ALUMNO';
export const SET_ERROR_ALUMNOS = 'SET_ERROR_ALUMNOS';
export const SET_ERROR_ALUMNO = 'SET_ERROR_ALUMNO';
export const SET_HORARIO_ALUMNO = 'SET_HORARIO_ALUMNO';
export const SET_LOADING_HORARIO_ALUMNO = 'SET_LOADING_HORARIO_ALUMNO';

export const SET_TAREAS = 'SET_TAREAS';
export const SET_TAREA = 'SET_TAREA'; 
export const LOADING_TAREAS = 'LOADING_TAREAS';
export const LOADING_TAREA = 'LOADING_TAREA';
export const ERROR_TAREAS = 'ERROR_TAREAS';
export const ERROR_TAREA = 'ERROR_TAREA';
export const SET_SUCCESS_TAREA = 'SET_SUCCESS_TAREA';
export const RESET_TAREAS = 'RESET_TAREAS';

export const SET_ASISTENCIA_BANDERA = 'SET_ASISTENCIA_BANDERA';
export const SET_ASISTENCIAS = 'SET_ASISTENCIAS';
export const SET_LOADING_ASISTENCIA = 'SET_LOADING_ASISTENCIA';
export const SET_DIAS_CALIFICACIONES = 'SET_DIAS_CALIFICACIONES';
export const SET_LOADING_DIAS = 'SET_LOADING_DIAS';

export const SET_SOLICITUDES = 'SET_SOLICITUDES';
export const LOADING_SOLICITUDES = 'LOADING_SOLICITUDES';
export const ERROR_SOLICITUDES = 'ERROR_SOLICITUDES';
export const RESET_SOLICITUDES = 'RESET_SOLICITUDES';
export const LOADING_SOLICITUD = 'LOADING_SOLICITUD';