import clienteAxios from '../../config/axios';
import { mensajeSuccessToastify, mensajeDangerToastify, mensajeWarningToastify } from '../../components/recursos/mensajes';
import store from "../../store";
import {
    URL_PARAMETROS,
    RESET_PLANEACION,
    CARGAR_PLANEACION_GRUPO,
    ERROR_PLANEACION_GRUPO,
    LOADING_PLANEACION_GRUPO,
    REGISTRAR_PLANEACION_MODULOS,
    ERROR_PLANEACION_MODULOS,
    LOADING_PLANEACION_MODULOS,
    REGISTRO_EXITO,
    REGISTRO_FALLIDO
} from '../../types';

const token = `${store.getState().Authentication.courrentUser.token_type} ${store.getState().Authentication.courrentUser.access_token}`;

export const getDatosPlaneaciónGrupo = (grupo_id, programa_id) => {

    return (dispatch) => {
        dispatch( setLoading ( true ) );
        clienteAxios.post ('planeacion/grupo',
            { grupo_id, programa_id },
            {
                headers: {
                    'Authorization' : token
                }
            }).then(response => {

                dispatch ( cargarDatosPlaneacionGrupo ( response.data ) );
                dispatch( setError(false) );
                
            }).catch(error => {

                dispatch( setError(true) );
                dispatch( setLoading ( false ) );

            }).finally(final => {

                dispatch( setLoading ( false ) );

            });
    }

}

export const getDatosPlanAsignacion = (grupo_id, programa_id) => {

    return (dispatch) => {
        dispatch( setLoading ( true ) );
        clienteAxios.post('/cargar-planeacion-admin',
            { grupo_id, programa_id },
            {
                headers: {
                    'Authorization' : token
                }
            }).then(response => {
                dispatch ( cargarDatosPlaneacionGrupo ( response.data ) );
                dispatch( setError(false) );
            }).catch(error => {

                dispatch( setError(true) );
                dispatch( setLoading ( false ) );
                dispatch ( cargarDatosPlaneacionGrupo ( null ) );

            }).finally(final => {

                dispatch( setLoading ( false ) );

            });
    }

}

export const getDatosPlaneacionGrupoSuccess = (grupo_id, programa_id) => {

    return (dispatch) => {
        clienteAxios.post ('planeacion/grupo',
            { grupo_id, programa_id },
            {
                headers: {
                    'Authorization' : token
                }
            }).then(response => {

                dispatch ( cargarDatosPlaneacionGrupo ( response.data ) );
                dispatch( setError(false) );  

            }).catch(error => {

                dispatch( setError(true) );

            });
    }

}

export const registrarPlaneacionModulos = (no_modulos, id_materia ) => {
    return (dispatch) => {
        dispatch( setLoadingPlanModulos(true) );

        if (no_modulos !== 0) {
            clienteAxios.post('admin/crear-planeacion-modulos',
            {
                no_modulos: no_modulos,
                id_materia: id_materia
            },
            {
                headers: {
                    'Authorization' : token
                }
            }).then(response => {
                
                dispatch ( registroSuccess ( true ) );
                mensajeSuccessToastify(response.data.message);

                dispatch( setLoadingPlanModulos(false) );
                dispatch ( setErrorPlanModulos ( false ) );
        
            }).catch(error => {
                dispatch ( setErrorPlanModulos ( true ) );
                dispatch( setLoadingPlanModulos(false) );
                if (error.response) {//el servidor respondió con un código de estado que cae fuera del rango de 2xx
                
                    mensajeDangerToastify('Error');
    
                } else if (error.request) {//Se realizó la solicitud pero no se recibió respuesta
    
                    mensajeDangerToastify('Se realizó la solicitud pero no se recibió respuesta');
                    
                } else {// Algo sucedió al configurar la solicitud y provocó un error
                    
                    mensajeDangerToastify('Algo sucedió al configurar la solicitud');
    
                }
            });
        } else {
            dispatch( setLoadingPlanModulos(false) );
            dispatch ( registroFallido ( true ) );
        }
        
    }
    
}

export function resetSFE () {
    return (dispatch) => {
        dispatch ( setErrorPlanModulos ( false ) );
        dispatch ( registroSuccess ( false ) );
        dispatch ( registroFallido ( false ) );
    }
}

export function setParametros (grupoId, programa_id) {
    return (dispatch) => {
        dispatch ( guardarParametros(grupoId, programa_id) );
    }
}

export function reset () {
    return (dispatch) => {
        dispatch ( resetPlaneacion() );
    }
}

export const setLoading = (bandera) => {
    return {
        type: LOADING_PLANEACION_GRUPO,
        payload: bandera
    }
}

export const setLoadingPlanModulos = (bandera) => {
    return {
        type: LOADING_PLANEACION_MODULOS,
        payload: bandera
    }
}

export const setError = (bandera) => {
    return {
        type: ERROR_PLANEACION_GRUPO,
        payload: bandera
    }
}

export const setErrorPlanModulos = (bandera) => {
    return {
        type: ERROR_PLANEACION_MODULOS,
        payload: bandera
    }
}

export const cargarDatosPlaneacionGrupo = (planeacionGrupo) => {
    return {
        type: CARGAR_PLANEACION_GRUPO,
        payload: planeacionGrupo 
    }
}

export const guardarParametros = (grupoId, programa_id) => {
    return {
        type: URL_PARAMETROS,
        payload: { grupo_id: grupoId, programa_id: programa_id}
    }
}

export const resetPlaneacion = () => {
    return {
        type: RESET_PLANEACION
    }
}

export const registroSuccess = (success) => {
    return {
        type: REGISTRO_EXITO,
        payload: success
    }
}

export const registroFallido = (success) => {
    return {
        type: REGISTRO_FALLIDO,
        payload: success
    }
}


