import clienteAxios from '../../config/axios';

import {
    VALIDACION_GRUPO,
    RESET_VALIDACION,
    VALIDACION_DOCENTE
} from '../../types';

export function validarDatosGrupo (datos) {
    return (dispatch) => {
        dispatch ( validarGrupo(datos) );
    }
}

export function reset () {
    return (dispatch) => {
        dispatch ( resetValidacion () );
    }
}

export const validarGrupo = ( datos) => {
    return {
        type: VALIDACION_GRUPO,
        payload: datos
    }
}

export const resetValidacion = () => {
    return {
        type: RESET_VALIDACION
    }
}

////////////////////////////////
export const validarDatosDocente = (datos) => {
    return {
        type: VALIDACION_DOCENTE,
        payload: datos
    }
}
