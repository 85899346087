import clienteAxios from '../../config/axios';
import store from "../../store";
import { mensajeSuccessToastify, mensajeWarningToastify, msjError } from '../../components/recursos/mensajes';

import {
    SET_SOLICITUDES,
    LOADING_SOLICITUDES,
    ERROR_SOLICITUDES,
    RESET_SOLICITUDES,
    LOADING_SOLICITUD
} from '../../types';

const token = `${store.getState().Authentication.courrentUser.token_type} ${store.getState().Authentication.courrentUser.access_token}`;

export const obtenerSolicitudes = () => {
    return dispatch => {
        
        clienteAxios.get('solicitudes',
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {

            dispatch( setSolicitudesList(response.data) );
            
        }).catch(error => {

            msjError( error );  

        }).finally(final => {

        });
    }
}

export const cambiarEstatusSolicitud = (estatusId, planeacionId, solicitudId) => {
    return dispatch => {
        dispatch( setLoading ( true ) );
        clienteAxios.post('cambiar/estatus-solicitud',
        { estatusId, planeacionId, solicitudId },
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {

            if (response.data.success) {
                mensajeSuccessToastify(response.data.message);
            } else {
                mensajeWarningToastify(response.data.message);
            }
            dispatch( obtenerSolicitudes() );
           
        }).catch(error => {
            msjError(error);
        }).finally(final => {
            dispatch( setLoading ( false ) );
        });
    }
}

export const setSolicitudesList = (solicitudes) => {
    return {
        type: SET_SOLICITUDES,
        payload: solicitudes
    }
};

export const setLoading = (loading) => {
    return {
        type: LOADING_SOLICITUD,
        payload: loading
    }
}
