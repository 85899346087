import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Form, Modal } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const HorarioAlumnoModal = ({ openHorario, setOpenHorario, matriculas, setMatriculaId, seleccionarHorario }) => {
    
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Horario',
        pageStyle: () => {
           return  "@page { size: auto; margin:s 20mm }"
        }
    });

    const { horario, loadingHorario } = useSelector(state => state.Alumnos);
    const componentRef = useRef();

    return ( 
        <>
        <Modal
            size="lg"
            show={openHorario}
            onHide={() => setOpenHorario(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            scrollable
            dialogClassName="modal-dialog modal-xl"
        >
                <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                <h4>Horario del Alumno</h4>
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label for="exampleFormControlSelect1">Programas</label>
                                <select class="form-control" onChange={(e) => seleccionarHorario(e.target.value)}>
                                <option value='0'>Seleccionar programa</option>
                                {matriculas.map(matricula => {
                                    return (
                                        <option value={matricula.id}>{matricula.matricula} - {matricula.carga_programa ?  matricula.carga_programa.programa ? matricula.carga_programa.programa.nombre_programa : null : null}</option>
                                    )
                                })}
                                </select>
                            </div>
                        </div>
                    </div>
                    
                    <div className="container"  ref={componentRef}>
                    <div className="row">
                    <div className="col-lg-12 mb-3 text-center">
                        <img src="/img/cch.jpg" alt="..." width="150"/> 
                        
                    </div>
                    <div className="col-lg-12 mb-3 text-center">
                    <h2>Centro de Crecimiento Humanista</h2>
                    </div>
                    <div className="col-lg-12">
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th colSpan={5} className="text-center">
                                        {horario !== null ? `${horario.alumno.nombre} ${horario.alumno.apellido_paterno} ${horario.alumno.apellido_materno ? horario.alumno.apellido_materno : ''}` : null}
                                    </th>
                                </tr>
                                <tr>
                                    <th class="text-center" scope="col">Materia</th>
                                    <th class="text-center" scope="col">Sede</th>
                                    <th class="text-center" scope="col">Grupo</th>
                                    <th class="text-center" scope="col">Fecha</th>
                                    <th class="text-center" scope="col">Hora</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!loadingHorario ?
                                    horario !== null ?
                                        horario.modulos.map(modulo => {
                                            return (
                                                <tr>
                                                    <td class="text-left">{modulo.materia}</td>
                                                    <td>{modulo.sede}</td>
                                                    <td>{modulo.grupo}</td>
                                                    <td>{modulo.fecha}</td>
                                                    <td>{modulo.hora}</td>
                                                </tr>    
                                            )
                                        })
                                    : 
                                    <tr>
                                        <td className="text-center" colSpan="5">
                                            No se encontrarón resultados
                                        </td>
                                    </tr>
                                : 
                                    <tr>
                                        <td className="text-center" colSpan="5">
                                            <i className="fa fa-spinner fa-spin"></i> Cargando horario, por favor espere...
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    </div>
                    </div>
                
                </Modal.Body>
                <Modal.Footer>
                <button type="button" className="btn btn-danger btn-sm float-right" onClick={handlePrint}><FontAwesomeIcon icon={faDownload}/> Descargar</button>
                    
                </Modal.Footer>
            </Modal>
        </>
     );
}
 
export default HorarioAlumnoModal;