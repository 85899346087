import clienteAxios from '../../config/axios';
import {  mensajeSuccessToastify, msjError } from '../../components/recursos/mensajes';
import { obtenerDatosMateria } from './Materias.actions';
import {
    SET_ASISTENCIA_BANDERA,
    SET_LOADING_ASISTENCIA,
    SET_DIAS_CALIFICACIONES,
    SET_LOADING_DIAS
} from '../../types';
import store from "../../store";
const token = `${store.getState().Authentication.courrentUser.token_type} ${store.getState().Authentication.courrentUser.access_token}`;

export const obtenerBanderaAsistencia = (materiaId) => {
    return dispatch => {
        clienteAxios.get ('/materia/asistencia/'+materiaId,
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {

            dispatch ( setBanderaAsistencia ( response.data ) );

        }).catch(error => {
            msjError(error);
        });
    }
}

export const obtenerDiasCalificaciones = (materiaId) => {
    return dispatch => {
        dispatch( setLoadingCalificacion(true) );
        clienteAxios.get ('/materia/dias-calificacion/'+materiaId,
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {

            dispatch ( setDiasCalificacion ( response.data ) );

        }).catch(error => {
            msjError(error);
        }).finally(final => {
            dispatch( setLoadingCalificacion(false) );
        });
    }
}


export const registrarAsistenciaMateria = (materiaId, docenteId, alumnoId, tipo) => {
    return dispatch => {
        dispatch( setLoading ( true ) );
        clienteAxios.post('/materia/asistencia/alumno',
        { materiaId, docenteId, alumnoId, tipo },
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {

            mensajeSuccessToastify(response.data.message);
            dispatch( obtenerDatosMateria(materiaId) );
           
        }).catch(error => {
            msjError(error);
        }).finally(final => {
            dispatch( setLoading ( false ) );
        });
    }
}

export const setBanderaAsistencia = (flag) => {
    return {
        type: SET_ASISTENCIA_BANDERA,
        payload: flag
    }
}

export const setLoading = (loading) => {
    return {
        type: SET_LOADING_ASISTENCIA,
        payload: loading
    }
}

export const setDiasCalificacion = (dias) => {
    return {
        type: SET_DIAS_CALIFICACIONES,
        payload: dias
    }
}

export const setLoadingCalificacion = (loading) => {
    return {
        type: SET_LOADING_DIAS,
        payload: loading
    }
}

