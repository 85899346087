import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { Card, Form } from 'react-bootstrap';
import { obtenerModulosAlumno } from '../../redux/actions/Modulos.actions';
import { resetMaterias } from '../../redux/actions/Materias.actions';
import Encabezado from '../recursos/Encabezado';


const CalendarioAlumno = () => {

    const dispatch = useDispatch();
    const [datosForm, setDatosForm] = useState(null);
    const { modulosList } = useSelector(state => state.Modulos);
    const alumnoId = useSelector(state => state.Alumnos.alumno.id);

    useEffect(() => {
        dispatch( obtenerModulosAlumno(alumnoId) ); 
       /*  dispatch( resetMaterias() ); */
    }, []);

    return ( 
        <>
        <title>CCH | Calendario</title>
        <Encabezado/>
        <div className="row animated fadeIn">
           
            <div className="col-lg-8 mb-3">
                <Card body className="shadow">
                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'dayGridMonth,listMonth'
                        }}
                        initialView="dayGridMonth"
                        weekends={true}
                        events={modulosList}
                        locale='es'
                        eventClick={function(info) {
                            setDatosForm(modulosList.filter(modulo => Number(modulo.id) === Number(info.event.id))[0].datos )
                            
                        }}
                        editable={true}
                    />
                </Card>
            </div>
            <div className="col-lg-4 mb-3">
                <Card body className="shadow">
                    <Form>
                        <Form.Group className="mb-3" controlId="programa">
                            <Form.Label>Programa</Form.Label>
                            <Form.Control type="text" placeholder="" value={datosForm !== null ? datosForm.programa : null} isValid={datosForm !== null ? true: false}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="grupo">
                            <Form.Label>Grupo</Form.Label>
                            <Form.Control type="text " placeholder="" value={datosForm !== null ? datosForm.grupo : null} isValid={datosForm !== null ? true: false}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="materia">
                            <Form.Label>Materia</Form.Label>
                            <Form.Control type="text " placeholder="" value={datosForm !== null ? datosForm.materia : null} isValid={datosForm !== null ? true: false}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="Docente">
                            <Form.Label>Docente</Form.Label>
                            <Form.Control type="text " placeholder="" value={datosForm !== null ? datosForm.docente : null} isValid={datosForm !== null ? true: false}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="fecha">
                            <Form.Label>Fecha</Form.Label>
                            <Form.Control type="text " placeholder="" value={datosForm !== null ? datosForm.fecha : null} isValid={datosForm !== null ? true: false}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="hora">
                            <Form.Label>Hora</Form.Label>
                            <Form.Control type="text " placeholder="" value={datosForm !== null ? datosForm.hora : null} isValid={datosForm !== null ? true: false}/>
                        </Form.Group>
                    </Form>
                </Card>
            </div>
        </div>
        
        </>
     );
}
 
export default CalendarioAlumno;