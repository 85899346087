import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const mensajeSuccessToastify = (mensaje) => {
  
  toast.success (mensaje, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    newestOnTop: true
    });

}
export const mensajeWarningToastify = (mensaje) => {
  
  toast.warning (mensaje, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    newestOnTop: true
    });

}

export const mensajeDangerToastify = (mensaje) => {
  
  toast.error (mensaje, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    newestOnTop: true
    });

}

export const mensajeInfoToastify = (mensaje) => {
  
  toast.info (mensaje, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    newestOnTop: true
    });

}

export const mensajeSuperiorIzq = (mensaje, estatus) => {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 6000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });

      Toast.fire({
        icon: estatus,
        title: mensaje,
        width: '25em'
      });


}

export const mensajeCentral = (mensaje, estatus) => {
    Swal.fire({
        title: 'Aviso',
        icon: estatus,
        text: mensaje,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
}

export const msjError = (error) => {
  if (error.response) {//el servidor respondió con un código de estado que cae fuera del rango de 2xx
                  
      mensajeDangerToastify(error.response.data.message);

  } else if (error.request) {//Se realizó la solicitud pero no se recibió respuesta

      mensajeDangerToastify('Se realizó la solicitud pero no se recibió respuesta');
      
  } else {// Algo sucedió al configurar la solicitud y provocó un error
      
      mensajeDangerToastify('Algo sucedió al configurar la solicitud');

  }
}