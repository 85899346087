import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity, faUserClock, faCalendar, faCoffee, faCheckSquare, faTasks, faPercent, faClock, faBookOpen  } from '@fortawesome/free-solid-svg-icons';

const MenuAlumno = ({ date }) => {

    const location = useLocation();
    const { materia } = useSelector(state => state.Materias);
    return ( 
        <>
            {/* <ul className="nav nav-pills nav-sidebar flex-column nav-legacy nav-child-indent" data-widget="treeview" role="menu" data-accordion="false"> */}
                
                <li className="nav-item">
                  <Link to="/alumno/cursos" className={`nav-link ${location.pathname === '/alumno/cursos' ? 'active' : location.pathname === '/alumno/tareas' ? 'active' : location.pathname === '/alumno/tarea/edit' ? 'active' : ''} `}>
                    <FontAwesomeIcon icon={faUniversity} className="nav-icon"/>
                    <p>
                      Cursos
                    </p>           
                  </Link>
                </li>
                <li className="nav-item">
                <Link to="/alumno/horario" className={`nav-link ${location.pathname === '/alumno/horario' ? 'active' : null} `}>
                  <FontAwesomeIcon icon={faUserClock} className="nav-icon"/>
                  <p>
                    Horarios
                  </p>           
                </Link>
                </li>
                <li className="nav-item">
                <Link to="/alumno/calendario" className={`nav-link ${location.pathname === '/alumno/calendario' ? 'active' : null} `}>
                    <FontAwesomeIcon icon={faCalendar} className="nav-icon"/>
                    <p>
                      Calendario
                    </p>           
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link">
                    <FontAwesomeIcon icon={faClock} className="nav-icon"/>
                    <p>
                    {date.toLocaleTimeString('en-US')}
                    </p>
                  </a>
                </li>
                
              {/* </ul> */}
        </>
     );
}
 
export default MenuAlumno;