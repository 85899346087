import {
    SET_SOLICITUDES,
    LOADING_SOLICITUDES,
    ERROR_SOLICITUDES,
    RESET_SOLICITUDES,
    LOADING_SOLICITUD
} from '../../types';

const initialState = {
    solicitudesList: [],
    solicitudes: {},
    errorSolicitud: false,
    errorSolicitudes: false,
    loading: false,
    loadingList: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_SOLICITUDES:
            return {
                ...state,
                solicitudesList: action.payload
            }
        case LOADING_SOLICITUDES:
            return {
                ...state,
                loadingList: action.payload
            }
        case ERROR_SOLICITUDES:
            return {
                ...state,
                errorSolicitudes: action.payload
            }
        case LOADING_SOLICITUD:
            return {
                ...state,
                loading: action.payload
            }
        case RESET_SOLICITUDES: 
            return {
                ...state,
                solicitudesList: [],
                solicitudes: {},
                errorSolicitud: false,
                errorSolicitudes: false,
                loading: false,
                loadingList: false
            }
        default:
            return state;
    }
}