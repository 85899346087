import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faListAlt, faEye, faCoffee, faSave, faMarker, faSquare, faCircle } from '@fortawesome/free-solid-svg-icons';
import { Card } from 'react-bootstrap';

/* actiond */
import { setTarea } from '../../redux/actions/Tareas.action';
import SessionExpireLabel from '../recursos/SessionExpireLabel';
import { RESOURCE_URL } from '../recursos/url';
/*  */

const MateriaTareas = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const tareasList = useSelector(state => state.Tareas.tareasList);
    const materia = useSelector(state => state.Materias.materia);

    const realizaTarea = (tarea) => {
        dispatch( setTarea(tarea) );
        history.push('/alumno/tarea/edit');
    }

    const banderaFechaLimite = (fechaTarea) => {
        const cadena = fechaTarea.split(' ');
        const fechaActual = new Date();
        const fechaLimite = new Date(cadena[0]);

        if (fechaActual <= fechaLimite) {
            return true;
        }

        return false;

    }

    const getBgCard = (tarea) => {

        if ( banderaFechaLimite(tarea.fecha_entrega) ) {
            return (tarea.texto !== null || tarea.archivo !== null) ? tarea.calificacion !== null ? 'card-success' : 'card-warning' : 'card-primary';
        } else {
            if (tarea.texto !== null || tarea.archivo !== null) {
                return tarea.calificacion !== null ? 'card-success' : 'card-warning';
            } else {
                return 'card-danger';
            }
        }
    }

    const getBgFooter = (tarea) => {

        if ( banderaFechaLimite(tarea.fecha_entrega) ) {
            return (tarea.texto !== null || tarea.archivo !== null) ? tarea.calificacion !== null ? 'bg-success' : 'bg-warning' : 'bg-primary';
        } else {
            if (tarea.texto !== null || tarea.archivo !== null) {
                return tarea.calificacion !== null ? 'bg-success' : 'bg-warning';
            } else {
                return 'bg-danger';
            }
        }
    }

    const getEstatus = (tarea) => {

        if ( banderaFechaLimite(tarea.fecha_entrega) ) {
            return (tarea.texto !== null || tarea.archivo !== null) ? tarea.calificacion !== null ? 'Calificada' : 'Tarea realizada' : 'Tarea nueva';
        } else {
            if (tarea.texto !== null || tarea.archivo !== null) {
                return tarea.calificacion !== null ? 'Calificada' : 'Tarea realizada';
            } else {
                return 'No se realizó la tarea';
            }
        }
    }
    
    return ( 
        <>
        <title>CCH | Tareas</title>
        <div className="row animated fadeIn">
        
            <div className="col-lg-12">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row ">
                            <div className="col-lg-12">
                                <div class="callout callout-info">
                                <div className="row">
                                        <div className="col-lg-12 mb-1">
                                            <h5><FontAwesomeIcon icon={faCoffee}/> Tareas </h5>
                                        </div>
                                        <div className="col-lg-12">
                                            <strong>Materia:</strong> {materia && materia.materia_nombre.nombre_materia}
                                        </div>
                                        <div className="col-lg-12">
                                            <strong>Grupo:</strong> {materia && materia.grupo && materia.grupo.nombre}
                                        </div>
                                        <div className="col-lg-12">
                                            <strong>Sede:</strong> {materia && materia.sede && materia.sede.nombre_sede}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="col-lg-12">
                <hr></hr>
            </div>
            <div className="col-lg-12 mb-3"><SessionExpireLabel/></div>
        </div>
        <div className="row animated fadeIn">
            <div className="col-lg-12 mb-3">
                <section className="content">
                    <Card className="card-solid shadow">
                        <Card.Body className="pb-0">
                            <div class="row">
                            {tareasList.length === 0 &&
                            <div className="col-lg-12 text-center mb-3">
                            <h4>No se encontrarón tareas registradas.</h4>
                            </div>}
                            
                            {tareasList.map(tarea => {
                                return (
                                    
                                    <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column animated fadeIn ">
                                        <Card className={`${getBgCard(tarea)} card-outline d-flex flex-fill shadow-sm`}>
                                            <Card.Header className="border-bottom-0">
                                            Tarea {tarea.no_tarea}
                                            </Card.Header>
                                            <Card.Body className="pt-0">
                                                <div class="row">
                                                    <div class="col-12">
                                                    <h2 class="lead"><b> {tarea.titulo}</b></h2>
                                                    </div>
                                                    <div class="col-9">
                                                        
                                                        <ul class="ml-4 mb-0 fa-ul text-muted">
                                                            <li class="small">
                                                            <span class="fa-li"><FontAwesomeIcon icon={faCalendar}/></span> <strong>Fecha de Entrega:</strong><br></br>
                                                            {tarea.fecha_entrega}
                                                            </li>
                                                            <li class="small">
                                                                <span class="fa-li"><FontAwesomeIcon icon={faListAlt}/></span> <strong>Calificación:</strong> {tarea.calificacion !== null ? tarea.calificacion : '0.0'}      
                                                            </li>
                                                            <li class="small">
                                                                <span class="fa-li"><FontAwesomeIcon icon={faCircle}/></span> <strong>Estatus:</strong> {getEstatus(tarea)}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-3 text-center">
                                                        <img src={`${RESOURCE_URL}/img/tareas-1.jpg`} alt="user-avatar" class="img-circle img-fluid"/>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                            <Card.Footer className={getBgFooter(tarea)}>
                                                {banderaFechaLimite(tarea.fecha_entrega) ?
                                                    (tarea.texto !== null || tarea.archivo !== null) ?
                                                        tarea.calificacion !== null ?
                                                        <button class="btn btn-sm btn-success float-right btn-block" onClick={() => realizaTarea(tarea)}>
                                                            <FontAwesomeIcon icon={faEye}/> Ver
                                                        </button>:
                                                        <button class="btn btn-sm btn-warning float-right btn-block" onClick={() => realizaTarea(tarea)}>
                                                            <FontAwesomeIcon icon={faSave}/> Modificar Tarea
                                                        </button>:
                                                        <button class="btn btn-sm btn-primary float-right btn-block" onClick={() => realizaTarea(tarea) }>
                                                            <FontAwesomeIcon icon={faSave}/> Realizar Tarea
                                                        </button>:
                                                        (tarea.texto !== null || tarea.archivo !== null) ?
                                                            tarea.calificacion !== null ? 
                                                                <button class="btn btn-sm btn-success float-right btn-block" onClick={() => realizaTarea(tarea)}>
                                                                    <FontAwesomeIcon icon={faEye}/> Ver
                                                                </button>:
                                                                <button class="btn btn-sm btn-warning float-right btn-block" onClick={() => realizaTarea(tarea)}>
                                                                    <FontAwesomeIcon icon={faEye}/> Ver
                                                                </button>
                                                            : 
                                                            <button class="btn btn-sm btn-danger float-right btn-block" onClick={() => realizaTarea(tarea)}>
                                                                <FontAwesomeIcon icon={faEye}/> Ver
                                                            </button>
                                                
                                                }
                                                
                                            </Card.Footer>
                                        </Card>
                                    </div>
                                );
                            })}
                            </div>
                        </Card.Body>
                    </Card>
                </section>
            </div>
            
        </div>
        </> 
    );
}
 
export default MateriaTareas;