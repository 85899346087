import React from 'react';
import { Card } from 'react-bootstrap';

const ReporteCalificaciones = ({ materia, refReportCalificaciones }) => {
    return ( 
        <>
            <div className="container" ref={refReportCalificaciones}>
                <div className="row">
                    <div className="col-lg-12 mb-3 text-center">
                        <img src="/img/cch.jpg" alt="..." width="150"/> 
                    </div>
                    <div className="col-lg-12 mb-3 text-center">
                        <h3>Centro de Crecimiento Humanista</h3>
                        <h5 className="text-muted">REPORTE CALIFICACIONES FINALES</h5>
                        <h6 className="text-muted">{materia.programa.nombre_programa}</h6>
                        <h6 className="text-muted">{materia.sede.nombre_sede}</h6>
                        <h6 className="text-muted">{materia.grupo.nombre}</h6>
                    </div>
                    <div className="col-lg-12 mb-3">
                        <hr></hr>
                    </div>
                    <div className="col-lg-12" >
                        <table class="table table-bordered">
                            <thead>
                            <tr>
                                <th style={{ width: '40%' }}>Nombre</th>
                                <th className="text-center" style={{ width: '30%' }}>Calificación</th>
                                <th className="text-center" style={{ width: '30%' }}>Observaciones</th>        
                            </tr>
                            </thead>
                            <tbody>
                            
                                {materia.cargas.map(carga => {
                                    return (
                                        <tr>
                                            <td>
                                                {carga.alumno.nombre} {carga.alumno.apellido_paterno} {carga.alumno.apellido_materno}
                                            </td>
                                            <td className="text-center"> 
                                                {carga.calificaciones.length > 0 ?
                                                    carga.calificaciones[0].calificacion 
                                                : '0.0'}
                                            </td>
                                            <td>
                                                {carga.calificaciones.length > 0 ? carga.calificaciones[0].observaciones : 'Sin observaciones'}
                                            </td>
                                        </tr>
                                    )
                                })} 
                            </tbody>
                        </table>  
                    </div>
                </div>
                
            </div>
        </> 
    );
}
 
export default ReporteCalificaciones;