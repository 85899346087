import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserClock } from '@fortawesome/free-solid-svg-icons';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';

/* actions */
import { obtenerHorarioAlumno } from '../../redux/actions/Alumnos.action';
import Encabezado from '../recursos/Encabezado';
/* import { resetMaterias } from '../../redux/actions/Materias.actions'; */
/* end action */

const AlumnoHorario = () => {

    const dispatch = useDispatch();
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Horario',
        pageStyle: () => {
           return  "@page { size: auto; margin:s 20mm }"
        }
    });

    const alumnoId = useSelector(state => state.Alumnos.alumno.id);
    const alumno = useSelector(state => state.Alumnos.alumno);
    const horario = useSelector(state => state.Alumnos.horario);

    const handleChangeMatricula = (matriculaId) => {
        if (matriculaId !== '0') {
            dispatch( obtenerHorarioAlumno(alumnoId, matriculaId) );
        }
    }

    return ( 
        <>
        <title>CCH | Horario</title>
            <Encabezado/>
            <div className="row ">
                 <div className="col-lg-5">
                     <div class="form-group">
                         <label>Programas</label>
                         <select class="form-control form-control-sm" onChange={(e) => handleChangeMatricula(e.target.value)}>
                            <option value='0'>Seleccionar programa</option>
                            {alumno.matriculas.map(matricula => {
                                return (
                                    <option value={matricula.id}>{matricula.matricula} - {matricula.carga_programa ?  matricula.carga_programa.programa ? matricula.carga_programa.programa.nombre_programa : null : null}</option>
                                )
                            })}
                         </select>
                     </div>
                 </div>
             </div>
            <div className="row animated fadeIn">
                <div className="col-lg-12 mb-3">
                    <Card className="card-solid shadow">
                        <Card.Header>
                            <Card.Title>Horarios</Card.Title>
                            <div class="card-tools">
                                <OverlayTrigger overlay={<Tooltip id={(1000).toString()}>Descargar PDF</Tooltip>}>
                                    <button class="btn btn-tool btn-sm" onClick={handlePrint}>
                                        <i class="fas fa-download"></i>
                                    </button>
                                </OverlayTrigger>
                                <button type="button" class="btn btn-tool" data-card-widget="maximize">
                                    <i class="fas fa-expand"></i>
                                </button>
                            </div>
                        </Card.Header>
                        <Card.Body className="table-responsive p-0">
                            <table class="table table-striped table-valign-middle">
                                <thead>
                                <tr>
                                    <th>Materia</th>
                                    <th>Sede</th>
                                    <th>Grupo</th>
                                    <th>Fecha</th>
                                    <th>Hora</th>
                                </tr>
                                </thead>
                                <tbody>
                                {horario !== null ?
                                        horario.modulos.map(modulo => {
                                            return (
                                                <tr>
                                                    <td class="text-left">{modulo.materia}</td>
                                                    <td>{modulo.sede}</td>
                                                    <td>{modulo.grupo}</td>
                                                    <td>{modulo.fecha}</td>
                                                    <td>{modulo.hora}</td>
                                                </tr>    
                                            )
                                        })
                                    : 
                                    <tr>
                                        <td className="text-center" colSpan="5">
                                            No se encontrarón resultados
                                        </td>
                                    </tr>
                                }
                                </tbody>
                            </table>  
                        </Card.Body>
                    </Card> 
                </div>
            </div>
            <div hidden>
                <div className="container"  ref={componentRef}>
                    <div className="row">
                        <div className="col-lg-12 mb-3 text-center">
                            <img src="/img/cch.jpg" alt="..." width="150"/> 
                            
                        </div>
                        <div className="col-lg-12 mb-3 text-center">
                        <h2>Centro de Crecimiento Humanista</h2>
                        <h5 className="text-muted">HORARIO</h5>
                        </div>
                        <div className="col-lg-12 mb-3">
                            <hr></hr>
                        </div>
                        <div className="col-lg-12">
                            <table class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th colSpan={5} className="text-center">
                                            {horario !== null ? `${horario.alumno.nombre} ${horario.alumno.apellido_paterno} ${horario.alumno.apellido_materno ? horario.alumno.apellido_materno : ''}` : null}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th class="text-center" scope="col">Materia</th>
                                        <th class="text-center" scope="col">Sede</th>
                                        <th class="text-center" scope="col">Grupo</th>
                                        <th class="text-center" scope="col">Fecha</th>
                                        <th class="text-center" scope="col">Hora</th>
                                    </tr>
                                </thead>
                                <tbody>
                                        {horario!== null ?
                                            horario.modulos.map((modulo, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td class="text-left">{modulo.materia}</td>
                                                        <td>{modulo.sede}</td>
                                                        <td>{modulo.grupo}</td>
                                                        <td>{modulo.fecha}</td>
                                                        <td>{modulo.hora}</td>
                                                    </tr>    
                                                )
                                            })
                                        : 
                                        <tr>
                                            <td className="text-center" colSpan="5">
                                                No se encontrarón resultados
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
     );
}
 
export default AlumnoHorario;