import React from 'react';
import { Modal } from 'react-bootstrap';

const EditGrupo = props => {

    const { grupo, loading, grupoValidacion, sedesList, programasList, open } = props;
    const { changeInputGrupo, guardarDatosGrupo, openClose } = props;

    return ( 
        <>
        <Modal
                size="lg"
                show={open}
                onHide={() => openClose(false)}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                <Modal.Title>
                <h4><i className="fas fa-users"></i> {grupo.id === '0' ? 'Registrar' : 'Modificar'} datos del grupo</h4>
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        {loading ?
                            <div className="alert alert-primary text-center" style={{ opacity: 0.5 }}>
                                <i className="fas fa-spinner fa-spin"></i> Guardando información, por favor espere...
                            </div>
                        : null}
                        <div className="form-row">
                            <div className="form-group col-lg-6">
                            <label htmlFor="nombre">Nombre del grupo</label>
                                <input 
                                    type="text" 
                                    className={`form-control ${grupoValidacion.nombre ? 'is-invalid' : null}`} 
                                    id="nombre" 
                                    name="nombre"
                                    placeholder="Nombre del grupo"
                                    value={grupo.nombre !== '' ? grupo.nombre : ''}   
                                    onChange={changeInputGrupo} 
                                />
                                <div className="invalid-feedback">
                                    El campo es obligatorio, ingresa la información.
                                </div>
                            </div>
                            <div className="col-lg-6 form-group">
                                <label>Sede</label>
                                <select className={`form-control ${grupoValidacion.sede_id ? 'is-invalid' : null}`} name="sede_id" 
                                    onChange={changeInputGrupo} 
                                    value={grupo.sede_id !== '0' ? grupo.sede_id : '0'}>
                                    <option value={0}>Selecciona una opción</option>
                                    {sedesList.map((sede) => {
                                        return (
                                        <option value={sede.id} key={sede.id}>{sede.nombre_sede}</option>
                                        );
                                    })}
                                </select>
                                <div className="invalid-feedback">
                                    El campo es obligatorio, selecciona una opción.
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-lg-12 form-group">
                                <label>Programa</label>
                                <select disabled={grupo.id !== '0' ? true : false}
                                    className={`form-control custom-select ${grupoValidacion.programa_id ? 'is-invalid' : null}`} 
                                    size={5} name="programa_id" 
                                    onChange={changeInputGrupo} value={grupo.programa_id !== '0' ? grupo.programa_id : '0'}
                                >
                                    <option value={0}>Selecciona una opción</option>
                                    {programasList.map((programa) => {
                                        return (
                                        <option value={programa.id} key={programa.id}>{programa.nombre_programa}</option>
                                        );
                                    })}
                                </select>
                                <div className="invalid-feedback">
                                    El campo es obligatorio, selecciona una opción.
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-lg-6">
                                <label htmlFor="fecha_inicio">Inicio del ciclo escolar</label>
                                <input 
                                    type="number" className={`form-control ${grupoValidacion.fecha_inicio ? 'is-invalid' : null}`} 
                                    id="fecha_inicio" name="fecha_inicio" onChange={changeInputGrupo} 
                                    value={grupo.fecha_inicio}   
                                />
                                <div className="invalid-feedback">
                                    El campo es obligatorio, ingresa la información.
                                </div>
                            </div>
                            <div className="col-lg-6 form-group">
                                <label htmlFor="fecha_fin">Término del ciclo escolar</label>
                                <input 
                                    type="number" 
                                    className={`form-control ${grupoValidacion.fecha_fin ? 'is-invalid' : null}`} 
                                    id="fecha_fin" 
                                    name="fecha_fin" 
                                    onChange={changeInputGrupo} 
                                    value={grupo.fecha_fin}   
                                />
                                <div className="invalid-feedback">
                                    El campo es obligatorio, ingresa la información.
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <div className="col-lg-12">
                        <button type="button" className="btn btn-danger float-left" data-dismiss="modal" onClick={() => openClose(false)}><i className="fa fa-times"></i> Cancelar</button>
                        <button type="button" className="btn btn-success float-right" onClick={ guardarDatosGrupo }><i className="fa fa-save"></i> Guardar</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
     );
}
 
export default EditGrupo;