import clienteAxios from '../../config/axios';
import {  mensajeSuccessToastify, mensajeDangerToastify, msjError } from '../../components/recursos/mensajes';
import {
    LISTA_MATERIAS,
    DATOS_MATERIA,
    LOADING_MATERIAS,
    LOADING_MATERIA,
    RESET_MATERIAS,
    ERROR_MATERIA
} from '../../types';

import store from "../../store";
import { obtenerListaAlumnos } from './Alumnos.action';
const token = `${store.getState().Authentication.courrentUser.token_type} ${store.getState().Authentication.courrentUser.access_token}`;

export function getDatosMateriaPlan (materia_id) {

    return (dispatch) => {
        dispatch( setLoading ( true ) );
        clienteAxios.get ('materia/detalle-plan/'+materia_id,
            {
                headers: {
                    'Authorization' : token
                }
                
            }).then(response => {
                
                if (response.status === 200) {

                    dispatch ( cargarDatosMateriaPlan ( response.data ) );
                    dispatch( setError(false) );

                }
                dispatch( setLoading ( false ) );
                
            }).catch(error => {

                dispatch( setError(true) );

            });

            
    }

}

export const obtenerMateriasDocente = (docenteId) => {
     return dispatch => {
        dispatch( setLoadingList ( true ) );
        clienteAxios.post('/materias/docente',
        { docenteId },
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {

            dispatch ( setMaterias( response.data ) );
           
        }).catch(error => {
            msjError(error);
        }).finally(final => {

            dispatch( setLoadingList ( false ) );

        });
     }
 }

 export const obtenerMateriasAlumno = (alumnoId) => {
    return dispatch => {
       dispatch( setLoadingList ( true ) );
       clienteAxios.post('/materias/alumno',
       { alumnoId },
       {
           headers: {
               'Authorization' : token
           }
       }).then(response => {
           dispatch ( setMaterias( response.data ) );
       }).catch(error => {
           msjError(error);
       }).finally(final => {
           dispatch( setLoadingList ( false ) );
       });
    }
}

export const calificarMateria = (id_carga, calificacion, observaciones) => {
    const materiaId = store.getState().Materias.materia.id;
    return dispatch => {
        clienteAxios.post('/materia/calificar-materia',
        { id_carga, calificacion, observaciones },
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {

            mensajeSuccessToastify(response.data.message);
            dispatch( obtenerDatosMateria(materiaId) );

        }).catch(error => {
            msjError(error);
        }).finally(final => {
            dispatch( setLoading ( false ) );
        });
    }
}

export const modificarCalificacion = (id_carga, calificacion, observaciones) => {
    const materiaId = store.getState().Materias.materia.id;
    return dispatch => {
        clienteAxios.post('/materia/modificar-calificacion',
        { id_carga, calificacion, observaciones },
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {

            mensajeSuccessToastify(response.data.message);
            dispatch( obtenerDatosMateria(materiaId) );

        }).catch(error => {

            msjError(error);

        }).finally(final => {

            dispatch( setLoading ( false ) );
        
        });
    }
}

export const obtenerDatosMateria = (materia_id) => {
    return dispatch => {
        dispatch( setLoading ( true ) );
        clienteAxios.post('/materia/datos-materia-docente',
        { id: materia_id },
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {

            dispatch ( setDatosMateriaDocente( response.data ) );
           
        }).catch(error => {
            if (error.response) {//el servidor respondió con un código de estado que cae fuera del rango de 2xx
                
                mensajeDangerToastify('Error');

            } else if (error.request) {//Se realizó la solicitud pero no se recibió respuesta

                mensajeDangerToastify('Se realizó la solicitud pero no se recibió respuesta');
                
            } else {// Algo sucedió al configurar la solicitud y provocó un error
                
                mensajeDangerToastify('Algo sucedió al configurar la solicitud');

            }
        }).finally(final => {

            dispatch( setLoading ( false ) );

        });
    }
 }

 export const editCalificacionProv = (data) => {
    /* Data : [calificacion, alumno_id, programa_id, semestre_id, materia_id] */
    
    return dispatch => {
        dispatch( setLoading(true) );
        clienteAxios.post('/materias/calificacionprov-edit', data,
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {

            mensajeSuccessToastify(response.data.message);
            dispatch ( obtenerListaAlumnos({}) );
           
        }).catch(error => {
            if (error.response) {//el servidor respondió con un código de estado que cae fuera del rango de 2xx
                
                mensajeDangerToastify('Error');

            } else if (error.request) {//Se realizó la solicitud pero no se recibió respuesta

                mensajeDangerToastify('Se realizó la solicitud pero no se recibió respuesta');
                
            } else {// Algo sucedió al configurar la solicitud y provocó un error
                
                mensajeDangerToastify('Algo sucedió al configurar la solicitud');

            }
        }).finally(final => dispatch( setLoading(false) ) );
    }
 }

 export const getCalificacionesProv = (email) => {
    /* Data : [email] */
    
    return dispatch => {
        dispatch( setLoadingCalificaciones(true) );
        clienteAxios.post('/materias/calificacionesprov-read', { email: email}).then(response => {

           dispatch( setCalificaciones(response.data) );
           
        }).catch(error => {
            if (error.response) {//el servidor respondió con un código de estado que cae fuera del rango de 2xx
                
                mensajeDangerToastify('Error');

            } else if (error.request) {//Se realizó la solicitud pero no se recibió respuesta

                mensajeDangerToastify('Se realizó la solicitud pero no se recibió respuesta');
                
            } else {// Algo sucedió al configurar la solicitud y provocó un error
                
                mensajeDangerToastify('Algo sucedió al configurar la solicitud');

            }
        }).finally(final => dispatch( setLoadingCalificaciones(false) ) );
    }
 }

export const setCalificaciones = (data) => {
    return {
        type: 'SET_CALIFICACIONES',
        payload: data
    }
}

export const setLoadingCalificaciones = (data) => {
    return {
        type: 'SET_LOADING_CALIFICACIONES',
        payload: data
    }
}

export function reset () {
    return (dispatch) => {
        dispatch ( resetMaterias() );
    }
}

export const setLoading = (bandera) => {
    return {
        type: LOADING_MATERIA,
        payload: bandera
    }
}

export const setError = (bandera) => {
    return {
        type: ERROR_MATERIA,
        payload: bandera
    }
}

export const cargarDatosMateriaPlan = (materia) => {
    return {
        type: DATOS_MATERIA,
        payload: materia 
    }
}

export const setMaterias = (materias) => {
    return {
        type: LISTA_MATERIAS,
        payload: materias 
    }
}

export const setDatosMateriaDocente = (datos) => {
    return {
        type: DATOS_MATERIA,
        payload: datos
    }
}

export const setLoadingList = (flag) => {
    return {
        type: LOADING_MATERIAS,
        payload: flag
    }
}

export const resetMaterias = () => {
    return {
        type: RESET_MATERIAS
    }
}

