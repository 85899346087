
import clienteAxios from '../../config/axios';
import {
    CARGAR_SEDES,
    LOADING_SEDES,
    ERROR_SEDES,
    RESET_SEDES
} from '../../types';

import store from "../../store";
const token = `${store.getState().Authentication.courrentUser.token_type} ${store.getState().Authentication.courrentUser.access_token}`;

export function cargarListaSedes (filtro) {
    
    return ( dispatch) => {
        dispatch( loadingSedes (true) );
        clienteAxios.get('sedes/all',
        {
            headers: {
                'Authorization' : token
            }

        }).then(response => {

            let listaSedes = response.data;
            
            dispatch ( getSedes(listaSedes) );
            dispatch( loadingSedes(false) );
            dispatch( errorSedes(false) );

        }).catch(error => {
            
            dispatch( errorSedes(true) );
            dispatch( loadingSedes(false) );

        });
    };

}

export function reset () {
    return (dispatch) => {
        dispatch( resetSedes () );
    }
}

export const getSedes = (listaSedes) => {
    return {
        type: CARGAR_SEDES,
        payload: listaSedes
    }
};

export const resetSedes = () => {
    return {
        type: RESET_SEDES
    }
}

export const loadingSedes = (loading) => {
    return {
        type: LOADING_SEDES,
        payload: loading
    }
}

export const errorSedes = (error) => {
    return {
        type: ERROR_SEDES,
        payload: error
    }
}