import clienteAxios from '../../config/axios';
import store from "../../store";
import { msjError } from '../../components/recursos/mensajes';
import { mensajeSuccessToastify, mensajeWarningToastify } from '../../components/recursos/mensajes';

import {
    SET_TAREAS,
    SET_TAREA,
    LOADING_TAREAS,
    LOADING_TAREA,
    ERROR_TAREAS,
    ERROR_TAREA,
    RESET_TAREAS,
    SET_SUCCESS_TAREA
} from '../../types';

const token = `${store.getState().Authentication.courrentUser.token_type} ${store.getState().Authentication.courrentUser.access_token}`;

export const registrarDatosTarea = ( tarea, materiaId ) => {
    
    return dispatch => {
        dispatch( loadingTarea(true) );   
        dispatch( setSuccessTarea(false) );
        clienteAxios.post('tareas/crear/'+materiaId,
        tarea,
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {
            
            if (response.data.success) {
                mensajeSuccessToastify(response.data.message);
                dispatch( obtenerListaTareasMaterias(materiaId) );
                dispatch( setSuccessTarea(true) );
            } else {
                mensajeWarningToastify(response.data.message);
            }

        }).catch(error => {
            msjError( error );
            dispatch( setSuccessTarea(false) );
        }).finally(final => {
            dispatch ( loadingTarea(false) );  
        }); 
    }

}

export const editarTareaAlumno = ( tarea, materiaId ) => {
    
    return dispatch => {
        dispatch( loadingTarea(true) );   
        dispatch( setSuccessTarea(false) );
        clienteAxios.post('tarea/alumno/update',
        tarea,
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {
            
            if (response.data.success) {
                mensajeSuccessToastify(response.data.message);
                dispatch( obtenerListaTareasMaterias(materiaId) );
                dispatch( setSuccessTarea(true) );
            } else {
                mensajeWarningToastify(response.data.message);
            }

            
        }).catch(error => {
            msjError( error );
            dispatch( setSuccessTarea(false) );
        }).finally(final => {
            dispatch ( loadingTarea(false) );  
        }); 
    }

}

export const editarDatosTarea = ( tarea, materiaId ) => {
    
    return dispatch => {
        dispatch( loadingTarea(true) );   
        dispatch( setSuccessTarea(false) );
        clienteAxios.post('tareas/edit',
        tarea,
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {
            
            if (response.data.success) {
                mensajeSuccessToastify(response.data.message);
                dispatch( obtenerListaTareasMaterias(materiaId) );
                dispatch( setSuccessTarea(true) );
            } else {
                mensajeWarningToastify(response.data.message);
            }

        }).catch(error => {
            msjError( error );
            dispatch( setSuccessTarea(false) );
        }).finally(final => {
            dispatch ( loadingTarea(false) );  
        }); 
    }

}

export const calificarTarea = ( tareaId, calificacion, cargaId ) => {
    
    return dispatch => {
        dispatch( loadingTarea(true) );   
        dispatch( setSuccessTarea(false) );
        clienteAxios.post('tarea/calificar',
        { tareaId: tareaId, calificacion: calificacion },
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {
            
            if (response.data.success) {
                mensajeSuccessToastify(response.data.message);
                dispatch( obtenerListaTareasMateriasAlumno(cargaId) );
                dispatch( setSuccessTarea(true) );
            } else {
                mensajeWarningToastify(response.data.message);
            }

        }).catch(error => {
            msjError( error );
            dispatch( setSuccessTarea(false) );
        }).finally(final => {
            dispatch ( loadingTarea(false) );  
        }); 
    }

}

export const obtenerListaTareasMaterias = (materiaId) => {
    return dispatch => {
        dispatch( loadingListaTareas(true) );  

        clienteAxios.get('tareas/lista/'+materiaId,
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {

            dispatch( setTareas(response.data) );
            
        }).catch(error => {

            msjError( error );  

        }).finally(final => {

            dispatch( loadingListaTareas(false) );

        });
    }
}

export const obtenerListaTareasMateriasAlumno = (cargaId) => {
    return dispatch => {
        dispatch( loadingListaTareas(true) );  

        clienteAxios.get('tareas/alumno/'+cargaId,
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {

            dispatch( setTareas(response.data) );
            
        }).catch(error => {

            msjError( error );  

        }).finally(final => {

            dispatch( loadingListaTareas(false) );

        });
    }
}

export const setTareas = (tareasList) => {
    return {
        type: SET_TAREAS,
        payload: tareasList
    }
};

export const setTarea = (tarea) => {
    return {
        type: SET_TAREA,
        payload: tarea
    }
}

export const loadingTarea = (loading) => {
    return {
        type: LOADING_TAREA,
        payload: loading
    }
};

export const loadingListaTareas = (loadingList) => {
    return {
        type: LOADING_TAREAS,
        payload: loadingList
    }
};

export const setSuccessTarea = (errorFlag) => {
    return {
        type: SET_SUCCESS_TAREA,
        payload: errorFlag
    }
}

export const reset = () => {
    return {
        type: RESET_TAREAS
    }
}

