import React, { useState, useEffect } from 'react';
import { Card, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { faArrowLeft, faBook, faCalendar, faCoffee, faEdit, faListAlt } from '@fortawesome/free-solid-svg-icons';
import MateriaTareaModal from './MateriaTareaModal';
import { useDispatch, useSelector } from 'react-redux';
import { registrarDatosTarea, editarDatosTarea } from '../../redux/actions/Tareas.action';
import SessionExpireLabel from '../recursos/SessionExpireLabel';
import { RESOURCE_URL } from '../recursos/url';

const MateriaTareas = () => {

    const [openEdit, setOpenEdit] = useState(false);
    const [tareaEdit, setTareaEdit] = useState({
        id: 0, 
        titulo: null,
        fecha_entrega: null,
        descripcion: null
    });
    const [validacionEdit, setValidacionEdit] = useState({
        titulo: false,
        fecha_entrega: false,
        descripcion: false
    });

    const dispatch = useDispatch();
    const history = useHistory();
    const materiaId = useSelector(state => state.Materias.materia.id);
    const materia = useSelector(state => state.Materias.materia);
    const tareasList = useSelector(state => state.Tareas.tareasList);
    const successTarea = useSelector(state => state.Tareas.successTarea);
    

    const handleChange = (e) => {
        setTareaEdit({...tareaEdit, [e.target.name]: e.target.value !== "" ? e.target.value : null});
        setValidacionEdit({ ...validacionEdit, [e.target.name]: e.target.value !== "" ? false : true });
    };

    const guardarTarea = () => {

        setValidacionEdit({ 
            ...validacionEdit, 
            titulo: tareaEdit.titulo === null ? true : false,
            descripcion: tareaEdit.descripcion === null ? true : false,
            fecha_entrega: tareaEdit.fecha_entrega === null ? true : false
        }); 

        if(tareaEdit.titulo !== null && tareaEdit.descripcion !== null && tareaEdit.fecha_entrega !== null) {
            if (tareaEdit.id === 0) {
                dispatch( registrarDatosTarea(tareaEdit, materiaId) );
            } else {
                dispatch( editarDatosTarea(tareaEdit, materiaId) );
            }
            
        }

    }

    const openEditTarea = (tarea, open) => {
        const fecha = tarea.fecha_entrega.split(' ');
        setOpenEdit(open);
        setTareaEdit({
            ...tareaEdit,
            id: tarea.id, 
            titulo: tarea.titulo,
            fecha_entrega: fecha[0]+'T'+fecha[1],
            descripcion: tarea.descripcion
        });
    }


    useEffect(() => {
        
        if (!openEdit) {
            setValidacionEdit({ 
                ...validacionEdit, 
                titulo: false,
                descripcion: false,
                fecha_entrega: false
            }); 
            setTareaEdit({ 
                ...validacionEdit,
                id: 0, 
                titulo: null,
                descripcion: null,
                fecha_entrega: null
            });
        }
    }, [openEdit]);

    useEffect(() => {
        if(successTarea){
            setOpenEdit(false);
        }
    }, [successTarea]);

    return ( 
        <>
        <title>CCH | Tareas</title>
        <div className="row animated fadeIn">
        
            <div className="col-lg-12">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row ">
                            <div className="col-lg-12">
                                <div class="callout callout-info">
                                <div className="row">
                                        <div className="col-lg-12 mb-1">
                                            <h5><FontAwesomeIcon icon={faCoffee}/> Tareas </h5>
                                        </div>
                                        <div className="col-lg-12">
                                            <strong>Materia:</strong> {materia && materia.materia_nombre.nombre_materia}
                                        </div>
                                        <div className="col-lg-12">
                                            <strong>Grupo:</strong> {materia && materia.grupo.nombre}
                                        </div>
                                        <div className="col-lg-12">
                                            <strong>Sede:</strong> {materia && materia.sede.nombre_sede}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="col-lg-12">
                <hr></hr>
            </div>
            <div className="col-lg-12 mb-3"><SessionExpireLabel/></div>
            <div className="col-lg-12 mb-3">
                    <button className="btn btn-link" onClick={() => history.push('/cursos/materia')}>
                        <FontAwesomeIcon icon={faArrowLeft}/> Volver
                    </button>
                    <OverlayTrigger overlay={<Tooltip id={(1000).toString()}>Nueva Tarea</Tooltip>}>
                        <Button variant="primary" className="float-right" onClick={() => setOpenEdit(true)} style={{ borderRadius: '50%' }}><i className="fas fa-plus" ></i></Button>
                    </OverlayTrigger>
            </div>
        </div>
        <div className="row animated fadeIn">
            <div className="col-lg-12 mb-3">
                <section className="content">
                    <Card className="card-solid shadow">
                        <Card.Body className="pb-0">
                            <div class="row">
                            {tareasList.length === 0 &&
                            <div className="col-lg-12 text-center mb-3">
                            <h4>No se encontrarón tareas registradas.</h4>
                            </div>}
                            {tareasList.map(tarea => {
                                return (
                                    <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column animated fadeIn">
                                        <Card className="card-primary card-outline">{/* d-flex flex-fill */}
                                            <Card.Header className="text-muted border-bottom-0">
                                                Tarea {tarea.no_tarea}
                                            </Card.Header>
                                            <Card.Body className="pt-0">
                                                <div class="row">
                                                    <div class="col-8">
                                                        <h2 class="lead"><b><FontAwesomeIcon icon={faBook}/> {tarea.titulo}</b></h2>
                                                        <ul class="ml-4 mb-0 fa-ul text-muted">
                                                            <li class="small">
                                                            <span class="fa-li"><FontAwesomeIcon icon={faCalendar}/></span> <strong>Fecha de Entrega:</strong><br></br> {tarea.fecha_entrega}
                                                            </li>
                                                            <li class="small">
                                                                <span class="fa-li"><FontAwesomeIcon icon={faListAlt}/></span> <strong>Descripción:</strong>
                                                                {tarea.descripcion.length < 99 && <br></br>}
                                                                {tarea.descripcion.length < 99 && tarea.descripcion}
                                                                {tarea.descripcion.length > 99 && 
                                                                <a href="#" data-toggle="collapse" data-target={'#tarea'+tarea.no_tarea} aria-expanded="true" aria-controls="collapseOne">
                                                                    <strong> ...Ver más</strong>
                                                                </a>} 
                                                                
                                                                <div id="accordion">
                                                                    <div class="">

                                                                        <div id={'tarea'+tarea.no_tarea} class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                                        <div class="card-body">
                                                                            {tarea.descripcion}
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                        
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-4 text-center">
                                                        <img src={`${RESOURCE_URL}/img/tareas-1.jpg`} alt="user-avatar" class="img-circle img-fluid"/>
                                                    </div>
                                                    <div className="col-12">
                                                        <hr></hr>
                                                    </div>
                                                    <div className="col-12 text-right">
                                                        <button class="btn btn-sm btn-warning" onClick={() => openEditTarea(tarea, true) }>
                                                        <FontAwesomeIcon icon={faEdit}/> Modificar
                                                        </button>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                );
                            })}
                            </div>
                        </Card.Body>
                    </Card>
                </section>
            </div>
            
        </div>
        <MateriaTareaModal
            openEdit={openEdit}
            tareaEdit={tareaEdit}
            setOpenEdit={setOpenEdit}
            setTareaEdit={setTareaEdit}
            handleChange={handleChange}
            validacionEdit={validacionEdit}
            guardarTarea={guardarTarea}
        />
        </> 
    );
}
 
export default MateriaTareas;