import React from 'react';
import { useSelector } from 'react-redux';

const SessionExpireLabel = () => {
    const auth = useSelector(state => state.Authentication.courrentUser);
    return ( 
        <>
        <span className="badge badge-pill badge-info">
        <i className="far fa-clock"></i> La sesión expira a las {new Date(auth.dateExpire).toLocaleTimeString('en-US')}
        </span>
        </>
     );
}
 
export default SessionExpireLabel;