
import clienteAxios from '../../config/axios';
import {
    CARGAR_PROGRAMAS,
    LOADING_PROGRAMAS,
    ERROR_PROGRAMAS,
    RESET_PROGRAMAS
} from '../../types';

import store from "../../store";
const token = `${store.getState().Authentication.courrentUser.token_type} ${store.getState().Authentication.courrentUser.access_token}`;

export function cargarListaProgramas (filtro) {
    
    return ( dispatch) => {
        dispatch( loadingProgramas (true) );
        clienteAxios.get('programas/all',
        {
            headers: {
                'Authorization' : token
            }
            
        }).then(response => {

            let listaProgramas = response.data;
            
            dispatch ( getProgramas(listaProgramas) );
            dispatch( loadingProgramas(false) );
            dispatch( errorProgramas(false) );

        }).catch(error => {
            
            dispatch( errorProgramas(true) );
            dispatch( loadingProgramas(false) );

        });
    };

}

export const cargarListaProgramasDetalle = () => {
    return dispatch => {
        dispatch( loadingProgramas (true) );
        clienteAxios.get('programas/detalle',
        {
            headers: {
                'Authorization' : token
            }
            
        }).then(response => {

            let listaProgramas = response.data;
            
            dispatch ( getProgramas(listaProgramas) );
            dispatch( loadingProgramas(false) );
            dispatch( errorProgramas(false) );

        }).catch(error => {
            
            dispatch( errorProgramas(true) );
            dispatch( loadingProgramas(false) );

        });  
    }
}

export function reset () {
    return (dispatch) => {
        dispatch( resetProgramas () );
    }
}

export const getProgramas = (listaSedes) => {
    return {
        type: CARGAR_PROGRAMAS,
        payload: listaSedes
    }
};

export const resetProgramas = () => {
    return {
        type: RESET_PROGRAMAS
    }
}

export const loadingProgramas = (loading) => {
    return {
        type: LOADING_PROGRAMAS,
        payload: loading
    }
}

export const errorProgramas = (error) => {
    return {
        type: ERROR_PROGRAMAS,
        payload: error
    }
}