import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { resetAuth } from '../../redux/actions/Login.actions';
import { useHistory, Link, useLocation } from 'react-router-dom';
import MenuAdministrador from './MenuAdministrador';
import MenuDocente from './MenuDocente';
import MenuAlumno from './MenuAlumno';
import { RESOURCE_URL } from '../recursos/url';


const Menu = (props) => {

    const dispatch = useDispatch();
    const [date, setDate] = useState(new Date());
    const isSignedIn = useSelector((state) => state.Authentication.courrentUser.isSignedIn);
    const ExpireIn = useSelector((state) => state.Authentication.courrentUser.dateExpire);
    const usuario = useSelector((state) => state.Authentication.usuario);
    const history = useHistory();
    const location = useLocation();
    
    useEffect(() => {
      let timerID = setInterval( () => tick(), 1000 );
    
      return function cleanup() {
          clearInterval(timerID);
        };
    });
    
    function tick() {
      setDate(new Date());
      if (Date.now() > ExpireIn && isSignedIn && ExpireIn !== 0) {
        dispatch ( resetAuth() );
        history.push('/');
      }
    }

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4 " style={{ background: '#53295E' }}>
      
          <a href="index3.html" className="brand-link" /* style={{ background: '#6f85be' }} */>
            <div className="row">
              <div className="col-lg-12 text-center">
              <img src="/img/cch.jpg" alt="..." className="img-circle elevation-3 mx-auto"
                 style={{opacity: .8, lineHeight: '8', marginLeft: '8rem', marginRight: '5rem', marginTop: '-3px', maxHeight: '33px',maxWidth: 'auto'}}/>
              </div>
              <div className="col-lg-12 text-center">
              <span className="brand-text font-weight-light text-sm">Centro de Crecimiento Humanista</span>
              </div>
            </div>
            
          </a>
      
          {/* <!-- Sidebar --> */}
          <div className="sidebar">
           {/*  <!-- Sidebar user panel (optional) --> */}
           {isSignedIn &&
            <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            
              
              <div className="image" hidden={!isSignedIn}>
              {Number(usuario.role_id) === 2 ?
                usuario.docente.foto !== null ? 
                  <img src={`${RESOURCE_URL}/img/doc_docente/${usuario.id}/${usuario.docente.foto}`}
                  alt="..." className="img-circle elevation-2"/>
                : 
                  <img src={`${RESOURCE_URL}/img/perfil.png`} alt="..." className="img-circle elevation-3"/>
              : null}
              {Number(usuario.role_id) === 3 ?
                usuario.alumno.foto !== null ? 
                  <img src={`${RESOURCE_URL}/img/doc_alumno/${usuario.id}/${usuario.alumno.foto}`}
                  alt="..." className="img-circle elevation-2"/>
                : 
                  <img src={`${RESOURCE_URL}/img/perfil.png`} alt="..." className="img-circle elevation-3"/>
              : null}
              {Number(usuario.role_id) === 1 ?
                  <img src={`${RESOURCE_URL}/img/perfil.png`} alt="..." className="img-circle elevation-3"/>
              : null}
              </div>
              {isSignedIn ?
                <div className="info" >
                  <a className="d-block">{usuario.name} </a>
                </div>
              : null}

            </div>
          }
           
            {/* <!-- Sidebar Menu --> */}
            <nav className="mt-2 ">
              <ul className="nav nav-pills nav-sidebar flex-column nav-legacy nav-child-indent" data-widget="treeview" role="menu" data-accordion="false">
              {isSignedIn ?
                Number(usuario.role_id) === 1 ?  <MenuAdministrador date={date}/>  : null
              : null }
              {isSignedIn ?
                Number(usuario.role_id) === 2 ?  <MenuDocente date={date}/>  : null
              : null }
              {isSignedIn ?
                Number(usuario.role_id === 3) ?  <MenuAlumno date={date}/>  : null
              : null }
              </ul>
              {!isSignedIn ?
                <ul className="nav nav-pills nav-sidebar flex-column nav-legacy nav-child-indent" data-widget="treeview" role="menu" data-accordion="false">
                  <li className="nav-item">
                    <Link to="/" className={`nav-link ${location.pathname === '/' ? 'active' : null}`}>
                        <i className="nav-icon fa fa-university"></i>
                        <p>
                          Iniciar Sesión
                        </p>           
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/consulta-calificaciones" className={`nav-link ${location.pathname === '/consulta-calificaciones' ? 'active' : null}`}>
                        <i className="nav-icon fa fa-percent"></i>
                        <p>
                          Mis calificaciónes
                        </p>           
                    </Link>
                  </li>
                </ul>
              : null}
            
            </nav>
            {/* <!-- /.sidebar-menu --> */}
          </div>
          {/* <!-- /.sidebar --> */}
        </aside>
    );
};

export default Menu;