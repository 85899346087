import {
    LISTA_GRUPOS,
    DATOS_GRUPO,
    LOADING_GRUPOS,
    LOADING_GRUPO,
    ERROR_GRUPOS,
    ERROR_GRUPO,
    RESET_GRUPOS,
    REGISTRO_EXITO,
    REGISTRO_FALLIDO
} from '../../types';

const initialState = {
    gruposList: [],
    grupo: null,
    errorListGrupos: false,
    loadingList: false,    
    errorGrupo: false,
    loading: false,
    registroSuccess: false,
    registroFallido: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LISTA_GRUPOS:
            return {
                ...state,
                gruposList: action.payload
            }
        case RESET_GRUPOS: 
            return {
                ...state,
                gruposList: [],
                grupo: null,
                errorListGrupos: false,
                loadingList: false,    
                errorGrupo: false,
                loading: false,
                registroSuccess: false,
                registroFallido: false
            }
        case LOADING_GRUPOS:
            return {
                ...state,
                loadingList: action.payload
            }
        case ERROR_GRUPOS:
            return {
                ...state,
                errorListGrupos: action.payload
            }
        case ERROR_GRUPO:
            return {
                ...state,
                errorGrupo: action.payload
            }
        case LOADING_GRUPO: 
            return  {
                ...state,
                loading: action.payload
            }
        case REGISTRO_EXITO:
            return {
                ...state,
                registroSuccess: action.payload
            }
        case REGISTRO_FALLIDO: 
            return {
                ...state, 
                registroFallido: action.payload
            }
        case DATOS_GRUPO: 
            return {
                ...state,
                grupo: action.payload
            }
        default:
            return state;
    }
}