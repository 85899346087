import React, { useState, useEffect } from 'react';
import { getDatosPlanAsignacion, resetPlaneacion } from '../../redux/actions/Planeacion.actions';
import { asignarModulosDocente, eliminarModulosDocente } from '../../redux/actions/Modulos.actions';
import { obtenerListaDocentes } from '../../redux/actions/Docentes.actions';
import { useSelector, useDispatch} from 'react-redux';
import { OverlayTrigger, Tooltip, Modal }  from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { useHistory } from 'react-router-dom';
import Encabezado from '../recursos/Encabezado';

const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
    } ) => (
    <div className="btn-group" role="group">
    {
        options.map((option) => {

        const isSelect = currSizePerPage === `${option.page}`;
        return (
            <button
                key={ option.text }
                type="button"
                onClick={ () => onSizePerPageChange(option.page) }
                className={ `btn ${isSelect ? 'btn-secondary' : 'btn-primary'}` }
            >
            { option.text }
            </button>
        );
        })
    }
    </div>
);

const options = {
    sizePerPageRenderer
}

const { SearchBar } = Search;

const AsignarHorarios = () => {
    
    const [open, setOpen] = useState(false);
    const { planeacionGrupo, parametros, loading } = useSelector(state => state.Planeacion);
    const { docentesList } = useSelector(state => state.Docentes);
    const [planeacionIdSelect, setPlaneacionIdSelect] = useState(null);
    const [docenteIdSelect, setDocenteIdSelect] = useState(null);
    const [planeacion, setPlaneacion] = useState(null);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch( getDatosPlanAsignacion(parametros.grupo_id, parametros.programa_id) );
        dispatch( obtenerListaDocentes({}) );
    }, []);

/*     useEffect(() => {
        return () => {
            dispatch( resetPlaneacion() );
        }
    }, []); */

    useEffect(() => {
        setPlaneacion(planeacionGrupo);
    }, [planeacionGrupo])

    const openModalDocentes = (planeacion_id, docente_id, open) => {
        setOpen(open);
        setPlaneacionIdSelect(planeacion_id);
        setDocenteIdSelect(docente_id);
    };

    const asignar = (docente_id) => {
        dispatch( asignarModulosDocente(planeacionIdSelect, docente_id) );
        setOpen(false);
    };

    const eliminar = () => {
        dispatch( eliminarModulosDocente(planeacionIdSelect, docenteIdSelect) );
        setOpen(false);
        setDocenteIdSelect(null);
    };

    const columns = [  {
        dataField: 'nombre',
        text: 'Nombre',
        align: 'left',
        headerAlign: 'center',
        style: {
            width: '10%',
        }
      }, {
        dataField: 'apellido_paterno',
        text: 'Apellido paterno',
        align: 'left',
        headerAlign: 'center',
        style: {
            width: '10%',
        }
      },{
        dataField: 'apellido_materno',
        text: 'Apellido materno',
        align: 'left',
        headerAlign: 'center',
        style: {
            width: '10%',
        }
      }, {
        dataField: 'email',
        text: 'Correo',
        align: 'left',
        headerAlign: 'center',
        style: {
            width: '20%'
        }
      }, {
        dataField: '3',
        text: 'Acciones',
        align: 'center', 
        headerAlign: 'center',
        style: {
            width: '15%'
        },
        formatter: (cell, row) => {
            return (
                <div className="btn-group">
                    <OverlayTrigger overlay={<Tooltip id={row.id}>Asignar horario</Tooltip>}>
                        <button className={`btn btn-success btn-sm`}
                            onClick={() => asignar(row.id) }
                            disabled={(docenteIdSelect !== null) ? true: false}
                        >
                            <i class="fa fa-calendar-check"></i>
                        </button>
                        
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip id={row.id}>Eliminar asignación</Tooltip>}>
                        <button className="btn btn-danger btn-sm"
                            onClick={() => eliminar() }
                            disabled={(docenteIdSelect !== null && docenteIdSelect !== row.id) ? true : docenteIdSelect === null ? true : false}
                        >
                            <i class="fa fa-calendar-times"></i>
                        </button>
                    </OverlayTrigger>
                </div>
            );
        }
      }];

    return ( 
        <>
        <title>CCH | Horarios</title>
        <Encabezado/>
        <div className="row animated fadeIn">
            
            <div className="col-lg-12 ">
                <div className="card shadow bg-white rounded">
                    <div className="card-header text-center">
                        <h5>{planeacion !== null ? planeacion[0].programa ? planeacion[0].programa.nombre_programa : null : null}</h5>
                        <h6>{planeacion !== null ? planeacion[0].grupo ? planeacion[0].grupo.nombre : null : null}</h6>
                    </div> 
                    <div className="card-body">
                       <div className="row">
                            <div className="col-lg-12">
                            {planeacion !== null ? 
                                planeacion.map(plan => {
                                    return (
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr className="text-center">
                                                <th scope="col" colSpan={3} className="table-dark">{plan.semestre}</th>
                                                </tr>
                                                <tr className="text-center table-active">
                                                <th scope="col" style={{ width: '50%' }}>MATERIA</th>
                                                <th scope="col" style={{ width: '30%' }}>DOCENTE</th>
                                                <th scope="col" style={{ width: '2|0%' }}>No. DE CLASES
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {plan.materias ?
                                                    plan.materias.map(materia => {
                                                    return (
                                                    <tr>
                                                        <th scope="row">{materia.nombre}</th>
                                                        <td className="text-center ">
                                                            {materia.docente === null ? 
                                                            <button className="btn btn-link btn-block btn-sm" 
                                                                onClick={() => openModalDocentes(materia.planeacion_id, null, true)}
                                                                disabled={plan.banderaEdit ?
                                                                        materia.noModulos !== 0 ? (materia.noModulos === materia.noModulosCreados) ? false : true : true
                                                                    : true}
                                                            >
                                                                Asignar Docente 
                                                            </button>: 
                                                            <OverlayTrigger overlay={<Tooltip id={materia.id}>Reasignar docente</Tooltip>}>
                                                                <button className="btn btn-link text-dark" onClick={() => openModalDocentes(materia.planeacion_id, materia.docente.id, true)}>
                                                                    {materia.docente &&
                                                                    `${materia.docente.nombre} ${materia.docente.apellido_paterno} ${materia.docente.apellido_materno}`
                                                                    } <i className="fa fa-edit"></i> 
                                                                </button>
                                                            </OverlayTrigger>
                                                            
                                                            }
                                                            
                                                        </td>
                                                        <td className="text-center">
                                                            <strong>{materia.noModulosCreados}/{materia.noModulos}</strong>
                                                        </td>
                                                    </tr>
                                                    )
                                                }): null}
                                                
                                            </tbody>
                                        </table>            
                                    )
                                })
                                
                            :
                            <table class="table table-bordered text-center">
                                <thead>
                                    {loading ?
                                     <tr className="text-center">
                                        <th scope="col" colSpan={3}>
                                            <h5> <i className="fa fa-spinner fa-spin"></i> Cargando información, por favor espere...</h5>
                                        </th>
                                     </tr>
                                    :
                                    <tr className="text-center">
                                        <th scope="col" colSpan={3}>
                                            <h5>No se encontraron resultados de la planeación del programa.</h5>
                                        </th>
                                    </tr>
                                    }
                                    
                                </thead>
                            </table>}
                            </div>
                       </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <Modal
        show={open}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        backdrop="static"
        dialogClassName="modal-90w"
        onHide={() => openModalDocentes(null, null, false)}
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            DOCENTES
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row">
                <div className="col-lg-12">
                    <ToolkitProvider
                        keyField="id"
                        data={ docentesList }
                        columns={ columns }
                        search
                    >
                    {
                        props => (
                        <div>
                            <h5>Buscar:</h5>
                            <SearchBar { ...props.searchProps } placeholder="Buscar"/>
                            <hr />
                            <BootstrapTable
                            { ...props.baseProps }
                            classes="table table-striped" 
                            pagination={ paginationFactory(options) }
                            noDataIndication="No se encontrarón resultados."
                            defaultSorted={ [{
                                dataField: 'id',
                                order: 'desc'
                              }]} 
                            />
                        </div>
                        )
                    }
                    </ToolkitProvider>
                        {/* <BootstrapTable 
                            classes="table table-striped" 
                            keyField='id' 
                            defaultSorted={ [{
                                dataField: 'id',
                                order: 'desc'
                              }]} 
                            data={ docentesList } 
                            columns={ columns } 
                            pagination={ paginationFactory(options) }
                            noDataIndication="No se encontrarón resultados."
                        /> */}
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            {/* <Button >Close</Button> */}
        </Modal.Footer>
        </Modal>
        </>
     );
};
 
export default AsignarHorarios;