import {
    CARGAR_MODULOS,
    LOADING_MODULOS,
    ERROR_MODULO,
    CARGAR_MODULO,
    LOADING_MODULO,
    ERROR_MODULOS,
    RESET_MODULOS,
    REGISTRO_EXITO,
    REGISTRO_FALLIDO_MODULO
} from '../../types';

const initialState = {
    modulosList: null,
    modulo: null,
    loading: false,
    loadingList: false,
    error: false,
    errorList: false,
    registroSuccess: false,
    registroFallido: false,
    msj: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CARGAR_MODULOS: 
            return {
                ...state,
                modulosList: action.payload
            }
        case CARGAR_MODULO: 
            return {
                ...state,
                modulo: action.payload
            }
        case LOADING_MODULOS:
            return {
                ...state,
                loadingModulos: action.payload
            }
        case LOADING_MODULO:
            return {
                ...state,
                loading: action.payload
            }
        case ERROR_MODULOS:
            return {
                ...state, 
                errorList: action.payload
            }
        case ERROR_MODULO:
            return {
                ...state,
                error: action.payload
            }
        case RESET_MODULOS: 
            return {
                ...state,
                modulosList: null,
                modulo: null,
                loading: false,
                loadingList: false,
                error: false,
                errorList: false,
                registroSuccess: false,
                registroFallido: false,
                msj: null
            }
        case REGISTRO_EXITO:
            return {
                ...state,
                registroSuccess: action.payload
            }
        case REGISTRO_FALLIDO_MODULO: 
            return {
                ...state, 
                registroFallido: action.payload.success,
                msj: action.payload.msj
            }
        default:
            return state;
    }
}