import React from 'react';
import { Route, Redirect  } from 'react-router-dom';

 const PrivateAlumno = ({ path, auth, component, name, role }) => {
     
    if (auth && Number(role) === 3) {
        return <Route name={name} path={path} component={component} exact/>
    }

    return <Redirect to="/"/>

 }
  
 export default PrivateAlumno;