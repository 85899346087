import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";

import { faDownload, faGraduationCap, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useReactToPrint } from 'react-to-print';

const ListaAlumnosGrupo = ({ open, obtenerListaAlumnos, grupo }) => {
    
    const alumnosList = useSelector(state => state.Alumnos.alumnosList);
    const loadingList = useSelector(state => state.Alumnos.loadingList);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Lista de Alumnos',
        pageStyle: () => {
           return  "@page { size: auto; margin:s 20mm }"
        }
    });
    
    return (
        <>
        <Modal
        size="lg"
        onHide={() => obtenerListaAlumnos(null, false)}
        show={open}
        backdrop="static"
        keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                   <FontAwesomeIcon icon={faGraduationCap}/> Alumnos
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <table class="table table-striped">
                    <thead>
                        <tr>
                        <th>Nombre</th>
                        <th className="text-center">Calificación</th>
                        </tr>
                    </thead>
                    <tbody>
                        {alumnosList.length > 0 ?
                        alumnosList.map(alumno => {
                            return (
                                <tr>
                                    <td>{alumno.nombre} {alumno.apellido_paterno} {alumno.apellido_materno}</td>
                                    <td className="text-center">{alumno.calificacion}</td>
                                </tr>
                            )
                        }):
                            <tr hidden={loadingList}>
                                <td className="text-center" colSpan="2">
                                    No se encontrarón resultados
                                </td>
                            </tr>
                        }
                        {loadingList &&
                            <tr>
                                <td className="text-center" colSpan="2">
                                    <FontAwesomeIcon icon={faSpinner} spin/> Cargando información, por favor espere...
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                

                <div hidden>
                <div className="container"  ref={componentRef}>
                    <div className="row">
                        <div className="col-lg-12 mb-3 text-center">
                            <img src="/img/cch.jpg" alt="..." width="150"/> 
                            
                        </div>
                        <div className="col-lg-12 mb-3 text-center">
                        <h2>Centro de Crecimiento Humanista</h2>
                        <h5 className="text-muted">{grupo && grupo.nombre}</h5>
                        </div>
                        <div className="col-lg-12 mb-3">
                            <hr></hr>
                        </div>
                        <div className="col-lg-12">
                            <table class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th class="text-center" scope="col">Nombre</th>
                                        <th class="text-center" scope="col">Calificación</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {alumnosList.map(alumno => {
                                    return (
                                        <tr>
                                            <td>{alumno.nombre} {alumno.apellido_paterno} {alumno.apellido_materno}</td>
                                            <td className="text-center">{alumno.calificacion}</td>
                                        </tr>
                                    )
                                }) }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" size='sm' onClick={handlePrint}> <FontAwesomeIcon icon={faDownload}/> Descargar PDF</Button>
            </Modal.Footer>
        </Modal>
        </>
      );
}
 
export default ListaAlumnosGrupo;