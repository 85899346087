import { createStore, applyMiddleware, compose } from 'redux';
import { loadState, saveState } from './saveStore';
import thunk from 'redux-thunk';
import reducer from './redux/reducers';

const initialState = loadState();

const middleware = applyMiddleware(thunk);


const store = createStore(
    reducer, 
    initialState,
    compose( middleware)
    
);

store.subscribe( function () {
        saveState(store.getState());
})

export default store;