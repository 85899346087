
import {
    AUTENTICACION_LOGIN,
    AUTENTICACION_EXITO,
    AUTENTICACION_ERROR,
    DATOS_USUARIO,
    RESET_AUTH,
    RESET_USUARIO
} from '../../types';

//CADA REDUCER TIENE SU PROPIO STATE

const initialState = {
    courrentUser : {
        isSignedIn: false,
        token_type: '',
        expires_in: 0,
        dateExpire: 0,
        access_token: '',
        refresh_token: ''
    },
    isLoading: false,
    error: false,
    usuario: {
        id: 0,
        name: '',
        email: '',
        role_id: 0
    }
};

export default function (state = initialState, action) {
    
    switch (action.type) {
        case AUTENTICACION_EXITO:
            return {
                ...state,
                isLoading: false,
                error: false,
                courrentUser: action.payload
            };
        case AUTENTICACION_LOGIN: 
            return {
                ...state,
                isLoading: action.payload
            };
        case DATOS_USUARIO:
            return {
                ...state,
                usuario: action.payload
            };
        case RESET_AUTH: 
            return {
                ...state,
                courrentUser : {
                    isSignedIn: false,
                    token_type: '',
                    expires_in: 0,
                    dateExpire: 0,
                    access_token: '',
                    refresh_token: ''
                },
                isLoading: false,
                error: false,
            };
        case AUTENTICACION_ERROR: 
            return {
                ...state,
                error: action.payload
            };
        case RESET_USUARIO:
            return {
                ...state,
                usuario: {
                    id: 0,
                    name: '',
                    email: '',
                    role_id: 0
                }
            }
        default:
            return state;
    }

}
