import React, { Component, useState } from 'react';
import { Card, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { RESOURCE_URL } from '../recursos/url';

const DocentesConsulta = (props) => {
    return (
        <Modal
            size="lg"
            show={props.open}
            onHide={() => props.openClose(false, null)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            scrollable
            backdrop="static"
            keyboard={false}
            centered
        >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <h4><i className="fas fa-user"></i> Datos del docente</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        
                        <div className="row justify-content-center">
                            
                            <div className="col-lg-12">
                                <div class="card card-widget widget-user">
                                    <div class="widget-user-header bg-dark">
                                        <h3 class="widget-user-username">{props.docente.nombre}</h3>
                                        <h5 class="widget-user-desc">{props.docente.apellidoP} {props.docente.apellidoM}</h5>
                                    </div>
                                    <div class="widget-user-image">
                                        {props.docente.imgPerfil !== null ? 
                                        <img 
                                            class="img-circle elevation-2"
                                            src={`${RESOURCE_URL}/img/doc_docente/${props.docente.user ? props.docente.user.id : null}/${props.docente.imgPerfil}`}
                                            alt="User Avatar"
                                        />:
                                        <img 
                                            class="img-circle elevation-2"
                                            src={`${RESOURCE_URL}/img/perfil.png`}
                                            alt="User Avatar"
                                        />}
                                    </div>
                                    <div class="card-footer">
                                        <div class="row">
                                            <div class="col-sm-4 border-right">
                                                <div class="description-block">
                                                <h5 class="description-header"><i class="fa fa-envelope"></i>  Email</h5>
                                                <span>{props.docente.correo}</span>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 border-right">
                                                <div class="description-block">
                                                <h5 class="description-header"><i class="fa fa-phone"></i> Teléfono</h5>
                                                <span>{props.docente.telefono}</span>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="description-block">
                                                <h5 class="description-header"><i class="fa fa-user-circle"></i> Usuario</h5>
                                                <span>{props.docente.user ? props.docente.user.name : null}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <Card className="card-dark">
                                    <Card.Header>
                                        <Card.Title>
                                            <h3 className="card-title">Documentos</h3>
                                        </Card.Title>
                                        <div class="card-tools">
                                            <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                            <i class="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </Card.Header>
                                    <Card.Body className="p-0">
                                        <table class="table">
                                            <thead>
                                            <tr>
                                                <th>Documento</th>
                                                <th>Nombre</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {props.docente.fileCedula !== null &&
                                            <tr>
                                                <td>Cédula</td>
                                                <td>
                                                    {props.docente.fileCedula}
                                                </td>
                                                <td class="text-right py-0 align-middle">
                                                    <div class="btn-group btn-group-sm">
                                                        <a href={`${RESOURCE_URL}/img/doc_docente/${props.docente.user ? props.docente.user.id : null}/${props.docente.fileCedula}`} target="_blank" className="btn btn-info"><i class="fas fa-eye"></i></a>
                                                    </div>
                                                </td>
                                                
                                            </tr>}
                                            {props.docente.fileCertificado !== null &&
                                            <tr>
                                                <td>Certificado</td>
                                                <td>
                                                    {props.docente.fileCertificado}
                                                </td>
                                                <td class="text-right py-0 align-middle">
                                                    <div class="btn-group btn-group-sm">
                                                        <a href={`${RESOURCE_URL}/img/doc_docente/${props.docente.user ? props.docente.user.id : null}/${props.docente.fileCertificado}`} target="_blank" class="btn btn-info"><i className="fas fa-eye"></i></a>
                                                    </div>
                                                </td>
                                                
                                            </tr>}
                                            {props.docente.fileActa !== null &&
                                            <tr>
                                                <td>Acta de nacimieto</td>
                                                <td>
                                                    {props.docente.fileActa}
                                                </td>
                                                <td class="text-right py-0 align-middle">
                                                    <div class="btn-group btn-group-sm">
                                                        <a href={`${RESOURCE_URL}/img/doc_docente/${props.docente.user ? props.docente.user.id : null}/${props.docente.fileActa}`} target="_blank" className="btn btn-info"><i class="fas fa-eye"></i></a>
                                                    </div>
                                                </td>
                                                
                                            </tr>}
                                            </tbody>
                                        </table>
                                    </Card.Body>                                                
                                </Card>
                            </div>
                        </div>
            
                    </div>
                </Modal.Body>
            </Modal>
    );

    
}

export default DocentesConsulta;
