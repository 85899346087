import React from 'react';
import { useSelector }  from 'react-redux';
import { Modal, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faCloudDownloadAlt, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { RESOURCE_URL } from '../recursos/url';

const AlumnosConsulta = props => {

    const { open } = props;
    const { openClose } = props;
    const alumno = useSelector(state => state.Alumnos.alumno);

    return (
        <Modal
            size="lg"
            show={open}
            onHide={() => openClose(false)}
            dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
            scrollable
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
            <h4><i className="fas fa-user"></i> Datos del Alumno</h4>
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div class="card card-widget widget-user">
                                <div class="widget-user-header bg-dark">
                                    <h3 class="widget-user-username">{alumno.nombre}</h3>
                                    <h5 class="widget-user-desc">{alumno.apellido_paterno} {alumno.apellido_materno}</h5>
                                </div>
                                <div class="widget-user-image">
                                    {alumno.foto !== null ? 
                                    <img 
                                        class="img-circle elevation-2"
                                        src={`${RESOURCE_URL}/img/doc_alumno/${alumno.user_id}/${alumno.foto}`}
                                        alt="User Avatar"
                                    />:
                                    <img 
                                        class="img-circle elevation-2"
                                        src={`${RESOURCE_URL}/img/perfil.png`}
                                        alt="User Avatar"
                                    />}
                                </div>
                                <div class="card-footer">
                                    <div class="row">
                                        <div class="col-sm-4 border-right">
                                            <div class="description-block">
                                            <h5 class="description-header"><i class="fa fa-envelope"></i>  Email</h5>
                                            <span>{alumno.email}</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 border-right">
                                            <div class="description-block">
                                            <h5 class="description-header"><i class="fa fa-phone"></i> Teléfono</h5>
                                            <span>{alumno.telefono !== null ? alumno.telefono : 'N/A'}</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="description-block">
                                            <h5 class="description-header"><i class="fa fa-user-circle"></i> Usuario</h5>
                                            <span>{alumno.user && alumno.user.name}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <Card className="card-dark">
                                <Card.Header>
                                    <Card.Title>
                                        <h3 className="card-title">Documentos de ingreso</h3>
                                    </Card.Title>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                        <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </Card.Header>
                                <Card.Body className="p-0">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th>Documento</th>
                                            <th>Nombre</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        
                                        <tr>
                                            <td>Certificado</td>
                                            <td>
                                            <FontAwesomeIcon icon={alumno.certificado ? faCloudUploadAlt : faCloudDownloadAlt} color={alumno.certificado ? "green" : "red"}/> {alumno.certificado ? alumno.certificado : 'Sin Documento'} 
                                            </td>
                                            <td class="text-right py-0 align-middle">
                                                <div class="btn-group btn-group-sm">
                                                    {alumno.certificado ?
                                                        <a href={`${RESOURCE_URL}/img/doc_alumno/${alumno.user && alumno.user.id}/${alumno.certificado}`} target="_blank" className="btn btn-info"><i class="fas fa-eye"></i></a>
                                                        :
                                                        <button target="_blank" className="btn btn-info" disabled><i class="fas fa-eye"></i></button>
                                                    }
                                                </div>
                                            </td>
                                            
                                        </tr>
                                        <tr>
                                            <td>Título</td>
                                            <td>
                                            <FontAwesomeIcon icon={alumno.titulo ? faCloudUploadAlt : faCloudDownloadAlt} color={alumno.titulo ? "green" : "red"}/> {alumno.titulo ? alumno.titulo : 'Sin Documento'}
                                            </td>
                                            <td class="text-right py-0 align-middle">
                                                <div class="btn-group btn-group-sm">
                                                {alumno.titulo ?
                                                    <a href={`${RESOURCE_URL}/img/doc_alumno/${alumno.user && alumno.user.id}/${alumno.titulo}`} target="_blank" class="btn btn-info"><i className="fas fa-eye"></i></a>
                                                    :
                                                    <button target="_blank" className="btn btn-info" disabled><i class="fas fa-eye"></i></button>
                                                }
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Cédula</td>
                                            <td>
                                            <FontAwesomeIcon icon={alumno.cedula ? faCloudUploadAlt : faCloudDownloadAlt} color={alumno.cedula ? "green" : "red"}/> {alumno.cedula  ? alumno.cedula : 'Sin Documento'}
                                            </td>
                                            <td class="text-right py-0 align-middle">
                                               
                                                <div class="btn-group btn-group-sm">
                                                {alumno.cedula ?
                                                    <a href={`${RESOURCE_URL}/img/doc_alumno/${alumno.user && alumno.user.id}/${alumno.cedula}`} target="_blank" className="btn btn-info"><i class="fas fa-eye"></i></a>
                                                    : 
                                                    <button target="_blank" className="btn btn-info" disabled><i class="fas fa-eye"></i></button>
                                                }
                                                    
                                                </div>
                                            </td>
                                            
                                        </tr>
                                        <tr>
                                            <td>Acta de nacimieto</td>
                                            <td>
                                            <FontAwesomeIcon icon={alumno.acta_nacimiento ? faCloudUploadAlt : faCloudDownloadAlt} color={alumno.acta_nacimiento ? "green" : "red"}/> {alumno.acta_nacimiento !== null ? alumno.acta_nacimiento : 'Sin Documento'}
                                            </td>
                                            <td class="text-right py-0 align-middle">
                                               
                                                <div class="btn-group btn-group-sm">
                                                {alumno.acta_nacimiento ?
                                                    <a href={`${RESOURCE_URL}/img/doc_alumno/${alumno.user ? alumno.user.id : null}/${alumno.acta_nacimiento}`} target="_blank" className="btn btn-info"><i class="fas fa-eye"></i></a>
                                                    : 
                                                    <button target="_blank" className="btn btn-info" disabled><i class="fas fa-eye"></i></button>
                                                }
                                                    
                                                </div>
                                            </td>
                                            
                                        </tr>
                                        <tr>
                                            <td>CURP</td>
                                            <td>
                                            <FontAwesomeIcon icon={alumno.curp ? faCloudUploadAlt : faCloudDownloadAlt} color={alumno.curp ? "green" : "red"}/> {alumno.curp  ? alumno.curp : 'Sin Documento'}
                                            </td>
                                            <td class="text-right py-0 align-middle">
                                               
                                                <div class="btn-group btn-group-sm">
                                                {alumno.curp ?
                                                    <a href={`${RESOURCE_URL}/img/doc_alumno/${alumno.user && alumno.user.id}/${alumno.curp}`} target="_blank" className="btn btn-info"><i class="fas fa-eye"></i></a>
                                                    : 
                                                    <button target="_blank" className="btn btn-info" disabled><i class="fas fa-eye"></i></button>
                                                }
                                                    
                                                </div>
                                            </td>
                                            
                                        </tr>
                                        </tbody>
                                    </table>
                                </Card.Body>                                                
                            </Card>
                        </div>
                        {alumno.documento_egreso ?
                            alumno.documento_egreso.map(documento => {
                                return (
                                <div className="col-lg-12">
                                    <Card className="card-dark">
                                        <Card.Header>
                                            <Card.Title>
                                                <h3 className="card-title">Documentos de egreso - {documento.programa.nombre_programa}</h3>
                                            </Card.Title>
                                            <div class="card-tools">
                                                <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                                <i class="fas fa-minus"></i>
                                                </button>
                                            </div>
                                        </Card.Header>
                                        <Card.Body className="p-0">
                                            <table class="table">
                                                <thead>
                                                <tr>
                                                    <th>Documento</th>
                                                    <th>Nombre</th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                
                                                <tr>
                                                    <td>Certificado</td>
                                                    <td>
                                                    <FontAwesomeIcon icon={faCloudDownloadAlt} color={"red"}/> {'Sin Documento'} 
                                                    </td>
                                                    <td class="text-right py-0 align-middle">
                                                        <div class="btn-group btn-group-sm">
                                                            <button target="_blank" className="btn btn-info" disabled><i class="fas fa-eye"></i></button>
                                                        </div>
                                                    </td>
                                                    
                                                </tr>
                                                <tr>
                                                    <td>Título</td>
                                                    <td>
                                                    <FontAwesomeIcon icon={faCloudDownloadAlt} color={"red"}/> {'Sin Documento'}
                                                    </td>
                                                    <td class="text-right py-0 align-middle">
                                                        <div class="btn-group btn-group-sm">
                                                            <button target="_blank" className="btn btn-info" disabled><i class="fas fa-eye"></i></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Cédula</td>
                                                    <td>
                                                    <FontAwesomeIcon icon={faCloudDownloadAlt} color={"red"}/> {'Sin Documento'}
                                                    </td>
                                                    <td class="text-right py-0 align-middle">
                                                    
                                                        <div class="btn-group btn-group-sm">
                                                            <button target="_blank" className="btn btn-info" disabled><i class="fas fa-eye"></i></button>
                                                        </div>
                                                    </td>
                                                    
                                                </tr>
                                                
                                                </tbody>
                                            </table>
                                        </Card.Body>                                                
                                    </Card>
                                </div>
                                )
                            })
                        : null}
                    </div>
        
                </div>
            </Modal.Body>
        </Modal>
    );

    
}

export default AlumnosConsulta;
