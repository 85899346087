import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Encabezado from "../recursos/Encabezado";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { OverlayTrigger, Button, Tooltip, Modal, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import { cambiarEstatusSolicitud } from "../../redux/actions/Solicitudes.actions";

const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
    } ) => (
    <div className="btn-group" role="group">
    {
        options.map((option) => {

        const isSelect = currSizePerPage === `${option.page}`;
        return (
            <button
                key={ option.text }
                type="button"
                onClick={ () => onSizePerPageChange(option.page) }
                className={ `btn ${isSelect ? 'btn-secondary' : 'btn-primary'}` }
            >
            { option.text }
            </button>
        );
        })
    }
    </div>
);

const options = {
    sizePerPageRenderer
}

const PrincipalSolicitudes = () => {

    const dispatch = useDispatch();

    const solicitudesList = useSelector(state => state.Solicitudes.solicitudesList);
    const loading = useSelector(state => state.Solicitudes.loading);

    const responderEstatusSolicitud = (estatusId, planeacionId, solicitudId) => {
        dispatch( cambiarEstatusSolicitud(estatusId, planeacionId, solicitudId) );
    }

    const columns = [ {
        dataField: 'materiaData',
        text: 'Materia',
        sort: true,
        style: {
            width: '30%',
        },
        formatter: (cell, row) => (
                row.planeacion &&
                    row.planeacion.materia &&
                    row.planeacion.materia.materia_nombre.nombre_materia
        )
        },{
        dataField: 'grupoData',
        text: 'Grupo',
        sort: true,
        style: {
            width: '20%',
        },
        formatter: (cell, row) => (
                row.planeacion &&
                    row.planeacion.materia &&
                    row.planeacion.materia.grupo.nombre
            )
        }, {
            dataField: 'docenteData',
            text: 'Solicitante',
            sort: true,
            style: {
                width: '20%',
            },
            formatter: (cell, row) => (`${row.docente.nombre} ${row.docente.apellido_paterno} ${row.docente.apellido_materno}`)
        }, {
            dataField: 'created_at',
            text: 'Fecha Solicitada',
            sort: true,
            align: 'center',
            headerAlign: 'center',
            style: {
                width: '15%',
            },
            formatter: (cell, row) => (`${row.fecha} ${row.hora}`)
            
        }, {
        dataField: '3',
        text: 'Acciones',
        sort: true, 
        headerAlign: 'center',
        align: 'center',
        style: {
            width: '15%'
        },
        formatter: (cell, row) => {
            return (
                <div className="btn-group">
                    <OverlayTrigger overlay={<Tooltip id={row.id}>Aceptar Solicitud</Tooltip>}>
                        <Button variant="success" onClick={() => responderEstatusSolicitud(4, row.planeacion_id, row.id) } size="sm" disabled={loading}>
                            <FontAwesomeIcon icon={faCheck}/> Aceptar
                        </Button> 
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip id={row.id}>Rechazar Solicitud</Tooltip>}>
                        <Button variant="danger" onClick={() => responderEstatusSolicitud(5, row.planeacion_id, row.id)} size="sm" disabled={loading}>
                            <FontAwesomeIcon icon={faTimes}/> Rechazar
                        </Button>
                    </OverlayTrigger>
                </div>
            );
        }
      }];

    return ( 
        <>
        <title>CCH | Solicitudes</title>
        <Encabezado/>
        <div className="row animated fadeIn">
            <div className="col-lg-12 mb-3">
                <Card>
                    <Card.Body className="p-2">
                        <BootstrapTable 
                            classes="table table-striped" 
                            keyField='id' 
                            defaultSorted={ [{
                                dataField: 'id',
                                order: 'desc'
                              }]} 
                            /* bordered={ false } */
                            data={ solicitudesList } 
                            columns={ columns } 
                            pagination={ paginationFactory(options) }
                            noDataIndication="No se encontrarón resultados."
                        />
                    </Card.Body>
                </Card>
            </div>
        </div>
        <Modal
        size="sm"
        show={loading}
        centered
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-sm"
        >
            <Modal.Body><FontAwesomeIcon icon={faSpinner} spin/> Enviando respuesta</Modal.Body>
        </Modal>
        </>
     );
}
 
export default PrincipalSolicitudes;