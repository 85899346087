import React from "react";
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import Encabezado from "../recursos/Encabezado";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faMailBulk, faPhone } from "@fortawesome/free-solid-svg-icons";

const myIcon = L.icon({
    iconUrl: '/img/location.png',
    iconSize: [25,40],/* 64, 64 location 4*/
    iconAnchor: [10, 40],/* 40,40 */
    popupAnchor: [3, -25]
});

const Contacto = () => {
    
    const styleMap = { "width": "100%", "height": "80vh" };

    return ( 
        <>
        <title>CCH | Contacto</title>
        <Encabezado/>
        <div className="row animated fadeIn">
            <div className="col-lg-6 mb-3">
                <Map style={styleMap} center={[22.76524184119336, -102.53580560990757]} zoom={16}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={[22.76524184119336, -102.53580560990757]} icon={myIcon}>
                        <Popup width={400}>
                            <img src="/img/cch.jpg" style={{ width: '100%' }}/>
                        </Popup>
                    </Marker>
                </Map>
            </div>
            <div className="col-lg-6">
                <p class="lead">Datos de Contacto</p>
                <div class="table-responsive">
                    <table class="table">
                      <tr>
                        <th ><FontAwesomeIcon icon={faAddressCard}/> Dirección:</th>
                        <td>Cascada 27, Cañada de la Bufa, 98619 Guadalupe, Zac.</td>
                      </tr>
                      <tr>
                        <th><FontAwesomeIcon icon={faPhone}/> Teléfono: </th>
                        <td>--</td>
                      </tr>
                      <tr>
                        <th><FontAwesomeIcon icon={faMailBulk}/> Email: </th>
                        <td></td>
                      </tr>
                      
                    </table>
                    
                </div>

                <div className="col-lg-12">
                    <p class="lead">Métodos de pago:</p>
                    <img src="/img/credit/visa.png" alt="Visa"/> 
                    <img src="/img/credit/mastercard.png" alt="Mastercard"/> 
                    <img src="/img/credit/american-express.png" alt="American Express"/> 
                    <img src="/img/credit/paypal2.png" alt="Paypal"/> 
                    {/* <p class="text-muted well well-sm shadow-none" style={{ marginTop: '10px' }}>
                        Los pagos correspondientes a la mensualidad, se hace a la siguiente cuenta:
                    </p> */}
                    <p class="text-muted well well-sm shadow-none" style={{ marginTop: '10px' }}>
                        Cuenta: 16516541
                        <br></br>
                        CLABE: 65134613161654
                    </p>
                    <p class="text-muted well well-sm shadow-none" /* style="margin-top: 10px;" */>
                        
                    </p>
                </div>
            </div>
        </div>
        </>
     );
}
 
export default Contacto;