import {
    LISTA_DOCENTES,
    DATOS_DOCENTE,
    LOADING_DOCENTES,
    LOADING_DOCENTE,
    RESET_DOCENTES,
    ERROR_DOCENTES,
    ERROR_DOCENTE,
    HORARIO_DOCENTE,
    LOADING_HORARIO_DOCENTE
} from '../../types';

const initialState = {
    docentesList: [],
    docente: null,
    errorListDocentes: false,
    loadingList: false,    
    errorDocente: false,
    loading: false,
    horario: null,
    loadingHorario: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LISTA_DOCENTES:
            return {
                ...state,
                docentesList: action.payload
            }
        case RESET_DOCENTES: 
            return {
                ...state,
                docentesList: [],
                docente: null,
                errorListDocentes: false,
                loadingList: false,    
                errorDocente: false,
                loading: false,
                horario: null,
                loadingHorario: false
            }
        case LOADING_DOCENTES:
            return {
                ...state,
                loadingList: action.payload
            }
        case ERROR_DOCENTES:
            return {
                ...state,
                errorListDocentes: action.payload
            }
        case ERROR_DOCENTE:
            return {
                ...state,
                errorDocente: action.payload
            }
        case LOADING_DOCENTE: 
            return  {
                ...state,
                loading: action.payload
            }
        case DATOS_DOCENTE: 
            return {
                ...state,
                docente: action.payload
            }
        case HORARIO_DOCENTE: 
            return {
                ...state,
                horario: action.payload
            }
        case LOADING_HORARIO_DOCENTE: 
            return {
                ...state,
                loadingHorario: action.payload
            }
        default:
            return state;
    }
}