
import clienteAxios from '../../config/axios';
import { REGISTRO_FALLIDO } from '../../types/index';
import { mensajeCentral, mensajeDangerToastify, mensajeWarningToastify, mensajeSuccessToastify, mensajeInfoToastify, msjError } from '../../components/recursos/mensajes';
import store from "../../store";

import { obtenerListaAlumnos } from './Alumnos.action';

import {
    LISTA_GRUPOS,
    DATOS_GRUPO,
    LOADING_GRUPOS,
    LOADING_GRUPO,
    ERROR_GRUPOS,
    ERROR_GRUPO,
    RESET_GRUPOS,
    REGISTRO_EXITO
} from '../../types';

const token = `${store.getState().Authentication.courrentUser.token_type} ${store.getState().Authentication.courrentUser.access_token}`;

export function cargarListaGrupos (filtro) {
    return ( dispatch) => {
        
        dispatch( loadingGrupos (true) );
        clienteAxios.get('grupos/all',
        {
            headers: {
                'Authorization' : token
            },
            params: {
                grupo: filtro.grupo,
                sede_id: filtro.sede_id,
                programa_id: filtro.programa_id
            }
        }).then(response => {

            let listaGrupos = response.data;
            
            dispatch ( setGuposList(listaGrupos) );
            dispatch( loadingGrupos(false) );
            dispatch( errorGrupos(false) );

        }).catch(error => {
            
            dispatch( errorGrupos(true) );
            dispatch( loadingGrupos(false) );
            msjError(error);

        });
    };

}

export const obtenerListaGruposAsignacion = (alumno_id) => {
    return ( dispatch) => {
        
        dispatch( loadingGrupos (true) );
        clienteAxios.get('/grupo/grupos-asignacion-list/'+alumno_id,
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {

            let listaGrupos = response.data;
            
            dispatch ( setGuposList(listaGrupos) );
            dispatch( loadingGrupos(false) );
            dispatch( errorGrupos(false) );

        }).catch(error => {
            
            dispatch( errorGrupos(true) );
            dispatch( loadingGrupos(false) );
            if (error.response) {//el servidor respondió con un código de estado que cae fuera del rango de 2xx
                mensajeDangerToastify('Error');
            } else if (error.request) {//Se realizó la solicitud pero no se recibió respuesta
                mensajeDangerToastify('Se realizó la solicitud pero no se recibió respuesta');
            } else {// Algo sucedió al configurar la solicitud y provocó un error
                mensajeDangerToastify('Algo sucedió al configurar la solicitud');
            }

        });
    };

}

export function registrarDatosGrupo (datosGrupo) {
    return (dispatch) => {
        
        dispatch( loadingGrupo(true) );

        
            clienteAxios.post('grupo/registro',
            datosGrupo,
            {
                headers: {
                    'Authorization' : token
                }
            }).then(response => {
                
                if (response.status === 200) {
                    dispatch ( registroSuccess ( true ) );
                    mensajeSuccessToastify(response.data.message);
                } else {
                    mensajeWarningToastify(response.data.message);
                }
                dispatch ( errorGrupo ( false ) );
        
            }).catch(error => {
    
                dispatch ( errorGrupo ( true ) );
                msjError(error);
    
            }).finally(final => {

                dispatch ( registroSuccess ( false ) );
                dispatch( loadingGrupo(false) );
                
            });
        
    }
}

export const registrarAltaGrupo =  (grupo_id, matricula, alumno_id) => {
    return (dispatch) => {
        dispatch( loadingGrupo(true) );
        clienteAxios.post('grupo/alta-grupo',
        { grupo_id: grupo_id, alumno_id: alumno_id, matricula: matricula },
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {

            if (response.status === 200) {
                dispatch ( registroSuccess ( true ) );
                mensajeSuccessToastify(response.data.message);
                dispatch( obtenerListaGruposAsignacion(alumno_id) );
                dispatch( obtenerListaAlumnos({}) );
            } else {
                mensajeWarningToastify(response.data.message);
            }
            dispatch ( errorGrupo ( false ) );
           
        }).catch(error => {
    
            dispatch ( errorGrupo ( true ) );
            msjError(error);
    
        }).finally(final => {

            dispatch ( registroSuccess ( false ) );
            dispatch( loadingGrupo(false) );

        });
    }
}

export const registrarBajaGrupo =  (grupo_id, matricula, alumno_id) => {
    return  async (dispatch) => {
        dispatch( loadingGrupo(true) );
        
        clienteAxios.post('grupo/baja-grupo',
        { grupo_id: grupo_id, alumno_id: alumno_id, matricula: matricula },
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {

            if (response.status === 200) {
                dispatch ( registroSuccess ( true ) );
                mensajeInfoToastify(response.data.message);
                dispatch( obtenerListaGruposAsignacion(alumno_id) );
                dispatch( obtenerListaAlumnos({}) );
            } else {
                mensajeWarningToastify(response.data.message);
            }
            dispatch ( errorGrupo ( false ) );
           
        }).catch(error => {
    
            dispatch ( errorGrupo ( true ) );
            msjError(error);
    
        }).finally(final => {

            dispatch ( registroSuccess ( false ) );
            dispatch( loadingGrupo(false) );

        });
        
       
    }
}

export function modificarDatosGrupo (datosGrupo) {
    return (dispatch) => {
        dispatch( loadingGrupo(true) );
        
        if (datosGrupo.nombre !== '' && datosGrupo.sede_id !== "0" && datosGrupo.programa_id !== "0" && datosGrupo.fecha_inicio !== '' && datosGrupo.fecha_fin !== '' ) {
            clienteAxios.put ('grupo/modificar/' + datosGrupo.id,
            {
                nombre : datosGrupo.nombre,
                sede_id : datosGrupo.sede_id,
                programa_id : datosGrupo.programa_id,
                fecha_inicio : datosGrupo.fecha_inicio,
                fecha_fin : datosGrupo.fecha_fin 
            },
            {
                headers: {
                    'Authorization' : token
                }
            }).then(response => {
                
                if (response.status === 200) {
    
                    dispatch ( registroSuccess ( true ) );
                    if (response.data.success) {
                        mensajeSuccessToastify(response.data.message);
                    } else {
                        mensajeWarningToastify(response.data.message);
                    }
    
                }
                dispatch ( errorGrupo ( false ) );
        
            }).catch(error => {
    
                dispatch ( errorGrupo ( true ) );
                if (error.response) {//el servidor respondió con un código de estado que cae fuera del rango de 2xx
                
                    mensajeDangerToastify('Error');
    
                } else if (error.request) {//Se realizó la solicitud pero no se recibió respuesta
    
                    mensajeDangerToastify('Se realizó la solicitud pero no se recibió respuesta');
                    
                } else {// Algo sucedió al configurar la solicitud y provocó un error
                    
                    mensajeDangerToastify('Algo sucedió al configurar la solicitud');
    
                }
            
            }).finally(final => {

                dispatch( loadingGrupo(false) );
                dispatch ( registroSuccess ( false ) );

            });
        } else {

            dispatch ( registroFallido ( true ) );
            mensajeCentral('Ingresa los campos obligatorios.', 'warning'); 

        }
        
    }
}

export function resetSFE () {
    return (dispatch) => {
        dispatch ( errorGrupo ( false ) );
        dispatch ( registroSuccess ( false ) );
        dispatch ( registroFallido ( false ) );
    }
}

export function getDatosGrupo (id) {
    return (dispatch) => {
        dispatch( loadingGrupo (true) );
        clienteAxios.get(`grupo/${id}`,
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {
            
            dispatch (obtenerDatosGrupo(response.data) );
            
            dispatch( loadingGrupo(false) );
            dispatch( errorGrupo(false) );

        }).catch(error => {
            
            dispatch( errorGrupo(true) );
            dispatch( loadingGrupo(false) );

        });
    };
}

export function grupoDelete (id) {
    return (dispatch) => {
        dispatch( loadingGrupo (true) );
        clienteAxios.delete(`grupo/eliminar/${id}` ,
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {
            
            if (response.status === 200) {
    
                if (response.data.success) {
                    mensajeSuccessToastify(response.data.message);
                    
                } else {
                    mensajeWarningToastify(response.data.message);
                }

            }
            dispatch ( errorGrupo ( false ) );

        }).catch(error => {
            
            mensajeWarningToastify(error.data.message);
            dispatch( errorGrupo(true) );
            dispatch( loadingGrupo(false) );

        });
        
    };
}





export function reset () {
    return (dispatch) => {
        dispatch( resetGrupos () );
    }
}

export const setGuposList = (listaGrupos) => {
    return {
        type: LISTA_GRUPOS,
        payload: listaGrupos
    }
};

export const resetGrupos = () => {
    return {
        type: RESET_GRUPOS
    }
}

export const loadingGrupos = (loading) => {
    return {
        type: LOADING_GRUPOS,
        payload: loading
    }
}

export const loadingGrupo = (loading) => {
    return {
        type: LOADING_GRUPO,
        payload: loading
    }
}

export const errorGrupos = (error) => {
    return {
        type: ERROR_GRUPOS,
        payload: error
    }
}

export const errorGrupo = (error) => {
    return {
        type: ERROR_GRUPO,
        payload: error
    }
}

export const registroSuccess = (success) => {
    return {
        type: REGISTRO_EXITO,
        payload: success
    }
}

export const registroFallido = (success) => {
    return {
        type: REGISTRO_FALLIDO,
        payload: success
    }
}

export const obtenerDatosGrupo = (grupo) => {
    return {
        type: DATOS_GRUPO,
        payload: grupo
    }
}
