import {
    CARGAR_SEDES,
    LOADING_SEDES,
    ERROR_SEDES,
    RESET_SEDES
} from '../../types';

const initialState = {
    sedesList: [],
    sede: {},
    errorListsedes: false,
    loadingList: false,    
    errorSede: false,
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CARGAR_SEDES:
            return {
                ...state,
                sedesList: action.payload
            }
        case RESET_SEDES: 
            return {
                ...state,
                sedesList: [],
                sede: {},
                errorListsedes: false,
                loadingList: false,    
                errorSede: false,
                loading: false
            }
        case LOADING_SEDES:
            return {
                ...state,
                loadingList: action.payload
            }
        case ERROR_SEDES:
            return {
                ...state,
                errorListSedes: action.payload
            }
        default:
            return state;
    }
}