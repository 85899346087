import {
    SET_TAREAS,
    SET_TAREA,
    LOADING_TAREAS,
    LOADING_TAREA,
    ERROR_TAREAS,
    ERROR_TAREA,
    RESET_TAREAS,
    SET_SUCCESS_TAREA
} from '../../types';

const initialState = {
    tareasList: [],
    tarea: {},
    errorList: false,
    loadingList: false,    
    errorTarea: false,
    successTarea: false,
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_TAREAS:
            return {
                ...state,
                tareasList: action.payload
            }
        case LOADING_TAREAS:
            return {
                ...state,
                loadingList: action.payload
            }
        case ERROR_TAREAS:
            return {
                ...state,
                errorList: action.payload
            }
        case SET_TAREA: 
            return {
                ...state,
                tarea: action.payload
            }
        case LOADING_TAREA: 
            return {
                ...state,
                loading: action.payload
            }
        case ERROR_TAREA:
            return {
                ...state,
                error: action.payload
            }
        case SET_SUCCESS_TAREA: 
            return {
                ...state,
                successTarea: action.payload
            }
        case RESET_TAREAS: 
            return {
                ...state,
                tareasList: [],
                tarea: {},
                errorList: false,
                loadingList: false,    
                errorTarea: false,
                successTarea: false,
                loading: false
            }
        default:
            return state;
    }
}