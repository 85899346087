import {
    URL_PARAMETROS,
    RESET_PLANEACION,
    CARGAR_PLANEACION_GRUPO,
    ERROR_PLANEACION_GRUPO,
    LOADING_PLANEACION_GRUPO,
    REGISTRAR_PLANEACION_MODULOS,
    ERROR_PLANEACION_MODULOS,
    LOADING_PLANEACION_MODULOS,
    REGISTRO_EXITO,
    REGISTRO_FALLIDO
} from '../../types';

const initialState = {
    planeacionGrupo: null,
    planeacionModulos: null,
    loading: false,
    loadingPlanModulos: false,
    errorPlanModulos: false,
    error: false,
    parametros: { 
        grupo_id: 0,
        programa_id: 0 
    },
    registroSuccess: false,
    registroFallido: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case URL_PARAMETROS: 
            return  {
                ...state,
                parametros: action.payload
            }
        case RESET_PLANEACION: 
            return  {
                ...state,
                planeacionGrupo: null,
                planeacionModulos: null,
                loading: false,
                loadingPlanModulos: false,
                errorPlanModulos: false,
                error: false,
                parametros: { 
                    grupo_id: 0,
                    programa_id: 0 
                },
                registroSuccess: false,
                registroFallido: false
            }
        case CARGAR_PLANEACION_GRUPO: 
            return {
                ...state,
                planeacionGrupo: action.payload
            }
        case ERROR_PLANEACION_GRUPO: 
            return {
                ...state,
                error: action.payload
            }
        case LOADING_PLANEACION_GRUPO: 
            return {
                ...state,
                loading: action.payload
            }
        case REGISTRAR_PLANEACION_MODULOS:
            return {
                ...state,
                planeacionModulos: action.payload
            }
        case ERROR_PLANEACION_MODULOS: 
            return {
                ...state,
                errorPlanModulos: action.payload
            }
        case LOADING_PLANEACION_MODULOS: 
            return {
                ...state,
                loadingPlanModulos: action.payload     
            }
        case REGISTRO_EXITO:
            return {
                ...state,
                registroSuccess: action.payload
            }
        case REGISTRO_FALLIDO: 
            return {
                ...state, 
                registroFallido: action.payload
            }
        default:
            return state;
    }
}