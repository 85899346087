import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, ListGroup, Col, Row } from 'react-bootstrap';
import { cargarListaProgramasDetalle } from '../../redux/actions/Programas.actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faListAlt } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import Encabezado from '../recursos/Encabezado';

const Programas = () => {

    const [openModalPrograma, setOpenModalPrograma] = useState(false);
    const [programaSelect, setProgramaSelect] = useState(null);
    const [semestres, setSemestres] = useState(null);
    const dispatch = useDispatch();
    const programasList = useSelector(state => state.Programas.programasList);

    useEffect(() => {
        /* if (programasList.length === 0) { */
            dispatch( cargarListaProgramasDetalle() );
        /* } */
    }, []);

    return ( 
        <>
        <title>CCH | Programas</title>
        <Encabezado/>
        <div className="row animated fadeIn">
        
            <div className="col-lg-12">
            <Card body className="shadow rounded">
                <ListGroup>
                    {programasList.map(programa => {
                        return (
                            <ListGroup.Item action onClick={() => { setOpenModalPrograma(true); setProgramaSelect(programa.nombre_programa); setSemestres(programa.semestres_programas); }}>
                                <FontAwesomeIcon icon={faListAlt}/> {programa.nombre_programa}
                            </ListGroup.Item>
                        )
                    })}
                    
                </ListGroup>
            </Card>
            </div>
        </div>
        <Modal
            show={openModalPrograma}
            onHide={() => setOpenModalPrograma(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            scrollable
            dialogClassName="modal-dialog modal-xl"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    <h4><FontAwesomeIcon icon={faListAlt}/> {programaSelect}</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Row xs={1} md={2} className="g-4">
                {(semestres !== null && semestres !== undefined) ? 
                    semestres.map(semestre => {
                        return (
                        <Col className="mb-3">
                            <Card className="shadow h-100">
                                <Card.Header className="text-uppercase" as="h5">{semestre.nombre}</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <ListGroup>
                                            {semestre.materia_nombres.map(materia => {
                                                return (
                                                    <ListGroup.Item action>
                                                        <FontAwesomeIcon icon={faBookOpen}/> {materia.nombre_materia}
                                                    </ListGroup.Item>
                                                )
                                            })}
                                            
                                        </ListGroup>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        )
                    })
                : null}
                
            </Row>
            </Modal.Body>
            <Modal.Footer>
                
            </Modal.Footer>
            </Modal>
        </>
     );
}
 
export default Programas;