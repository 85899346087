import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faComments, faList, faSync } from '@fortawesome/free-solid-svg-icons';
/* actions */
import { cargarListaGrupos, registrarDatosGrupo, modificarDatosGrupo ,grupoDelete, setGuposList } from '../../redux/actions/Grupos.actions';
import { cargarListaSedes } from '../../redux/actions/Sedes.actions';
import { cargarListaProgramas } from '../../redux/actions/Programas.actions';
import { setParametros } from '../../redux/actions/Planeacion.actions';
import { validarDatosGrupo } from '../../redux/actions/Validacion.actions';
import { obtenerSolicitudes } from '../../redux/actions/Solicitudes.actions';
import { obtenerListaAlumnosGrupo, resetDatosAlumno, setListaAlumnos } from '../../redux/actions/Alumnos.action';
/* end-actions */
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { OverlayTrigger, Button, Tooltip, Modal, Form, InputGroup } from 'react-bootstrap';
import EditGrupo from './EditGrupo';
import Encabezado from '../recursos/Encabezado';
import ListaAlumnosGrupo from './ListaAlumnosGrupo';

const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
    } ) => (
    <div className="btn-group" role="group">
    {
        options.map((option) => {

        const isSelect = currSizePerPage === `${option.page}`;
        return (
            <button
                key={ option.text }
                type="button"
                onClick={ () => onSizePerPageChange(option.page) }
                className={ `btn ${isSelect ? 'btn-secondary' : 'btn-primary'}` }
            >
            { option.text }
            </button>
        );
        })
    }
    </div>
);

const options = {
    sizePerPageRenderer
}

const PrincipalGrupos = () => {

    const [filtroBusqueda, setfiltroBusqueda] = useState({ grupo: null, sede_id: null, programa_id: null});
    const [grupo, setgrupo] = useState({ id: '', nombre: '', sede_id: '0', programa_id: '0', fecha_inicio: '', fecha_fin: ''});
    const [open, setopen] = useState(false);
    const [openDelete, setopenDelete] = useState(false);
    const [OpenModalAlumnos, setOpenModalAlumnos] = useState(false)

    const { gruposList, loadingList, loading, registroSuccess } = useSelector(state => state.Grupos);
    
    /* REDUX */
    const sedesList = useSelector(state => state.Sedes.sedesList);
    const programasList = useSelector(state => state.Programas.programasList);
    const grupoValidacion = useSelector(state => state.Validacion.grupoValidacion);
    /* END REDUX */

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch( cargarListaGrupos(filtroBusqueda) );
        dispatch( cargarListaSedes(filtroBusqueda) );
        dispatch( cargarListaProgramas(filtroBusqueda) );
        dispatch( obtenerSolicitudes() );
        dispatch( resetDatosAlumno() );
    }, []);

    useEffect(() => {
        if (registroSuccess) {
            dispatch( cargarListaGrupos(filtroBusqueda) );
            setopen(false);
        }
    }, [registroSuccess]);

    useEffect(() => {
        
        return () => {
            dispatch( setGuposList([]) );
        }
    }, []);

    const changeInput = (e) => {
        setfiltroBusqueda({
            ...filtroBusqueda,
            [e.target.name]: e.target.value
        });
    };

    const changeInputGrupo = (e) => {
        if (e.target.name === 'fecha_fin' || e.target.name === 'fecha_inicio') {
            
        }

        setgrupo({
            ...grupo, 
            [e.target.name]: e.target.value
        });        
    };

    const openClose = (open) => {
        setopen(open);
        setgrupo({ 
            id: '0', 
            nombre: '', 
            sede_id: '0', 
            programa_id: '0', 
            fecha_inicio: '', 
            fecha_fin: ''
        });
        dispatch( validarDatosGrupo({ nombre: false, sede_id: false,  programa_id: false, fecha_inicio: false,  fecha_fin: false }) );
    };

    const openDeleteFunction = (row) => {
        setopenDelete(true);
        setgrupo({
            id: row.id,
            nombre: row.nombre,
            sede_id: row.sede_id,
            programa_id: row.programa_id,
            fecha_inicio: row.fecha_inicio,
            fecha_fin: row.fecha_fin
        });
    }

    const eliminarDatos = (id) => {
        setopenDelete(false);
        setgrupo({
            id: '0',
            nombre: '',
            sede_id: '0',
            programa_id: '0',
            fecha_inicio: '',
            fecha_fin: ''
        });
        dispatch( grupoDelete(id) );
        dispatch ( cargarListaGrupos(filtroBusqueda) );
    }

    const aplicarFiltro = () => {

        dispatch ( cargarListaGrupos(filtroBusqueda) );
   
    };

    const limpiarFiltro = () => {
        setfiltroBusqueda({
            id: 0,
            grupo: null,
            sede_id: null,
            programa_id: null
        });
    }

    const limpiarDatosGrupo = () => {
        setgrupo({
            id: '0',
            nombre: '',
            sede_id: '0',
            programa_id: '0',
            fecha_inicio: '',
            fecha_fin: ''
        });
        dispatch( validarDatosGrupo({
                nombre: false,
                sede_id: false, 
                programa_id: false,
                fecha_inicio: false, 
                fecha_fin: false
            }) 
        );
    };

    const accionModificarGrupo = (row) => {
        setopen(true);
        setgrupo({
            id: row.id,
            nombre: row.nombre.toUpperCase(),
            sede_id: row.sede_id,
            programa_id: row.programa_id,
            fecha_inicio: row.fecha_inicio,
            fecha_fin: row.fecha_fin
        });
    } 

    const redireccionarPlaneacion = (id, planeacion_id) => {
        dispatch( setParametros(id, planeacion_id) );
        history.push(`/planeacion`);
    }

    const guardarDatosGrupo = () => {

        let datosValidacion = {
            nombre: grupo.nombre === '' ? true : false,
            sede_id: grupo.sede_id === '0' || grupo.sede_id === "0" ? true : false, 
            programa_id: grupo.programa_id === '0' || grupo.programa_id === "0" ? true : false,
            fecha_inicio: grupo.fecha_inicio === '' ? true : false, 
            fecha_fin: grupo.fecha_fin === '' ? true : false
        }

        dispatch( validarDatosGrupo(datosValidacion) );

        if (grupo.nombre !== '' && grupo.sede_id !== '0' && grupo.programa_id !== '0' && grupo.fecha_inicio !== '' && grupo.fecha_fin !== '') {
            if (grupo.id === '0') {
                dispatch( registrarDatosGrupo(grupo) );
            } else {
                dispatch( modificarDatosGrupo(grupo) );
            }
        }
       
        
    }


    const cicloEscolar = (cell, row) => {
        return ( <div>{row.fecha_inicio} - {row.fecha_fin}</div> );
    };

    const asignarHorarios = (grupo_id, programa_id) => {
        dispatch( setParametros(grupo_id, programa_id) );
        history.push('/asignacion/horarios');
        
    }

    const obtenerListaAlumnos = (grupo, open) => {
        
        if (open) {
            dispatch( obtenerListaAlumnosGrupo(grupo.id) );
            setgrupo({
                id: grupo.id,
                nombre: grupo.nombre.toUpperCase(),
                sede_id: grupo.sede_id,
                programa_id: grupo.programa_id,
                fecha_inicio: grupo.fecha_inicio,
                fecha_fin: grupo.fecha_fin
            });
        } else {
            dispatch( setListaAlumnos([]) );
            setgrupo({
                id: null,
                nombre: null,
                sede_id: null,
                programa_id: null,
                fecha_inicio: null,
                fecha_fin: null
            });
        }
        
        setOpenModalAlumnos(open);
    } 

    const columns = [{
        dataField: 'id',
        text: 'No.',
        sort: true,
        align: 'center',
        style: {
            width: '5%'
        }
      }, {
        dataField: 'nombre',
        text: 'Nombre',
        sort: true,
        align: 'left',
        style: {
            width: '15%',
        }
      }, {
        dataField: 'sede.nombre_sede',
        text: 'Sede',
        sort: true,
        align: 'left',
        style: {
            width: '15%'
        }
      }, {
        dataField: 'programa.nombre_programa',
        text: 'Programa',
        sort: true,
        align: 'left',
        style: {
            width: '35%'
        }
      }, {
        dataField: '2',
        text: 'Ciclo escolar',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        style: {
            width: '15%'
        },
        formatter: cicloEscolar
      },{
        dataField: '3',
        text: 'Acciones',
        sort: true,
        headerAlign: 'center',
        align: 'center',
        style: {
            width: '15%'
        },
        formatter: (cell, row) => {
            return (
                <>
                <div className="btn-group">
                    <OverlayTrigger overlay={<Tooltip id={row.id}>Planeación</Tooltip>}>
                        <Button variant="success" size="sm" onClick={() => redireccionarPlaneacion(row.id, row.programa_id)}><i className="fas fa-sitemap" ></i></Button>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip id={row.id}>Asignar horarios</Tooltip>}>
                        <Button variant="primary" size="sm" onClick={() => asignarHorarios(row.id, row.programa_id)}><i className="fa fa-calendar-plus" ></i></Button>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip id={row.id}>Modificar datos del grupo</Tooltip>}>
                        <Button variant="warning" size="sm" onClick={() => accionModificarGrupo(row)}><i className="fas fa-edit" ></i></Button>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip id={row.id}>Lista de alumnos</Tooltip>}>
                        <Button variant="secondary" size="sm" onClick={() => obtenerListaAlumnos(row, true)}><FontAwesomeIcon icon={faList}/></Button>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip id={row.id}>Eliminar grupo</Tooltip>}>
                        <Button variant="danger" size="sm" onClick={() => openDeleteFunction(row)}><i className="fas fa-trash" ></i></Button>
                    </OverlayTrigger>
                </div>
                </>
            );
        }
      }];

    return (
        <>
            <title>CCH | Grupos</title>
            <Encabezado/>
            <div className="row animated fadeIn">
                
                <div className="col-lg-12 ">
                    <div className="card collapsed-card shadow bg-white rounded">
                        <div className="card-header">
                            <h5 className="card-title">
                            <i className="fas fa-search"></i> Busqueda
                            </h5>

                            <div className="card-tools">
                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                <i className="fas fa-minus"></i>
                            </button>
                            </div>
                        </div>
                        
                        <div className="card-body" style={{display: 'none'}}>
                            <div className="row">
                                <div className="col-lg-4 form-group">
                                    <label>Grupo</label>
                                    <input className="form-control" type="text" placeholder="Grupo" name="grupo" onChange={changeInput} value={filtroBusqueda.grupo !== null ? filtroBusqueda.grupo : ''}></input>
                                </div>
                                <div className="col-lg-4 form-group">
                                    <label>Sede</label>
                                    <select className="form-control" name="sede_id" onChange={changeInput} value={filtroBusqueda.sede_id !== null ? filtroBusqueda.sede_id : 0}>
                                        <option value={0}>Selecciona una opción</option>
                                        {sedesList.map((sede) => {
                                            return (
                                            <option value={sede.id} key={sede.id}>{sede.nombre_sede}</option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className="col-lg-4 form-group">
                                    <label>Programa</label>
                                    <select className="form-control" name="programa_id" onChange={changeInput} value={filtroBusqueda.programa_id !== null ? filtroBusqueda.programa_id : 0}>
                                        <option value={0}>Selecciona una opción</option>
                                        {programasList.map((programa) => {
                                            return (
                                            <option value={programa.id} key={programa.id}>{programa.nombre_programa}</option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className="col-lg-12">
                                    <div className="btn-group btn-sm float-right" role="group">
                                        <button onClick={limpiarFiltro} className="btn btn-secondary " title="Limpiar filtro">
                                            <i className="fas fa-eraser"></i>
                                        </button>
                                        <button onClick={aplicarFiltro} className="btn btn-success " title="Buscar">
                                            <i className="fas fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </div>
                <div className="col-lg-12 mb-3">
                    
                </div>
                <div className="col-lg-12">
                    <div className="card shadow bg-white rounded ">
                        <div className="card-header">
                            <h3 className="card-title">Lista grupos</h3>
                            
                            <div class="card-tools">
                            {loadingList ?
                               <FontAwesomeIcon icon={faSync} spin/>
                            : null}
                            </div>
                            
                            
                        </div> 
                        <div className="card-body table-responsive p-2">
                            <BootstrapTable 
                                classes="table-striped" 
                                keyField='id' 
                                defaultSorted={ [{
                                    dataField: 'id',
                                    order: 'desc'
                                }]} 
                                data={ gruposList } 
                                columns={ columns } 
                                pagination={ paginationFactory(options) }
                                noDataIndication="No se encontrarón resultados."
                            />
                        </div>
                        <div className="card-footer">
                            <Button variant="primary" className="float-right" size="sm"  onClick={() => openClose(true)}><i className="fas fa-plus" ></i> Nuevo grupo</Button>
                        </div>
                    </div>
                   
                </div>
            </div>
            
            <EditGrupo 
                grupo={grupo}
                loading={loading}
                grupoValidacion={grupoValidacion}
                sedesList={sedesList}
                programasList={programasList}
                open={open}
                changeInputGrupo={changeInputGrupo}
                guardarDatosGrupo={guardarDatosGrupo}
                openClose={openClose}
            />

            <Modal
                show={openDelete}
                onHide={() => setopenDelete(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                <h4><i className="fas fa-users"></i> Eliminar datos del grupo</h4>
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12 mb-3">
                           ¿Esta seguro de eliminar el registro?         
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="col-lg-12">
                        <button type="button" className="btn btn-danger float-left" data-dismiss="modal" onClick={() => setopenDelete(false)}><i className="fa fa-times"></i> Cancelar</button>
                        <button type="button" className="btn btn-success float-right" onClick={ () => eliminarDatos(grupo.id) }><i className="fa fa-save"></i> Aceptar</button>
                    </div>
                </Modal.Footer>
            </Modal>

            <ListaAlumnosGrupo
                open={OpenModalAlumnos}
                obtenerListaAlumnos={obtenerListaAlumnos}
                grupo={grupo}
            />

             
        </>
    );
};

export default PrincipalGrupos;