import React from 'react';
import { useSelector } from 'react-redux';
import './App.css';
import { BrowserRouter as Router, Route, Switch  } from 'react-router-dom';
import NavBar from './components/nav-bar/NavBar';
import Menu from './components/panel/Menu';
import './admin-lte/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css';
import './admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
import './admin-lte/dist/css/adminlte.min.css';
import './admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css';
import './admin-lte/plugins/fontawesome-free/css/all.min.css';
import PrincipalGrupos from './components/Grupos/PrincipalGrupos';
import Login from './components/login/Login';
import PrincipalPlaneacion from './components/planeacion/PrincipalPlaneacion';
import PrincipalDocentes from './components/docentes/PrincipalDocentes';
import PrincipalAlumnos from './components/alumnos/PrincipalAlumnos';
import AsignarHorarios from './components/Grupos/AsignarHorarios'; 
import Programas from './components/programas/Programas';
import Cursos from './components/cursos/PrincipalCursos';
import MateriaDocente from './components/cursos/MateriaDocente';
import MateriaTareas from './components/cursos/MateriaTareas';
import MateriaAsistencia from './components/cursos/MateriaAsistencia';
import Calificaciones from './components/calificaciones/Calificaciones';

import CalendarioAdmin from './components/Calendario/CalendarioAdmin';
import PrivateRouteAdmin from './private-routes/PrivateRouteAdmin';
import PrivateRoutedDocente from './private-routes/PrivateRouteDocente';
import PrivateRouteAlumno from './private-routes/PrivateRouteAlumno';
import DocenteHorario from './components/docentes/DocenteHorario';
import CalendarioDocente from './components/Calendario/CalendarioDocente';
import PrincipalCursosAlumno from './components/cursos/PrincipalCursosAlumno';
import MateriaTaresAlumno from './components/cursos/MateriaTareasAlumno';
import MateriaEditTareaAlumnos from './components/cursos/MateriaEditTareaAlumno';
import AlumnoHorario from './components/alumnos/AlumnoHorario';
import CalendarioAlumno from './components/Calendario/CalendarioAlumno';
import CalificarTarea from './components/tareas/CalificarTarea';

import 'antd/dist/antd.css';
import './components/styles/animated.css';
import { ToastContainer } from 'react-toastify';
import ListaTareas from './components/tareas/ListaTareas';
import PrincipalSolicitudes from './components/solicitudes/PrincipalSolicitudes';
import Configuracion from './components/configuración/Configuracion';
import PrivateRouteAll from './private-routes/PrivateRouteAll';
import Contacto from './components/contacto/Contracto';
import CalificacionesPublico from './components/calificaciones/CalificacionesPublico';


function App() {

  const auth = useSelector(state => state.Authentication.courrentUser.isSignedIn);
  const role = useSelector(state => state.Authentication.usuario.role_id);
  const fecha = new Date();
  return (
    <>
      <Router>
        <ToastContainer style={{ opacity: 0.8 }}/>
        
        <div class="wrapper">
          <NavBar/>
          <Menu/>
          
          <div className="content-wrapper" style={{ background: `${auth ? '#FFFF' : '#e9ecef'}` }}> 
              <div className="container-fluid ">
                
                <Switch>
                  <Route path="/" exact component={Login} />
                  <PrivateRouteAdmin    path="/grupos"                        component={PrincipalGrupos}         exact auth={auth} role={role}/>
                  <PrivateRouteAdmin    path="/planeacion"                    component={PrincipalPlaneacion}     exact auth={auth} role={role}/>
                  <PrivateRouteAdmin    path="/docentes"                      component={PrincipalDocentes}       exact auth={auth} role={role}/>
                  <PrivateRouteAdmin    path="/asignacion/horarios"           component={AsignarHorarios}         exact auth={auth} role={role}/>
                  <PrivateRouteAdmin    path="/alumnos"                       component={PrincipalAlumnos}        exact auth={auth} role={role}/>
                  <PrivateRouteAdmin    path="/programas"                     component={Programas}               exact auth={auth} role={role}/>
                  <PrivateRouteAdmin    path="/calendario-admin"              component={CalendarioAdmin}         exact auth={auth} role={role}/>
                  <PrivateRouteAdmin    path="/solicitudes"                   component={PrincipalSolicitudes}    exact auth={auth} role={role}/>
                  <PrivateRoutedDocente path="/cursos"                        component={Cursos}                  exact auth={auth} role={role}/>
                  <PrivateRoutedDocente path="/cursos/materia"                component={MateriaDocente}          exact auth={auth} role={role}/>
                  <PrivateRoutedDocente path="/cursos/materia/tareas"         component={MateriaTareas}           exact auth={auth} role={role}/>
                  <PrivateRoutedDocente path="/cursos/materia/asistencia"     component={MateriaAsistencia}       exact auth={auth} role={role}/>
                  <PrivateRoutedDocente path="/cursos/materia/calificaciones" component={Calificaciones}          exact auth={auth} role={role}/>
                  <PrivateRoutedDocente path="/docente/horario"               component={DocenteHorario}          exact auth={auth} role={role}/>
                  <PrivateRoutedDocente path="/docente/calendario"            component={CalendarioDocente}       exact auth={auth} role={role}/>
                  <PrivateRoutedDocente path="/curso/alumno/tarea"            component={CalificarTarea}          exact auth={auth} role={role}/>
                  <PrivateRoutedDocente path="/curso/alumno/tareas"           component={ListaTareas}             exact auth={auth} role={role}/>
                  <PrivateRouteAlumno   path="/alumno/cursos"                 component={PrincipalCursosAlumno}   exact auth={auth} role={role}/>
                  <PrivateRouteAlumno   path="/alumno/tareas"                 component={MateriaTaresAlumno}      exact auth={auth} role={role}/>
                  <PrivateRouteAlumno   path="/alumno/tarea/edit"             component={MateriaEditTareaAlumnos} exact auth={auth} role={role}/>
                  <PrivateRouteAlumno   path="/alumno/horario"                component={AlumnoHorario}           exact auth={auth} role={role}/>
                  <PrivateRouteAlumno   path="/alumno/calendario"             component={CalendarioAlumno}        exact auth={auth} role={role}/>
                  <PrivateRouteAll      path="/configuracion"                 component={Configuracion}           exact auth={auth} role={role}/>
                  <PrivateRouteAll      path="/contacto"                      component={Contacto}                exact auth={auth} role={role}/>
                  <Route name="calificaciones" path="/consulta-calificaciones" component={CalificacionesPublico} exact/>
              </Switch>
              </div>
          </div>
          <footer className="main-footer">
            <strong>Copyright &copy; {fecha.getFullYear()} <a href="#"></a>.</strong>
              Todos los derechos reservados
            <div className="float-right d-none d-sm-inline-block">
              <b>Version</b> 2.1
            </div>
          </footer>
        </div>
        </Router>
      </>
  );
}

export default App;
