import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation} from 'react-router-dom';

const MenuAdministrador = ({ date }) => {

    const location = useLocation();
    const solicitudesList = useSelector(state => state.Solicitudes.solicitudesList)
    return ( 
        <>
            {/* <ul className="nav nav-pills nav-sidebar flex-column nav-legacy" data-widget="treeview" role="menu" data-accordion="false"> */}
                
                <li className="nav-item">
                  <Link to="/grupos" className={`nav-link ${location.pathname === '/grupos' ? 'active' : null} ${location.pathname === '/planeacion' ? 'active' : null} ${location.pathname === '/asignacion/horarios' ? 'active' : null}`}>
                    <i className="nav-icon fa fa-university"></i>
                    <p>
                      Grupos
                    </p>           
                  </Link>
                </li>
                <li className="nav-item">
                <Link to="/docentes" className={`nav-link ${location.pathname === '/docentes' ? 'active' : null} `}>
                  <i className="nav-icon fas fa-chalkboard-teacher"></i>
                  <p>
                    Docentes
                  </p>           
                </Link>
                </li>
                <li className="nav-item">
                  <Link to="/alumnos" className={`nav-link ${location.pathname === '/alumnos' ? 'active' : null} `}>
                    <i className="nav-icon fas fa-graduation-cap"></i>
                    <p>
                      Alumnos
                    </p>           
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/programas" className={`nav-link ${location.pathname === '/programas' ? 'active' : null} `}>
                    <i className="nav-icon fas fa-list-alt"></i>
                    <p>
                      Plan de estudios
                    </p>           
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/calendario-admin" className={`nav-link ${location.pathname === '/calendario-admin' ? 'active' : null} `}>
                    <i className="nav-icon far fa-calendar-alt"></i>
                    <p>
                      Calendario
                    </p>           
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/solicitudes" className={`nav-link ${location.pathname === '/solicitudes' ? 'active' : null} `}>
                    
                    <i className="nav-icon far fa-bell"></i>
                    <p>
                      Solicitudes
                      {solicitudesList.length > 0 && <span class="right badge badge-danger">{solicitudesList.length}</span>}
                    </p>           
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link">
                    <i className="nav-icon fas fa-clock"></i>
                    <p>
                    {date.toLocaleTimeString('en-US')}
                    </p>
                  </a>
                </li>
                
              {/* </ul> */}
        </>
     );
}
 
export default MenuAdministrador;