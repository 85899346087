import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { OverlayTrigger, Button, Tooltip } from 'react-bootstrap';
import { registrarAltaGrupo, registrarBajaGrupo } from '../../redux/actions/Grupos.actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
    } ) => (
    <div className="btn-group" role="group">
    {
        options.map((option) => {

        const isSelect = currSizePerPage === `${option.page}`;
        return (
            <button
                key={ option.text }
                type="button"
                onClick={ () => onSizePerPageChange(option.page) }
                className={ `btn ${isSelect ? 'btn-secondary' : 'btn-primary'}` }
            >
            { option.text }
            </button>
        );
        })
    }
    </div>
);

const options = {
    sizePerPageRenderer
}
const AlumnosAsignacionGrupo = ({ open, setOpenModalGrupos }) => {

    const dispatch = useDispatch();

    const { gruposList, loadingList, loading } = useSelector(state => state.Grupos);
    const [openModalConfim, setOpenModalConfim] = useState(false);
    const [operacion, setOperacion] = useState(0);
    const [grupoId, setgrupoId] = useState(null);
    const [matricula, setMatricula] = useState(null);
    const alumnoStore = useSelector(state => state.Alumnos.alumno);

    const openComfirmacion = (open, operacion, grupo_id) => {
        setOperacion(operacion);
        setOpenModalConfim(open);
        setgrupoId(grupo_id);
        if(open) {
            let grupo = gruposList.filter(grup => grup.id === grupo_id)[0];
            setMatricula(grupo.matricula+grupo_id+alumnoStore.id);
        } else {
            setMatricula(null);
        }
        
    };

    const guardar = () => {
        if(operacion === 0) {
            dispatch( registrarAltaGrupo(grupoId, matricula, alumnoStore.id) );
        } else {
            dispatch( registrarBajaGrupo(grupoId, matricula, alumnoStore.id) );
        }
        setOperacion(0);
        setOpenModalConfim(false);
        setgrupoId(null);
        
    }

    const columns = [ {
        dataField: 'grupo',
        text: 'Grupo',
        sort: true,
        align: 'left',
        headerAlign: 'center',
        style: {
            width: '20%',
        }
      }, {
        dataField: 'sede',
        text: 'Sede',
        sort: true,
        align: 'left',
        headerAlign: 'center',
        style: {
            width: '20%',
        }
      }, {
        dataField: 'programa',
        text: 'Programa',
        sort: true,
        align: 'left',
        headerAlign: 'center',
        style: {
            width: '30%',
        }
      },{
        dataField: 'ciclo',
        text: 'Ciclo',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        style: {
            width: '20%',
        }
      }, {
        dataField: '3',
        text: 'Acciones',
        sort: true,
        align: 'center', 
        headerAlign: 'center',
        style: {
            width: '10%'
        },
        formatter: (cell, row) => {
            return (
                <div className="btn-group">
                    {row.asignacionBandera ? 
                    <OverlayTrigger overlay={<Tooltip id={row.grupo}>Dar de baja grupo</Tooltip>}>
                    <Button variant="danger" size="sm" onClick={() =>  openComfirmacion(true, 1, row.id) }><i class="fa fa-calendar-times"></i></Button>
                    </OverlayTrigger>
                    :
                    <OverlayTrigger overlay={<Tooltip id={row.grupo}>Dar de alta grupo</Tooltip>}>
                        <Button variant="success" size="sm" onClick={() => openComfirmacion(true, 0, row.id) }><i class="fa fa-calendar-plus"></i></Button>
                    </OverlayTrigger>
                    }
                </div>
            );
        }
    }];

    return ( 
        <>
            <Modal
                size="lg"
                show={open}
                onHide={() => setOpenModalGrupos(false) }
                centered
                dialogClassName="modal-dialog modal-xl"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    
                    <h4>Asignacion del programa</h4>
                    
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="col-lg-12 text-center mb-3" hidden={!loading}>
                            <div className="alert alert-primary " style={{ opacity: 0.5 }}>
                            <FontAwesomeIcon icon={faSpinner} spin/> Guardando información, por favor espere...
                            </div>
                            
                        </div>
                        <div className="col-lg-12 ">
                            <BootstrapTable 
                                classes="table table-striped" 
                                keyField='id' 
                                defaultSorted={ [{
                                    dataField: 'id',
                                    order: 'desc'
                                }]} 
                                data={ gruposList } 
                                columns={ columns } 
                                pagination={ paginationFactory(options) }
                                noDataIndication={loadingList ? <div><FontAwesomeIcon icon={faSpinner} spin/> Cargando información, por favor espere...</div> : "No se encontrarón resultados."}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={openModalConfim}
                onHide={() => openComfirmacion(false, 0, null) }
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <h6>{operacion === 0 ? 'Confirmación de Alta' : null} {operacion === 1 ? 'Confirmación de Baja' : null}</h6>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                {operacion === 0 ? `¿Esta seguro de dar de alta al alumno en el grupo seleccionado?` : null}
                                {operacion === 1 ? '¿Esta seguro de dar de baja al alumno en el grupo seleccionado?' : null}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="col-lg-12">
                        <button type="button" className="btn btn-danger float-left btn-sm" data-dismiss="modal" onClick={() => openComfirmacion(false, 0, null) }><i className="fa fa-times"></i> Cancelar</button>
                        <button type="button" className="btn btn-success float-right btn-sm" onClick={() => guardar()}><i className="fa fa-save" ></i> Guardar</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
     );
}
 
export default AlumnosAsignacionGrupo;