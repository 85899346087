import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//acciones
import { getDatosPlaneaciónGrupo, registrarPlaneacionModulos, resetSFE, getDatosPlaneacionGrupoSuccess } from '../../redux/actions/Planeacion.actions';
import { registrarModulo, editarModulo, resetSFE as resetSFEModulos } from '../../redux/actions/Modulos.actions';
import { getDatosMateriaPlan } from '../../redux/actions/Materias.actions';
import { getDatosGrupo } from '../../redux/actions/Grupos.actions';

import { OverlayTrigger, Button, Tooltip } from 'react-bootstrap';

//componentes
import PlaneacionEdit from './PlaneacionEdit';
import Encabezado from '../recursos/Encabezado';

const PrincipalPlaneacion = () => {

    const [open, setopen] = useState(false);
    const [noModulos, setnoModulos] = useState(0);
    const [datosModulo, setdatosModulo] = useState({ fecha: '', hora: '', planeacion_id: 0, titulo: '' });
    const [semestreSeleccionado, setsemestreSeleccionado] = useState(null);
    const [planeacionEdit, setplaneacionEdit] = useState(false);

    const dispatch = useDispatch();

    const parametros = useSelector(storeState => storeState.Planeacion.parametros);
    const { planeacionGrupo, loading, error, loadingPlanModulos, errorPlanModulos,  } = useSelector(storeState => storeState.Planeacion);
    const grupo = useSelector(storeState => storeState.Grupos.grupo);
    const loadingGrupo = useSelector(storeState => storeState.Grupos.loading);
    const materia = useSelector(storeState => storeState.Materias.materia);
    const loadingMateria = useSelector( storeState => storeState.Materias.loading);
    const success = useSelector( storeState => storeState.Planeacion.registroSuccess);
    const successModulo = useSelector( storeState => storeState.Modulos.registroSuccess);

    useEffect(() => {

        dispatch( getDatosPlaneaciónGrupo(parametros.grupo_id, parametros.programa_id) );
        dispatch( getDatosGrupo(parametros.grupo_id) );
    
    }, []);

    useEffect(() => {
        if(success) {
            dispatch( getDatosPlaneacionGrupoSuccess(parametros.grupo_id, parametros.programa_id) );
            dispatch( getDatosMateriaPlan(materia.id) );
        }
        dispatch( resetSFE() );
        dispatch( resetSFEModulos() );
    }, [success]);

    useEffect(() => {
        if (successModulo) {
            dispatch ( getDatosPlaneacionGrupoSuccess(parametros.grupo_id, parametros.programa_id) );
            dispatch ( getDatosMateriaPlan(materia.id) );
        }
        dispatch( resetSFE() );
        dispatch( resetSFEModulos() );
    }, [successModulo]);

    useEffect(() => {
        setdatosModulo({
            ...datosModulo,
            titulo: materia ? materia.materia_nombre ? materia.materia_nombre.nombre_materia : null : null,
            planeacion_id: materia ? materia.planeacion ? materia.planeacion.id : 0 : 0
        });
    }, [materia]);

    useEffect(() => {
        if (planeacionGrupo !== null) {
            setsemestreSeleccionado({
                nombre: planeacionGrupo[0].semestre,
                fechas: planeacionGrupo[0].fechasSemestre
            });
        }
    }, [planeacionGrupo]);

    const openClose = (open) => {
            setopen(open)
            setplaneacionEdit(false); 
    };

    const clickPlaneacion = (id, planeacionEdit) => {
        setopen(true)
        setplaneacionEdit(planeacionEdit); 
        dispatch( getDatosMateriaPlan(id) );
    }

    const addPlaneacionModulos = (materia_id) => {
        dispatch( registrarPlaneacionModulos( noModulos !== undefined ? noModulos : 0, materia_id) );
    }

    const addModulo = () => {

        setdatosModulo({
            ...datosModulo,
            fecha: '',
            hora: ''
        });
        dispatch( registrarModulo(datosModulo ) );

    }

    const changeDatosModulo = (e) => {
        setdatosModulo({
            ...datosModulo,
            [e.target.name]: e.target.value
        });
    }

    return (
        <>
        <title>CCH | Planeación</title>
        <Encabezado/>
        <div className="row animated fadeIn">
                
                <div className="col-lg-12">
                    <div className="callout callout-info">
                        {loadingGrupo ?
                            <h5><i className="fas fa-spinner fa-spin"></i></h5>
                        : <h5>{grupo !== null ? grupo.programa ? grupo.programa.nombre_programa : null : null}</h5>}
                        {loadingGrupo ?
                            <span className="text-muted"><i className="fas fa-spinner fa-spin"></i></span>
                        : <span className="text-muted">Grupo: {grupo !== null ? grupo.nombre : null}</span>}
                         <span className="float-right"><strong>{semestreSeleccionado !== null ? semestreSeleccionado.nombre : null}</strong>: {semestreSeleccionado !== null ? semestreSeleccionado.fechas !== null ? `${semestreSeleccionado.fechas.fecha_inicio} - ${semestreSeleccionado.fechas.fecha_fin}` : 'No existe registro.' : 'No existe registro.'} </span>  
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-4">
                        <div className="info-box bg-light">
                            <div className="info-box-content">
                            <span className="info-box-text text-center text-muted">Planeación del 0% a 50%</span>
                            <span className="info-box-number text-center text-muted mb-0"><i className="fas fa-info-circle text-danger"></i></span>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4">
                        <div className="info-box bg-light">
                            <div className="info-box-content">
                            <span className="info-box-text text-center text-muted">Planeación del 51% a 99%</span>
                            <span className="info-box-number text-center text-muted mb-0"><i className="fas fa-exclamation-triangle text-warning"></i></span>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4">
                        <div className="info-box bg-light">
                            <div className="info-box-content">
                            <span className="info-box-text text-center text-muted">Planeación al 100%</span>
                            <span className="info-box-number text-center text-muted mb-0"><i className="fas fa-check-circle text-success"></i> </span>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                </div>
                <div className="col-lg-12">
        
                    <div className="card">
                    <div className="card-header d-flex p-0">
                        <h3 className="card-title p-3"><strong>Semestres</strong></h3>
                        <ul className="nav nav-pills ml-auto p-2">
                        {planeacionGrupo !== null ? planeacionGrupo.map((planeacion, Index) => {
                            return (
                            <li className={`nav-item`}>
                                <a className={`nav-link ${Index === 0 ? 'active' : ''} `} href={`#tab_${planeacion.id}`} data-toggle="tab"> 
                                    <i className="far fa-bookmark"></i> {planeacion.semestre}
                                </a>
                            </li>   
                            )
                        }) : null}
                        
                        </ul>
                    </div>
                    <div className="card-body p-0">
                        
                        <div className="tab-content">
                        
                        {planeacionGrupo !== null ? planeacionGrupo.map((planeacion, Index) => {
                            return (
                            
                            <div className={`tab-pane ${Index === 0 ? 'active' : ''}`} id={`tab_${planeacion.id}`}>
                                
                                    <table className="table table-striped projects">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '55%' }}>
                                                    Materia
                                                </th>
                                                <th style={{ width: '5%' }} >
                                                    Modulos
                                                </th>
                                                <th style={{ width: '15%' }} className="text-center">
                                                    Estatus
                                                </th>
                                                <th style={{ width: '20%' }} className="text-center">
                                                    Progreso
                                                </th>
                                                <th style={{ width: '5%' }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {loading ?
                                            <tr className="text-center"><td colSpan={5}><i className="fas fa-spinner fa-spin"></i> Cargando información...</td></tr>
                                        : 
                                        planeacion.materias.map((materia) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <a>
                                                            {materia.nombre}
                                                        </a>
                                                        <br/>
                                                        <small>
                                                            Creado el {new Date(materia.created_at).toLocaleDateString()}
                                                        </small>
                                                    </td>
                                                    <td className="text-center">
                                                    <strong>{materia.planeacionModulos}/{materia.noModulos}</strong>
                                                    </td>
                                                    <td  className="project-state">
                                                            {materia.colorBandera === 'bg-danger' ? <i className="fas fa-info-circle text-danger"></i> : null }
                                                            {materia.colorBandera === 'bg-warning' ? <i className="fas fa-exclamation-triangle text-warning"></i> : null }
                                                            {materia.colorBandera === 'bg-success' ? <i className="fas fa-check-circle text-success"></i> : null }
                                                    </td>
                                                    <td className="project_progress">
                                                    
                                                    <small>
                                                    <div className="progress">
                                                        <div className={`progress-bar ${materia.colorBandera}`} role="progressbar" 
                                                        style={{width: `${materia.porcentajePlaneacion ? materia.porcentajePlaneacion.toFixed(2) : 0}%`}} 
                                                        aria-valuenow={materia.porcentajePlaneacion ? materia.porcentajePlaneacion.toFixed(2) : 0} 
                                                        aria-valuemin={0} 
                                                        aria-valuemax={100}>
                                                            {materia.porcentajePlaneacion ? materia.porcentajePlaneacion.toFixed(2) : 0}%
                                                        </div>
                                                    </div>
                                                    {materia.porcentajePlaneacion ? materia.porcentajePlaneacion.toFixed(2) : 0}% Completado
                                                    </small>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            overlay={
                                                                <Tooltip id={materia.id}>
                                                                {planeacion.banderaHabilitar ? 'Ir a planeación de módulos' : 'Consultar módulos'}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Button variant={`${planeacion.banderaHabilitar ? 'success' : 'info'}`} size="sm" onClick={() => clickPlaneacion(materia.id, planeacion.banderaHabilitar)}><i className="fas fa-swatchbook" ></i></Button>
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
        
                            </div>
                            )
                        }) : null}
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <PlaneacionEdit
                open={open}
                openClose={openClose}
                loadingMateria={loadingMateria}
                loadingPlanModulos={loadingPlanModulos}
                planeacionEditBandera={planeacionEdit}
                materia={materia}
                datosModulo={datosModulo}
                changeDatosModulo={changeDatosModulo}
                setNoModulos={setnoModulos}
                registrarModulo={addModulo}
                editarModulo={editarModulo}
                addPlaneacionModulos={addPlaneacionModulos}//
            />
        </>
      );
}
 
export default PrincipalPlaneacion;