import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUsuario, resetAuth } from '../../redux/actions/Login.actions';
import { reset as resetGrupos } from '../../redux/actions/Grupos.actions';
import { reset as resetPlaneacion } from '../../redux/actions/Planeacion.actions';
import { reset as resetValidaciones } from '../../redux/actions/Validacion.actions';
import { resetAlumnos } from '../../redux/actions/Alumnos.action';
import { resetModulos } from '../../redux/actions/Modulos.actions';
import { resetDocentes } from '../../redux/actions/Docentes.actions';
import { resetMaterias } from '../../redux/actions/Materias.actions';
import { resetProgramas } from '../../redux/actions/Programas.actions';
import { resetSedes } from '../../redux/actions/Sedes.actions';
import { reset as resetTareas } from '../../redux/actions/Tareas.action';

import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faHandshake, faIdCard, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';

const NavBar = () => {
  
  const location = useLocation();
  const dispatch = useDispatch();

  /* REDUX */
  const [openCerrarSesion, setOpenCerraSesion] = useState(false);
  const isSignedIn = useSelector(state => state.Authentication.courrentUser.isSignedIn);
  const usuario = useSelector(state => state.Authentication.usuario);
  const solicitudesList = useSelector(state => state.Solicitudes.solicitudesList);
  /* END REDUX  */

  const cerrarSesion = () => {
    
    dispatch( resetAuth() );
    dispatch( resetGrupos() );
    dispatch( resetValidaciones() );
    dispatch( resetPlaneacion() );
    dispatch( resetModulos() );
    dispatch( resetAlumnos() );
    dispatch( resetDocentes() );
    dispatch( resetMaterias() );
    dispatch( resetProgramas() );
    dispatch( resetSedes() );
    dispatch( resetTareas() );

    setOpenCerraSesion( false );
  }

  const getFecha = (fecha) => {
    let f = new Date(fecha);
    const MESES = [ "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    const DIAS = ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"];
    return `${DIAS[f.getDay()]} ${f.getDate()+1} de ${MESES[f.getMonth()]} del ${f.getFullYear()}`;
  };

    return (
      <>
      
      <nav className="main-header navbar navbar-expand navbar-dark navbar-light text-light" style={{ background: '#794578' }}>
        
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
          </li>
          
        </ul>
    
        <ul className="navbar-nav ml-auto" hidden={!isSignedIn}>
        
          <li className="nav-item dropdown" hidden={Number(usuario.role_id) === 1 ? false : true}>
            <a className="nav-link" data-toggle="dropdown" href="#">
              <i className="far fa-bell"></i>
              {solicitudesList.length > 0 && <span className="badge badge-danger navbar-badge">{solicitudesList.length}</span>}
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" >
              <span className="dropdown-item dropdown-header">{solicitudesList.length} Solicitudes</span>
              <div className="dropdown-divider"></div>
              {(solicitudesList.length > 0)  &&
                solicitudesList.map(solicitud => {
                  return (
                    <>
                    <a href="#" className="dropdown-item">
                      <FontAwesomeIcon icon={faHandshake}/> {solicitud.docente && `${solicitud.docente.nombre} ${solicitud.docente.apellido_paterno}`}
                      <span className="float-right text-muted text-sm">{getFecha(solicitud.created_at)}</span>
                    </a>
                    <div className="dropdown-divider"></div>
                    </>
                  )
                })
              }
              
              <Link to="/solicitudes" className="dropdown-item dropdown-footer">Ver todas las Solicitudes</Link>
            </div>
          </li>
          {isSignedIn ? 
          <li className="nav-item d-none d-sm-inline-block">
            <Link to="/configuracion" className={`nav-link ${location.pathname === '/configuracion' ? 'active' : null} `}>
              <FontAwesomeIcon icon={faCog}/> Configuración
            </Link>
          </li>
          : null}
          {isSignedIn ? 
          <li className="nav-item d-none d-sm-inline-block">
            <Link to="/contacto" className={`nav-link ${location.pathname === '/contacto' ? 'active' : null} `}>
              <FontAwesomeIcon icon={faIdCard}/> Contacto
            </Link>
          </li>
          : null}
          {isSignedIn ? 
          <li className="nav-item d-none d-sm-inline-block">
            <button className="btn btn-link nav-link" onClick={() => setOpenCerraSesion( true )} ><FontAwesomeIcon icon={faSignOutAlt}/> Salir</button>
          </li>
          : null}
          {/* <li class="nav-item">
            <a class="nav-link" data-widget="fullscreen" href="#" role="button">
              <i class="fas fa-expand-arrows-alt"></i>
            </a>
          </li> */}
        </ul>
      </nav>
     
      <Modal
        show={openCerrarSesion}
        onHide={() => setOpenCerraSesion( false )}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
            <Modal.Title>
                <h5>Cerrar Sesión</h5>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿ Estas seguro de terminar tu sesión ?</p>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-danger btn-sm" onClick={() => setOpenCerraSesion( false )}><i className="fa fa-times"></i> Cancelar</button>
          <button type="button" className="btn btn-success btn-sm" onClick={cerrarSesion}><i className="fa fa-check"></i> Aceptar</button>
        </Modal.Footer>
      </Modal>
      </>
    );
};

export default NavBar;