import React, { Component, useEffect, useState } from 'react';
import { OverlayTrigger, Button, Tooltip, Modal } from 'react-bootstrap';
import { editarModulo } from '../../redux/actions/Modulos.actions';
import { useDispatch } from 'react-redux';

const PlaneacionEdit = (props) => {

        const [seleccionado_id, setSelecionado] = useState(null);
        const [fecha, setFecha] = useState('');
        const [hora, setHora] = useState('');
        const dispatch = useDispatch();
        const seleccionarModuloEdit = (fecha, hora, modulo) => {

            setSelecionado(modulo);
            setFecha(fecha);
            setHora(hora);

        }

        const cancelarModuloEdit = () => {
            setSelecionado(null);
            setFecha('');
            setHora('');
        }

        const guardarDatosModulo = (modulo_id) => {
            dispatch( editarModulo({
                modulo_id: modulo_id,
                fecha: fecha,
                hora: hora
            }, '') );

            setSelecionado(null);
            setFecha('');
            setHora('');
            
        }
        
        return (
            <>
                <Modal
                    size="lg"
                    show={props.open}
                    onHide={() => props.openClose(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                    centered
                    scrollable
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    <h4>{/* <i className="fas fa-swatchbook"></i> */} Planeación de módulos</h4>
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-lg-12 mb-3 text-center" hidden={!props.loadingMateria}>
                                <span className="text-muted"><i className="fas fa-spinner fa-spin"></i> Cargando información por favor espere...</span>
                            </div>
                            <div className="col-lg-12 mb-3" hidden={props.loadingMateria}>
                                <div className="callout callout-secondary">
                                <h5><i className="fas fa-swatchbook"></i> {props.materia !== null ? props.materia.materia_nombre !== null ? props.materia.materia_nombre.nombre_materia : null : null}</h5>
                                </div>
                                
                            </div>
                            <div className="col-lg-12">
                                <div className={`card card-outline card-primary`}>
                                    <div className="card-header">
                                        <h3 className="card-title"><i className="fab fa-slack-hash"></i> Número de módulos</h3>

                                        <div className="card-tools">
                                        <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className={'fas fa-minus'}></i>
                                        </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        {props.materia !== null ? props.materia.planeacion === null ? 
                                            props.planeacionEditBandera ?
                                            <div className="row" >
                                                <div className="col-lg-6">
                                                
                                                <input type="number" name="fecha" className="form-control" placeholder="Ingresa el número de módulos"
                                                onChange={(e) => props.setNoModulos(e.target.value)}
                                                />
                                                    
                                                </div>
                                                <div className="col-lg-6">
                                                    <button className="btn btn-primary" onClick={() => props.addPlaneacionModulos(props.materia.id)}>
                                                    {props.loadingMateria ? 
                                                    <i className="fas fa-spinner fa-spin"></i>
                                                    :<i className="fa fa-save"></i>} Guardar
                                                    </button>
                                                </div>
                                            </div>
                                            : 
                                            <div className="col-lg-12 text-center text-muted">
                                            <h5>Opción no disponible.</h5>
                                            </div>
                                         : null : null}
                                        {props.materia !== null ? 
                                            props.materia.planeacion !== null ? 
                                                <div className="row">
                                                    <div className="col-lg-12 text-center">
                                                        <strong className="text-muted">Módulos asignados</strong>
                                                    </div>
                                                    <div className="col-lg-12 text-center">
                                                        {props.loadingMateria ? <i className="fas fa-spinner fa-spin"></i> : <h1>{props.materia.planeacion.no_modulos}</h1>}
                                                    </div>
                                                </div>
                                            :null 
                                        :null}
                                        
                                    </div>
                                    
                                </div>
                                
                            </div>
                            {/* -------------------------- */}
                            {props.materia !== null ? 
                                props.materia.planeacion !== null ? 
                                    props.materia.planeacion.no_modulos !== props.materia.planeacion.modulos.length ? 
                                        props.planeacionEditBandera ?
                                        <div className="col-lg-12">
                                            <div className="card card-outline card-success">
                                                <div className="card-header">
                                                    <h3 className="card-title"><i className="fas fa-calendar-plus"></i> Agregar Módulo</h3>
                                                    <div className="card-tools">
                                                    <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-minus"></i>
                                                    </button>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row" /* hidden={materia.planeacion === null ? false : true} */>
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label>Fecha:</label>
                                                                    <input type="date" name="fecha" className="form-control"
                                                                    value={props.datosModulo.fecha}
                                                                    onChange={(e) => props.changeDatosModulo(e)}/>
                                                            
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label>Hora:</label>
                                                                <input type="time" name="hora" className="form-control"
                                                                value={props.datosModulo.hora}
                                                                onChange={(e) => props.changeDatosModulo(e)}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <button className="btn btn-success float-right" onClick={() => props.registrarModulo()}>
                                                                <i className="fa fa-save"></i> Guardar
                                                            </button>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            
                                        </div> : null
                                    :null 
                                :null
                            :null}
                            {/* -------------------------------- */}
                            <div className="col-lg-12">
                                <div className="card card-outline card-danger">
                                    <div className="card-header">
                                        <h3 className="card-title"><i className="fas fa-calendar"></i> Lista de módulos</h3>

                                        <div className="card-tools">
                                        <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-minus"></i>
                                        </button>
                                        </div>
                                    </div>
                                    <div className="card-body table-responsive p-0">
                                        <table className="table table-striped table-valign-middle">
                                        <thead>
                                        <tr>
                                            <th style={{ width: '40%' }}>Fecha</th>
                                            <th style={{ width: '40%' }}>Hora</th>
                                            <th style={{ width: '20%' }} hidden={!props.planeacionEditBandera}>

                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {
                                            props.materia ?
                                                props.materia.planeacion !== null ? 
                                                    props.materia.planeacion.modulos.length > 0 ? 
                                                        props.materia.planeacion.modulos.map((modulo) => {
                                                            return (
                                                                <tr>
                                                                    <td className="align-middle">
                                                                        {seleccionado_id === modulo.id ?
                                                                            <input type="date" name="fecha" className="form-control" value={fecha} onChange={(e) => setFecha(e.target.value)}/>
                                                                        : modulo.fecha}
                                                                        
                                                                    </td>
                                                                    <td className="align-middle">
                                                                        {seleccionado_id === modulo.id ?
                                                                            <input type="time" name="hora" className="form-control" value={hora} onChange={(e) => setHora(e.target.value)}/>
                                                                        : modulo.hora}
                                                                        
                                                                    </td>
                                                                    <td className="align-middle text-center" /* hidden={!props.planeacionEdit} */>
                                                                        <OverlayTrigger
                                                                            overlay={
                                                                                <Tooltip id={modulo.id}>
                                                                                Modificar
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <Button variant="warning" /* size="sm" */hidden={seleccionado_id === modulo.id ? true : false}
                                                                            onClick={() => seleccionarModuloEdit(modulo.fecha , modulo.hora, modulo.id)}>
                                                                                <i className="fas fa-edit" ></i>
                                                                            </Button>
                                                                        </OverlayTrigger>
                                                                        <div className="btn-group" role="group" hidden={seleccionado_id === modulo.id ? false : true}>
                                                                            <OverlayTrigger
                                                                                overlay={
                                                                                    <Tooltip id={modulo.id}>
                                                                                    Guardar
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <Button variant="success" /* size="sm" */
                                                                                onClick={() => guardarDatosModulo(modulo.id)}>
                                                                                    <i className="fas fa-save" ></i>
                                                                                </Button>
                                                                                
                                                                            </OverlayTrigger>
                                                                            <OverlayTrigger
                                                                                overlay={
                                                                                    <Tooltip id={modulo.id}>
                                                                                    Cancelar
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <Button variant="danger" /* size="sm" */
                                                                                onClick={() => cancelarModuloEdit()}>
                                                                                    <i className="fas fa-times" ></i>
                                                                                </Button>
                                                                            </OverlayTrigger>
                                                                            
                                                                        </div>
                                                                        
                                                                    </td>
                                                                </tr>  
                                                            )
                                                        })
                                                    
                                                    : 
                                                    <tr className="text-center">
                                                        <td colSpan={3}>No se encontrarón resultados</td>
                                                    </tr>
                                                : 
                                                <tr className="text-center">
                                                    <td colSpan={3}>No se encontrarón resultados</td>
                                                </tr>
                                            : null}
                                            
                                            
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="col-lg-12">
                            <button type="button" className="btn btn-danger float-right" data-dismiss="modal" onClick={() => props.openClose(false)}><i className="fa fa-times"></i> Salir</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );

}

export default PlaneacionEdit;


