import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { OverlayTrigger, Button, Tooltip, Form } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPercent, faSync } from '@fortawesome/free-solid-svg-icons';
//Actions
import { 
    obtenerListaAlumnos, 
    resetDatosAlumno, 
    setDatosAlumno, 
    registrarDatosAlumno, 
    modificarDatosAlumno,
    obtenerHorarioAlumno,
    setHorario
} from '../../redux/actions/Alumnos.action';
import { solicitudCambioContrasena } from '../../redux/actions/Docentes.actions';
import { obtenerListaGruposAsignacion, setGuposList } from '../../redux/actions/Grupos.actions';
//Componentes
import AlumnosEdit from './AlumnosEdit';
import AlumnosConsulta from './AlumnosConsulta';
import HorarioAlumnoModal from './HorarioAlumnoModal';
import AlumnosAsignacionGrupo from './AlumnosAsignacionGrupo';
import Encabezado from '../recursos/Encabezado';
import ModalCalificacionesAlumno from './ModalCalificacionesAlumno';

const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
    } ) => (
    <div className="btn-group" role="group">
    {
        options.map((option) => {

        const isSelect = currSizePerPage === `${option.page}`;
        return (
            <button
                key={ option.text }
                type="button"
                onClick={ () => onSizePerPageChange(option.page) }
                className={ `btn ${isSelect ? 'btn-secondary' : 'btn-primary'}` }
            >
            { option.text }
            </button>
        );
        })
    }
    </div>
);

const options = {
    sizePerPageRenderer
}

const PrincipalAlumnos = () => {

    const [openModalAlumno, setOpenModalAlumno] = useState(false);
    const [vistaPrevia, setvistaPrevia] = useState(null);
    const [openConsulta, setOpenConsulta] = useState(false);
    const [solicitudUserId, setSolicitudUserId] = useState(null);
    const [controlModalSolicitud, setControlModalSolicitud] = useState(false);
    const [openHorario, setOpenHorario] = useState(false);
    const [openModalGrupos, setOpenModalGrupos] = useState(false);
    const [matriculaId, setMatriculaId] = useState(null);
    const [alumnoId, setAlumnoId] = useState(null);
    const [matriculas, setMatriculas] = useState([]);
    const [openCalificacion, setOpenCalificacion] = useState(false);
    const [alumnoValidacion, setAlumnoValidacion] = useState({
        nombre: false,
        apellidoP: false,
        correo: false,
        matricula: false,
        correoInvalido: false
    });
    const [alumno, setAlumno] = useState({
        id: null,
        nombre: null,
        apellido_paterno: null,
        apellido_materno: null,
        email: null, 
        telefono: null,
        matricula: null,
        cedula: null,
        certificado: null,
        titulo: null,
        acta_nacimiento: null,
        curp: null,
        foto: null,
        user_id: null
    });

    const componentRef = useRef();
    
    const { alumnosList, loading, errorAlumno, loadingList } = useSelector(state => state.Alumnos);
    const alumnoStore = useSelector(state => state.Alumnos.alumno);
    
    
    const dispatch = useDispatch();

    const openEditarAlumno = (row) => {
        setOpenModalAlumno(true);
        dispatch (setDatosAlumno(row)); 
    }

    const changeImg = (e) => {
        const img = e.target.files[0];
        const imgURL = URL.createObjectURL(img);
        setvistaPrevia(imgURL);
        setAlumno({ ...alumno, [e.target.name]: e.target.files[0] });
    }

    const handleChangeAlumno = (e) => {
        
        if (e.target.name === 'email') {
            let campo = e.target;
            let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
            
            if (emailRegex.test(campo.value)) {
                setAlumnoValidacion({ ...alumnoValidacion, correoInvalido: false });
            } else {
                setAlumnoValidacion({ ...alumnoValidacion, correoInvalido: true});
            }
        }

        let alumnoNew = {...alumno, [e.target.name]: e.target.value !== '' ? e.target.value : null};
       
        setAlumno(alumnoNew);
    }

    const handleChangeFiles = (e) => {
        setAlumno({ ...alumno, [e.target.name]: e.target.files[0] });
    }

    const getHorarioAlumno = (alumno_id, matriculas) => {
        setAlumnoId(alumno_id);
        setOpenHorario(true);
        setMatriculas(matriculas);
    } 

    const openConsultaAlumno = (open, row) => {
        setOpenConsulta(open);
        dispatch( setDatosAlumno(row) );
    }

    const guardarDatosAlumno = () => {
        setAlumnoValidacion({
            ...alumnoValidacion, 
            nombre: alumno.nombre !== null ? false : true,
            apellidoP: alumno.apellido_paterno !== null ? false : true,
            correo: alumno.email !== null ? false : true
        });
    
        if (alumno.nombre !== null && alumno.apellido_paterno && alumno.email !== null && !alumnoValidacion.correoInvalido) {
            
            let formData  = new FormData();
            formData.append('id',alumno.id);
            formData.append('nombre',alumno.nombre);
            formData.append('apellido_paterno',alumno.apellido_paterno);
            formData.append('apellido_materno',alumno.apellido_materno === null ? '' : alumno.apellido_materno);
            formData.append('email',alumno.email);
            formData.append('matricula',alumno.matricula);
            formData.append('telefono',alumno.telefono === null ? '' : alumno.telefono );
            formData.append('foto', 
                alumno.foto !== null ? 
                    alumno.foto.name ? alumno.foto : ''
                : ''
            );
            formData.append('titulo', 
                alumno.titulo !== null ? 
                    alumno.titulo.name ? alumno.titulo : '' 
                : ''
            );
            formData.append('acta_nacimiento', 
                alumno.acta_nacimiento !== null ? 
                    alumno.acta_nacimiento.name ? alumno.acta_nacimiento : ''
                : ''
            );
            formData.append('certificado', 
                alumno.certificado !==  null ? 
                    alumno.certificado.name ? alumno.certificado : ''
                : ''
            );
            formData.append('cedula', 
                alumno.cedula !==  null ? 
                    alumno.cedula.name ? alumno.cedula : ''
                : ''
            );
            formData.append('curp', 
                alumno.curp !==  null ? 
                    alumno.curp.name ? alumno.curp : ''
                : ''
            );
            
            if (alumno.id === null) {
                dispatch( registrarDatosAlumno( formData ) );
            } else {
                dispatch( modificarDatosAlumno( formData, alumno.id) );
            }
            
        }

    };

    const openModalSolicitud = (user_id) => {
        setSolicitudUserId(user_id);
        setControlModalSolicitud(true);        
    }

    const openModalCalificacion = (alumno) => {
        dispatch( setDatosAlumno(alumno) );
        setOpenCalificacion(true);
    }

    const openModalGruposAsignar = (alumno, open) => {
        setOpenModalGrupos(open);
        dispatch( setDatosAlumno(alumno) );
        dispatch( obtenerListaGruposAsignacion(alumno.id) );
    }

    const solicitarContrasena = () => {
        dispatch( solicitudCambioContrasena(solicitudUserId) );
        setSolicitudUserId(null);
        setControlModalSolicitud(false);
    }

    const seleccionarHorario = (matriculaId) => {
        if(matriculaId !== '0') {
            dispatch( obtenerHorarioAlumno(alumnoId, matriculaId) ); 
        }
        
    }
    

    useEffect(() => {

        dispatch( obtenerListaAlumnos({}) );
        

    }, []);

    useEffect(() => {
        if (alumnoStore.id !== null) {
            let alumnoNew = alumnosList.filter(alumno => alumno.id === alumnoStore.id);
            dispatch(setDatosAlumno(alumnoNew[0]) );
        }
    }, [alumnosList]);

    useEffect(() => {
        setAlumno(alumnoStore);
    }, [alumnoStore]);

    useEffect(() => {
        if(!openHorario) {
            dispatch( setHorario(null) );
        }
    }, [openHorario])
    useEffect(() => {
        if(!openModalAlumno) {
            dispatch( resetDatosAlumno() ); 
        }
    }, [openModalAlumno]);

    useEffect(() => {
        if(!openConsulta) {
            dispatch( resetDatosAlumno() );
        }
    }, [openConsulta]);

    useEffect(() => {
        if(!loading) {
            if (!errorAlumno) {
                setOpenModalAlumno(false);
            }
        }
    }, [loading]);

    useEffect(() => {
        if (!openModalGrupos) {
            dispatch( setGuposList([]) );
        }
    }, [openModalGrupos])

    useEffect(() => {
        return () => {
            dispatch( setGuposList([]) );
        }
    }, []);


    const columns = [ {
        dataField: 'matricula',
        text: 'Matriculas',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        style: {
            width: '10%',
        },
        formatter: (cell, row) => {
            return ( 
                <select class="form-control form-control-sm">
                   
                    {row.matriculas.map(mat => {
                        return (
                            <option value={mat.id}>{mat.matricula}</option>
                        )
                    })}
                    {row.matriculas.length === 0 ?
                        <option value='0'>Sin matriculas</option>
                    : null}
                </select>
            ); 
        }
      }, {
        dataField: 'nombre',
        text: 'Nombre',
        sort: true,
        align: 'left',
        headerAlign: 'center',
        filter: textFilter({ placeholder: 'Nombre', className: 'form-control-sm' }),
        style: {
            width: '10%',
        }
      }, {
        dataField: 'apellido_paterno',
        text: 'Apellido paterno',
        sort: true,
        align: 'left',
        headerAlign: 'center',
        style: {
            width: '10%',
        },
        filter: textFilter({ placeholder: 'Apellido paterno', className: 'form-control-sm' })
      },{
        dataField: 'apellido_materno',
        text: 'Apellido materno',
        sort: true,
        align: 'left',
        headerAlign: 'center',
        style: {
            width: '10%',
        },
        formatter: (cell, row) => (row.apellido_materno !== null ? row.apellido_materno : '--'),
        filter: textFilter({ placeholder: 'Apellido materno', className: 'form-control-sm' })
      }, {
        dataField: 'email',
        text: 'Correo',
        sort: true,
        align: 'left',
        headerAlign: 'center',
        style: {
            width: '20%'
        },
        filter: textFilter({ placeholder: 'Correo electrónico', className: 'form-control-sm' })
      }, {
        dataField: 'telefono',
        text: 'Teléfono',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        style: {
            width: '10%'
        },
        formatter: (cell, row) => (row.telefono !== null ? row.telefono : '--'),
        filter: textFilter({ placeholder: 'Teléfono', className: 'form-control-sm' })
      }, {
        dataField: '1',
        text: 'Estatus de registro',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        style: {
            width: '15%'
        },
        formatter: (cell, row) => {
            let contador = 0;
            if (row.matricula !== null) {
                contador++;
            }
            if (row.nombre !== null) {
                contador++;
            }
            if (row.apellido_paterno !== null) {
                contador++;
            }
            if (row.apellido_materno !== null) {
                contador++;
            }
            if (row.email !== null) {
                contador++;
            }
            if (row.telefono !== null) {
                contador++;
            }
            if (row.titulo !== null) {
                contador++;
            }
            if (row.certificado !== null) {
                contador++;
            }
            if (row.acta_nacimiento !== null) {
                contador++;
            }
            if (row.curp !== null) {
                contador++;
            }
            if (row.cedula !== null) {
                contador++;
            }
            if (row.foto !== null) {
                contador++;
            }
            let porcentaje = ((contador/12)*100).toFixed(2);
            let color = 'bg-danger';

            if(porcentaje > 0 && porcentaje < 50){
                color = 'bg-danger';
            }
            if(porcentaje > 50 && porcentaje < 100){
                color = 'bg-primary';
            }
            if(porcentaje >= 100){
                color = 'bg-success';
            }

            return (
                <div className="progress">
                    <div className={`progress-bar ${color}`} style={{width: porcentaje+'%'}}>{porcentaje+'%'}</div>
                </div>
            );
        },
      }, {
        dataField: '3',
        text: 'Acciones',
        sort: true,
        aling: 'center', 
        headerAlign: 'center',
        style: {
            width: '15%'
        },
        formatter: (cell, row) => {
            return (
                <div className="btn-group">
                    <OverlayTrigger overlay={<Tooltip id={row.id}>Alta de Programas</Tooltip>}>
                        <Button variant="success" size="sm" onClick={() => openModalGruposAsignar(row, true)}><i class="fa fa-calendar-plus"></i></Button>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip id={row.id}>Modificar Datos del Alumno</Tooltip>}>
                        <Button variant="warning" onClick={() => openEditarAlumno(row)} size="sm"><i class="fa fa-edit"></i></Button>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip id={row.id}>Consultar Datos del Alumno</Tooltip>}>
                        <Button variant="secondary" onClick={() => openConsultaAlumno(true, row)} size="sm"><i class="fa fa-address-card"></i></Button>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip id={row.id}>Consultar Horario del Alumno</Tooltip>}>
                        <Button variant="primary" onClick={() => getHorarioAlumno(row.id, row.matriculas)} size="sm" disabled={row.matriculas.length > 0 ? false : true}><i class="fa fa-clock"></i></Button>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip id={row.id}>Solicitar nueva contraseña</Tooltip>}>
                        <Button variant="danger" onClick={() => openModalSolicitud(row.user_id) } size="sm"><i class="fa fa-key"></i></Button>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip id={row.id}>Registrar calificaciones</Tooltip>}>
                        <Button variant="light" onClick={() => openModalCalificacion(row) } size="sm"> <FontAwesomeIcon icon={faPercent}/> </Button>
                    </OverlayTrigger>
                </div>
            );
        }
    }];

    return (
        <>
        <title>CCH | Alumnos</title>
        <Encabezado></Encabezado>
            <div className="row animated fadeIn">
                <div className="col-lg-12 mb-3">
                    <OverlayTrigger overlay={<Tooltip id={(1000).toString()}>Registrar Alumno</Tooltip>}>
                        <Button variant="primary" className="float-right" onClick={() => setOpenModalAlumno(true)} style={{ borderRadius: '50%' }}><i className="fas fa-plus" ></i></Button>
                    </OverlayTrigger>
                </div>
                <div className="col-lg-12">
                    <div className="card shadow bg-white rounded">
                     <div className="card-header">
                        <h3 className="card-title">Lista de Alumnos</h3>
                        {loadingList ?
                            <div className="float-right">
                                <FontAwesomeIcon icon={faSync} spin/>
                            </div>
                            
                        : null}
                    </div> 
                    <div className="card-body table-responsive p-2">
                        <div className="row">
                            <div className="col-lg-12">
                            <BootstrapTable 
                                classes="table table-striped" 
                                keyField='id' 
                                defaultSorted={ [{
                                    dataField: 'id',
                                    order: 'desc'
                                }]} 
                                data={ alumnosList } 
                                columns={ columns } 
                                pagination={ paginationFactory(options) }
                                filter={ filterFactory() }
                                filterPosition="top"
                                noDataIndication="No se encontrarón resultados."
                            />
                            </div>
                        </div>
                    </div>
                    
                    </div>
                   
                </div>
            </div>
            <AlumnosEdit 
                open={openModalAlumno} 
                setOpenModalAlumno={setOpenModalAlumno}
                vistaPrevia={vistaPrevia}
                changeImg={changeImg}
                handleChangeFiles={handleChangeFiles}
                alumno={alumno}
                alumnoValidacion={alumnoValidacion}
                handleChangeAlumno={handleChangeAlumno}
                guardarDatosAlumno={guardarDatosAlumno}
            />
            <AlumnosConsulta
                open={openConsulta}
                openClose={setOpenConsulta}
            />
            <HorarioAlumnoModal 
                openHorario={openHorario}
                setOpenHorario={setOpenHorario}
                setMatriculaId={setMatriculaId}
                matriculas={matriculas}
                seleccionarHorario={seleccionarHorario}
            />
            <AlumnosAsignacionGrupo
                open={openModalGrupos}
                setOpenModalGrupos={setOpenModalGrupos}
            />
            <ModalCalificacionesAlumno
                open={openCalificacion}
                setOpen={setOpenCalificacion}
            />
            
            <Modal
                show={controlModalSolicitud}
                onHide={() => { setControlModalSolicitud(false); setSolicitudUserId(null) } }
                centered
                static
            >
                <Modal.Header>
                    
                    <h6>Solicitud de Cambio de Contraseña</h6>
                    
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="col-lg-12 text-center">
                        Se enviara un correo con la nueva contraseña<br></br>
                        ¿Esta seguro de cambiar las credenciales del alumno seleccionado?
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-danger btn-sm " onClick={() => setControlModalSolicitud(false)}>
                        <i className="fa fa-times"></i> Cancelar
                    </button>
                    <button type="button" className="btn btn-success btn-sm " onClick={() => solicitarContrasena()}>
                        <i className="fa fa-check"></i> Confirmar
                    </button>
                </Modal.Footer>
            </Modal>
            
        </>
    );

}

export default PrincipalAlumnos;