import React, { useState, useEffect } from 'react';
import { login_acceso, resetUsuario } from '../../redux/actions/Login.actions';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSign, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { RESOURCE_URL } from '../recursos/url';
import { setCalificaciones } from '../../redux/actions/Materias.actions';

const Login = () => {

    const [usuario, setUsuario] = useState('');
    const [contrasena, setContrasena] = useState('');
    const [errorUsuario, setErrorUsuario] = useState('');
    const [errorContrasena, setErrorContrasena] = useState('');

    const dispatch = useDispatch();
    const history = useHistory();

    const usuarioStorage = useSelector(storeState => storeState.Authentication.usuario);
    const { isLoading, error, courrentUser } =  useSelector(storeState => storeState.Authentication);
    const isSignedIn = useSelector(state => state.Authentication.courrentUser.isSignedIn);
    
    useEffect(() => {
        const initialAuthentication = () => {
            if(courrentUser.isSignedIn) {
                switch(Number(usuarioStorage.role_id)){
                    case 1: 
                        history.push("/grupos");
                    break;
                    case 2: 
                        history.push('/cursos');
                        break;
                    case 3:
                        history.push('/alumno/cursos');
                        break;
                    default: 
                        history.push('/');
                }
            }
        }
        initialAuthentication();
        dispatch( setCalificaciones([]) );
    },[]);

    useEffect(() => {
      if(isSignedIn){
        if(isSignedIn) {
            switch(Number(usuarioStorage.role_id)){
                case 1: 
                    history.push("/grupos");
                break;
                case 2: 
                    history.push('/cursos');
                    break;
                case 3:
                    history.push('/alumno/cursos');
                    break;
                default: 
                    history.push('/');
            }
        }
      }
    }, [isSignedIn])
    

    const validarEmail = (valor) => {

        if (/^([\da-z_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/.test(valor)){
          return true;
        } else {
         return false;
        }
  
    };

    const submitLogin = () => {

        const email = validarEmail(usuario);
        
        if(email && contrasena !== ''){
            dispatch( login_acceso( usuario, contrasena) );
        } else {
            
            let errorUsuario = '';
            let errorContrasena = '';
            
            if (!email) {
               errorUsuario = 'Usuario incorrecto, el usuario debe ser correo electronico';
            }

            if (contrasena === '') {
                errorContrasena = "Ingresa la contraseña.";
            }
            setErrorUsuario(errorUsuario);
            setErrorContrasena(errorContrasena);
        }
        
    };

    const submitLoginUser = () => {
        
        const email = validarEmail(usuarioStorage.email);

        if(contrasena !== ''){
            dispatch( login_acceso( usuarioStorage.email, contrasena) );
            setErrorUsuario('');
            setErrorContrasena('');
        } else {
            let errorUsuario = '';
            let errorContrasena = '';
            
            if (!email) {
               errorUsuario = 'Usuario incorrecto, el usuario debe ser correo electronico';
            }
            
            if (contrasena === '') {
                errorContrasena = "Ingresa la contraseña.";
            }
            setErrorUsuario(errorUsuario);
            setErrorContrasena(errorContrasena);
        }
        
    }

    const submitOtroUsuario = () => {
        dispatch ( resetUsuario() )
    };


    return (
        <>
        
        <div className="row justify-content-center">
        <title>CCH | Login</title>
            <div className="col-lg-3 text-center mt-3" hidden={usuarioStorage.id !== 0 ? true : false}>
                {/* <div className="col-lg-3 mt-3  rounded-circle" hidden={usuarioStorage.id !== 0 ? true : false}> */}
                <div className="card card-outline card-primary" style={{ width: '20rem' }} >
                    <div className="col-lg-12 mt-3">
                    <img src="/img/cch.jpg" className="card-img-top mx-auto" alt="..." style={{ width: '150px' }}/>
                    </div>
                    {/* <div class="card-header text-center">
                    <a href="../../index2.html" class="h1"><b></b>CCH</a>
                    </div> */}
                    <div className="card-body">
                        <p class="login-box-msg">Tiene que estar registrado para iniciar su sesión</p>
                        <div className="row">
                            <div className="col-lg-12 mb-3 text-center" hidden={!isLoading}>
                                <i className="fa fa-spinner fa-spin"></i> Espere un momento...
                            </div>
                            <div className="col-lg-12 alert alert-danger" style={{ opacity: 0.9 }} hidden={!error}>
                                <i className="icon fas fa-ban"></i> Usuario o contraseña incorrectos, intenta de nuevo.
                            </div>
                            <div className="col-lg-12 mb-3">
                                <div className="input-group mb-3">
                                     
                                    <input
                                        type="email" 
                                        name="usuario"
                                        className="form-control" 
                                        placeholder="Correo electrónico"
                                        onChange={e => setUsuario(e.target.value)}
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                        <span className="fas fa-envelope"></span>
                                        </div>
                                    </div>
                                </div>
                                <span  className="text-danger text-sm">{errorUsuario}</span>
                               
                            </div>
                            <div className="col-lg-12">
                                <div className="input-group mb-3">
                                    <input 
                                        type="password" 
                                        className="form-control" 
                                        name="contrasena"
                                        placeholder="Contraseña"
                                        onChange={e => setContrasena(e.target.value)}
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                        <span className="fas fa-lock"></span>
                                        </div>
                                    </div>
                                </div>
                                <span  className="text-danger text-sm">{errorContrasena}</span>
                            </div>
                            
                            <div className="col-lg-12 text-right">
                                <button type="submit" className="btn btn-primary btn-block"
                                onClick={submitLogin}> <FontAwesomeIcon icon={faSignInAlt}/> Entrar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12 mb-2" hidden={usuarioStorage.id !== 0 ? false : true}>
                <div className="lockscreen-wrapper">
                    <div className="lockscreen-logo">
                        <a href="#"><b></b>{usuarioStorage.name}</a>
                    </div>
                    <div className="lockscreen-name">
                        
                    </div>

                    <div className="lockscreen-item">
                        <div className="lockscreen-image">
                        {Number(usuarioStorage.role_id) === 2 ?
                            usuarioStorage.docente.foto !== null ? 
                            <img src={`${RESOURCE_URL}/img/doc_docente/${usuarioStorage.id}/${usuarioStorage.docente.foto}`} alt="..."/>
                            : 
                            <img src={`${RESOURCE_URL}/img/perfil.png`} alt="..."/>
                        : null}
                        {Number(usuarioStorage.role_id) === 3 ?
                            usuarioStorage.alumno.foto !== null ? 
                            <img src={`${RESOURCE_URL}/img/doc_alumno/${usuarioStorage.id}/${usuarioStorage.alumno.foto}`} alt="..."/>
                            : 
                            <img src={`${RESOURCE_URL}/img/perfil.png`} alt="..."/>
                        : null}
                        {Number(usuarioStorage.role_id) === 1 ?
                            <img src={`${RESOURCE_URL}/img/perfil.png`} alt="..."/>
                        : null}
                        {Number(usuarioStorage.role_id) === 0 ?
                            <img src={`${RESOURCE_URL}/img/perfil.png`} alt="..."/>
                        : null}
                        </div>
 
                        <div className="lockscreen-credentials">
                        <div className="input-group">
                            
                            <input type="password" name="contrasena" className="form-control" placeholder="Contraseña" 
                                onChange={e => setContrasena(e.target.value)}
                            />
                            <div className="input-group-append rounded">
                            <button type="button" className="btn" onClick={() => submitLoginUser()}><i className="fas fa-arrow-right text-muted"></i></button>
                            </div>
                            
                        </div>
                        
                        </div>
                        
                    </div>
                    <div className="col-lg-12 mb-3 text-center" >
                    <span  className="text-danger text-sm">{errorContrasena}</span>
                    </div>
                    <div className="col-lg-12 alert alert-danger" style={{ opacity: 0.9 }} hidden={!error}>
                                <i className="icon fas fa-ban"></i> Datos incorrectos, intenta de nuevo.
                            </div>
                    <div className="col-lg-12 mb-3 text-center" hidden={!isLoading}>
                        <i className="fa fa-spinner fa-spin"></i> Espere un momento...
                    </div>
                    
                    <div className="help-block text-center">
                    Ingrese su contraseña para recuperar su sesión
                    </div>
                    <div className="text-center">
                        <button className="btn btn-link" onClick={submitOtroUsuario}>O inicie sesión como un usuario diferente</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default Login;