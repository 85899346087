import clienteAxios from '../../config/axios';
import { mensajeCentral, mensajeSuccessToastify, mensajeWarningToastify, mensajeDangerToastify, msjError } from '../../components/recursos/mensajes';
import { getDatosPlanAsignacion } from '../actions/Planeacion.actions';
import store from "../../store";

import {
    CARGAR_MODULOS,
    LOADING_MODULOS,
    ERROR_MODULO,
    CARGAR_MODULO,
    LOADING_MODULO,
    ERROR_MODULOS,
    RESET_MODULOS,
    REGISTRO_EXITO,
    REGISTRO_FALLIDO_MODULO
} from '../../types';

const token = `${store.getState().Authentication.courrentUser.token_type} ${store.getState().Authentication.courrentUser.access_token}`;
const Planeacion = store.getState().Planeacion;

export function registrarModulo (datos) {
    
    const { planeacion_id, fecha, hora, titulo } = datos;
    
    return (dispatch) => {
        dispatch( setLoading ( true ) );
        clienteAxios.post ('modulo/crear-modulo',
            { planeacion_id, fecha, hora, titulo },
            {
                headers: {
                    'Authorization' : token
                }
            }).then(response => {

                dispatch ( setRegistroSuccess ( true ) );
                if (response.data.success) {
                    mensajeSuccessToastify(response.data.message);
                } else {
                    mensajeWarningToastify(response.data.message);
                }
                
                dispatch( setError(false) );
                
            }).catch(error => {
                dispatch ( setRegistroFallido ( true, 'Ocurrio un error al guardar, verifique su conexión de internet.' ) );
                dispatch( setError(true) );
                msjError(error);

            }).finally(final => {
                dispatch( setLoading ( false ) );
            });

    }

}

export function editarModulo (datos) {
    return (dispatch) => {

        const { modulo_id, fecha, hora } = datos;

        dispatch( setLoading(true) );
        
        //if (datosGrupo.nombre !== '' && datosGrupo.sede_id !== "0" && datosGrupo.programa_id !== "0" && datosGrupo.fecha_inicio !== '' && datosGrupo.fecha_fin !== '' ) {
            clienteAxios.put ('modulo/modificar-datos/' + modulo_id,
            {
                fecha : fecha,
                hora : hora
            },
            {
                headers: {
                    'Authorization' : token
                }
            }).then(response => {
    
                dispatch ( setRegistroSuccess ( true ) );
                mensajeCentral(response.data.message, response.data.success ? 'success' : 'warning');
                dispatch( setLoading(false) );
                dispatch ( setError ( false ) );
        
            }).catch(error => {
    
                dispatch ( setError ( true ) );
                dispatch( setLoading(false) );
                msjError(error);
    
            });
    }
}

export const asignarModulosDocente = (planeacion_id, docente_id) =>{
    return dispatch => {
        dispatch( setLoading ( true ) );
        clienteAxios.post('/asignar-modulos-docente',
            { planeacion_id, docente_id },
            {
                headers: {
                    'Authorization' : token
                }
            }).then(response => {

                if (response.data.success) {
                    mensajeSuccessToastify(response.data.message);
                    let parametros = Planeacion.parametros;
                    dispatch(getDatosPlanAsignacion(parametros.grupo_id, parametros.programa_id));
                } else {
                    mensajeWarningToastify(response.data.message);
                }
                dispatch( setError(false) );
                
            }).catch(error => {
                
                msjError(error);

            }).finally(final => {
                dispatch( setLoading ( false ) );
            });
    }
}

export const eliminarModulosDocente = (planeacion_id, docente_id) =>{
    return dispatch => {
        dispatch( setLoading ( true ) );
        clienteAxios.post('/eliminar-modulos-docente',
            { planeacion_id, docente_id },
            {
                headers: {
                    'Authorization' : token
                }
            }).then(response => {

                if (response.data.success) {
                    mensajeSuccessToastify(response.data.message);
                    let parametros = Planeacion.parametros;
                    dispatch(getDatosPlanAsignacion(parametros.grupo_id, parametros.programa_id));
                } else {
                    mensajeWarningToastify(response.data.message);
                }
                dispatch( setError(false) );
                
            }).catch(error => {
                
                msjError(error);

            }).finally(final => {
                dispatch( setLoading ( false ) );
            });
    }
}

export const obtenerModulos = () => {
    return dispatch => {
        clienteAxios.get ('modulos/all',
            {
                headers: {
                    'Authorization' : token
                }
                
            }).then(response => {

                dispatch ( setModulosList ( response.data ) );
                
            }).catch(error => {
                msjError(error);
            });
    }
}

export const obtenerModulosDocente = (docenteId) => {
    return dispatch => {
        clienteAxios.get ('modulos/docente/'+docenteId,
            {
                headers: {
                    'Authorization' : token
                }
                
            }).then(response => {

                dispatch ( setModulosList( response.data ) );
                
            }).catch(error => {
                msjError(error);
            });
    }
}

export const obtenerModulosAlumno = (alumnoId) => {
    return dispatch => {
        clienteAxios.get('/modulos/alumno/'+alumnoId,
            {
                headers: {
                    'Authorization' : token
                }
                
            }).then(response => {

                dispatch ( setModulosList( response.data ) );
                
            }).catch(error => {
                msjError(error);
            });
    }
}

export const solicitudModulo = (solicitudId, planeacionId, docenteId, fecha, hora) =>{
    return dispatch => {
        dispatch( setLoading ( true ) );
        clienteAxios.post('/solicitud/modulo/nuevo',
            {   
                solicitudId: solicitudId,
                fecha: fecha, 
                hora: hora, 
                planeacionId: planeacionId, 
                docenteId: docenteId 
            },
            {
                headers: {
                    'Authorization' : token
                }
            }).then(response => {

                if (response.data.success) {
                    mensajeSuccessToastify(response.data.message);
                } else {
                    mensajeWarningToastify(response.data.message);
                }
                dispatch( setError(false) );
                
            }).catch(error => {
                msjError(error);
            }).finally(final => {
                dispatch( setLoading ( false ) );
            });
    }
}

export const setModulosList = (list) => {
    return {
        type: CARGAR_MODULOS,
        payload: list 
    }
}

export function resetSFE() {
    return (dispatch) => {
        dispatch ( setError ( false ) );
        dispatch ( setRegistroSuccess ( false ) );
        dispatch ( setRegistroFallido ( false, '' ) );
    }
}
export const setLoading = (bandera) => {
    return {
        type: LOADING_MODULO,
        payload: bandera
    }
}

export const setError = (bandera) => {
    return {
        type: ERROR_MODULO,
        payload: bandera
    }
}

export const resetModulos = () => {
    return {
        type: RESET_MODULOS
    }
}

export const setRegistroSuccess = (success) => {
    return {
        type: REGISTRO_EXITO,
        payload: success
    }
}

export const setRegistroFallido = (success, msj) => {
    return {
        type: REGISTRO_FALLIDO_MODULO,
        payload: { success, msj }
    }
}

