import { faBell, faCalendar, faCog, faUniversity, faUserClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation} from 'react-router-dom';

const Encabezado = () => {

    const auth = useSelector(state => state.Authentication.courrentUser);
    const location = useLocation();

    return (  
        <>
        <div className="row">
            <div className="col-lg-12">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                        <div className="col-lg-6">
                            {location.pathname === '/grupos' && <h1><i className="fas fa-users"></i>  Grupos</h1>}
                            {location.pathname === '/planeacion' && <h1><i className="fa fa-sitemap"></i> Planeación</h1>}
                            {location.pathname === '/asignacion/horarios' && <h1><i className="fas fa-thumbtack"></i>  Asignación de horarios</h1>}
                            {location.pathname === '/docentes' && <h1><i className="nav-icon fas fa-chalkboard-teacher"></i>  Docentes</h1>}
                            {location.pathname === '/alumnos' && <h1><i className="nav-icon fas fa-chalkboard-teacher"></i>  Alumnos</h1>}
                            {location.pathname === '/programas' && <h1><i className="fa fa-sitemap"></i> Plan de Estudio</h1>}
                            {(location.pathname === '/calendario-admin' || location.pathname === '/docente/calendario' || location.pathname === '/alumno/calendario') && <h1><i className="fa fa-calendar"></i> Calendario</h1>}
                            {(location.pathname === '/cursos' || location.pathname === '/alumno/cursos' ) && <h1><FontAwesomeIcon icon={faUniversity}/> Cursos</h1>}
                            {(location.pathname === '/docente/horario' || location.pathname === '/alumno/horario') && <h1><FontAwesomeIcon icon={faUserClock}/> Horarios</h1>}
                            {location.pathname === '/solicitudes' && <h1><FontAwesomeIcon icon={faBell}/> Solicitudes</h1>}
                            {location.pathname === '/configuracion' && <h1><FontAwesomeIcon icon={faCog}/> Configuración</h1>}
                        </div>
                        <div className="col-lg-6">
                            
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">CCH</a></li>
                                {location.pathname === '/grupos' && <li className="breadcrumb-item active">Grupos</li>}
                                {location.pathname === '/planeacion' && <li className="breadcrumb-item active">Planeación</li>}
                                {location.pathname === '/asignacion/horarios' && <li className="breadcrumb-item active">Asignación de Horarios</li>}
                                {location.pathname === '/docentes' && <li className="breadcrumb-item">docentes</li>}
                                {location.pathname === '/alumnos' && <li className="breadcrumb-item active">Alumnos</li>}
                                {location.pathname === '/programas' && <li className="breadcrumb-item active">Plan de Estudio</li>}
                                {(location.pathname === '/calendario-admin' || location.pathname === '/docente/calendario' || location.pathname === '/alumno/calendario')  && <li className="breadcrumb-item active">Calendario</li>}
                                {(location.pathname === '/cursos' || location.pathname === '/alumno/cursos' ) && <li className="breadcrumb-item active">Cursos</li>}
                                {(location.pathname === '/docente/horario' || location.pathname === '/alumno/horario') && <li className="breadcrumb-item active">Horarios</li>}
                                {location.pathname === '/solicitudes' && <li className="breadcrumb-item active">Solicitudes</li>}
                                {location.pathname === '/configuracion' && <li className="breadcrumb-item active">Configuracion</li>}
                            </ol>
                            
                        </div>
                        </div>
                       
                    </div>
                </section>
            </div>
            <div className="col-lg-12">
                <hr></hr>
            </div>
            <div className="col-lg-12 mb-3">
                <span className="badge badge-pill badge-info">
                <i className="far fa-clock"></i> La sesión expira a las {new Date(auth.dateExpire).toLocaleTimeString('en-US')}
                </span>
            </div>
        </div>
        
        </>
    );
}
 
export default Encabezado;