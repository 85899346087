import {
    VALIDACION_GRUPO,
    RESET_VALIDACION,
    VALIDACION_DOCENTE,
    RESET_VALIDACION_DOCENTE
} from '../../types';

const initialState = {
    grupoValidacion: {
        nombre: false,
        sede_id: false, 
        programa_id: false,
        fecha_inicio: false, 
        fecha_fin: false
    },
    docenteValidacion: {
        nombre: false,
        apellidoPaterno: false, 
        apellidoMaterno: false,
        email: false,
        telefono: false, 
        imgPerfil: false,
        fileCedula: false, 
        fileActa: false,
        fileCertificado: false
    }
};

export default function (state = initialState, action) {
    switch (action.type) {
        case VALIDACION_GRUPO:
            return {
                ...state,
                grupoValidacion: action.payload
            }
        case RESET_VALIDACION:
            return {
                ...state,
                grupoValidacion: {
                    nombre: false,
                    sede_id: false, 
                    programa_id: false,
                    fecha_inicio: false, 
                    fecha_fin: false
                }
            }
        case VALIDACION_DOCENTE:
            return {
                ...state,
                docenteValidacion: action.payload
            }
        case RESET_VALIDACION_DOCENTE:
            return {
                ...state,
                docenteValidacion: {
                    nombre: false,
                    apellidoPaterno: false, 
                    apellidoMaterno: false,
                    email: false,
                    telefono: false, 
                    imgPerfil: false,
                    fileCedula: false, 
                    fileActa: false,
                    fileCertificado: false
                }
            }
        default: 
            return state;
    }
}