import {
    SET_LISTA_ALUMNOS,
    SET_ALUMNO,
    SET_LOADING_ALUMNOS,
    SET_LOADING_ALUMNO,
    RESET_ALUMNOS,
    RESET_ALUMNO,
    SET_ERROR_ALUMNOS,
    SET_ERROR_ALUMNO,
    SET_HORARIO_ALUMNO,
    SET_LOADING_HORARIO_ALUMNO
} from '../../types';

const initialState = {
    alumnosList: [],
    alumno: {
        id: null,
        nombre: null,
        apellido_paterno: null,
        apellido_materno: null,
        email: null, 
        telefono: null,
        matricula: null,
        cedula: null,
        certificado: null,
        titulo: null,
        acta_nacimiento: null,
        curp: null,
        foto: null,
        user_id: null
    },
    errorListAlumnos: false,
    loadingList: false,    
    errorAlumno: false,
    loading: false,
    horario: null,
    loadingHorario: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_LISTA_ALUMNOS:
            return {
                ...state,
                alumnosList: action.payload
            }
        case RESET_ALUMNOS: 
            return {
                ...state,
                alumnosList: [],
                alumno: {
                    id: null,
                    nombre: null,
                    apellido_paterno: null,
                    apellido_materno: null,
                    email: null, 
                    telefono: null,
                    matricula: null,
                    cedula: null,
                    certificado: null,
                    titulo: null,
                    acta_nacimiento: null,
                    curp: null,
                    foto: null,
                    user_id: null
                },
                errorListAlumnos: false,
                loadingList: false,    
                errorAlumno: false,
                loading: false,
                horario: null,
                loadingHorario: false
            }
        case SET_LOADING_ALUMNOS:
            return {
                ...state,
                loadingList: action.payload
            }
        case SET_ERROR_ALUMNOS:
            return {
                ...state,
                errorListaAlumnos: action.payload
            }
        case SET_ERROR_ALUMNO:
            return {
                ...state,
                errorAlumno: action.payload
            }
        case SET_LOADING_ALUMNO: 
            return  {
                ...state,
                loading: action.payload
            }
        case SET_ALUMNO: 
            return {
                ...state,
                alumno: action.payload
            }
        case SET_HORARIO_ALUMNO: 
            return {
                ...state,
                horario: action.payload
            }
        case SET_LOADING_HORARIO_ALUMNO: 
            return {
                ...state,
                loadingHorario: action.payload
            }
        case RESET_ALUMNO:
            return {
                ...state,
                alumno: {
                    id: null,
                    nombre: null,
                    apellido_paterno: null,
                    apellido_materno: null,
                    email: null, 
                    telefono: null,
                    matricula: null,
                    cedula: null,
                    certificado: null,
                    titulo: null,
                    acta_nacimiento: null,
                    curp: null,
                    foto: null,
                    user_id: null
                }
            }
        default:
            return state;
    }
}