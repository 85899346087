import clienteAxios from '../../config/axios';
import { mensajeSuccessToastify, mensajeWarningToastify, mensajeDangerToastify, msjError } from '../../components/recursos/mensajes';
import store from "../../store";
import {
    SET_LISTA_ALUMNOS,
    SET_ALUMNO,
    SET_LOADING_ALUMNOS,
    SET_LOADING_ALUMNO,
    RESET_ALUMNOS,
    RESET_ALUMNO,
    SET_ERROR_ALUMNOS,
    SET_ERROR_ALUMNO,
    SET_HORARIO_ALUMNO,
    SET_LOADING_HORARIO_ALUMNO
} from '../../types';

const token = `${store.getState().Authentication.courrentUser.token_type} ${store.getState().Authentication.courrentUser.access_token}`;

export function obtenerListaAlumnos (filtro) {
    
    return ( dispatch) => {
        
        dispatch( loadingAlumnos (true) );
        clienteAxios.get('alumnos/all',
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {

            let listaAlumnos = response.data;
            
            dispatch ( setListaAlumnos(listaAlumnos) );
            dispatch( loadingAlumnos(false) );
            dispatch( errorAlumnos(false) );

        }).catch(error => {
            msjError(error);

            dispatch( errorAlumnos(true) );  

        }).finally(final => {

            dispatch( loadingAlumnos(false) );

        });
    };

}

export function obtenerListaAlumnosGrupo (grupoId) {
    
    return ( dispatch) => {
        
        dispatch( loadingAlumnos (true) );
        clienteAxios.get('alumnos/lista-alumnos-grupo/'+grupoId,
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {

            let listaAlumnos = response.data;
            
            dispatch ( setListaAlumnos(listaAlumnos) );
            dispatch( errorAlumnos(false) );
            
        }).catch(error => {
            msjError(error);
            dispatch( errorAlumnos(true) );  
        }).finally(final => {
            dispatch( loadingAlumnos(false) );
        });
    };

}

export const obtenerHorarioAlumno = (id, matriculaId) => {
    return dispatch => {
        dispatch( setLoadingHorario(true) );
        clienteAxios.get('/pdf/horario-alumno/'+id+'/'+matriculaId,
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {
            dispatch( setHorario(response.data) );
        }).catch(error => {
            msjError(error)
        }).finally(final => {
            dispatch( setLoadingHorario(false) );
        });
    }
};

export function registrarDatosAlumno (datosAlumno) {
    return (dispatch) => {
        
        dispatch( loadingAlumno(true) );
        
        clienteAxios.post('/alumno/registro',
        datosAlumno,
        {
            headers: {
                'Authorization' : token
            }
        }).then(response => {
            
            if (response.data.success) {
                mensajeSuccessToastify(response.data.message);
                dispatch ( errorAlumno ( false ) );
                dispatch( obtenerListaAlumnos({}) );
            } else {
                mensajeWarningToastify(response.data.message);
                dispatch ( errorAlumno ( true ) );
            }

        }).catch(error => {

            msjError(error);
            dispatch ( errorAlumno ( true ) );

        }).finally(final => {
            
            dispatch ( loadingAlumno(false) );
            
            
        });

    }
        
}

export function modificarDatosAlumno (datosDocente, id) {
    return (dispatch) => {

        const headers = { 
            'Authorization': token
        };

        dispatch( loadingAlumno(true) );
        clienteAxios.post('/alumno/modificar-datos/'+id, datosDocente, { headers }).then(response => {
            
            if (response.data.success) {

                mensajeSuccessToastify(response.data.message);
                dispatch ( errorAlumno ( false ) );
                dispatch( obtenerListaAlumnos({}) );

            } else {

                mensajeWarningToastify(response.data.message);
                dispatch ( errorAlumno ( true ) );
                
            }
                
        }).catch(error => {

            msjError(error);
            dispatch ( errorAlumno ( true ) );

        }).finally(final => {
            dispatch ( loadingAlumno(false) );
        });

    }
        
}


export const setListaAlumnos = (listaAlumnos) => {
    return {
        type: SET_LISTA_ALUMNOS,
        payload: listaAlumnos
    }
}

export const resetAlumnos = () => {
    return {
        type: RESET_ALUMNOS
    }
}

export const resetDatosAlumno = () => {
    return {
        type: RESET_ALUMNO
    }
}

export const loadingAlumnos = (loading) => {
    return {
        type: SET_LOADING_ALUMNOS,
        payload: loading
    }
}

export const errorAlumnos = (error) => {
    return {
        type: SET_ERROR_ALUMNOS,
        payload: error
    }
}

export const errorAlumno = (error) => {
    return {
        type: SET_ERROR_ALUMNO,
        payload: error
    }
}
export const loadingAlumno = (loading) => {
    return {
        type: SET_LOADING_ALUMNO,
        payload: loading
    }
}

export const setHorario = (horario) => {
    return {
        type: SET_HORARIO_ALUMNO,
        payload: horario
    }
};

export const setLoadingHorario = (flag) => {
    return {
        type: SET_LOADING_HORARIO_ALUMNO,
        payload: flag
    }
};

export const setDatosAlumno = (datos) => {
    return {
        type: SET_ALUMNO,
        payload: datos
    }
}


