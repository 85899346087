import React, { Component, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Element } from 'react-scroll';
import { RESOURCE_URL } from '../recursos/url';

const DocentesEdit = (props) => {
    return (
        <Modal
            size="lg"
            show={props.open}
            onHide={() => props.openClose(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            scrollable
            backdrop="static"
            keyboard={false}
            centered
        >
                <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                <h4><i className="fas fa-user"></i> {props.docente.id !== null ? 'Modificar' : 'Registrar'} datos del docente</h4>
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        {props.loading ?
                            <div className="alert alert-info text-center" style={{ opacity: 0.5 }}>
                                <i className="fas fa-spinner fa-spin"></i> Guardando información, por favor espere...
                            </div>
                        : null}
                    <form>
                        <div class="form-row">
                            <div class="form-group col-lg-2 text-center">
                                {props.vistaPrevia !== null ? 
                                    <img 
                                        src={props.vistaPrevia} 
                                        style={{ width: '90px', height: '90px' }}
                                        className='img-circle elevation-2'    
                                    />
                                :props.docente.imgPerfil !== null && props.vistaPrevia === null ?
                                    <img 
                                        src={`${RESOURCE_URL}/img/doc_docente/${props.docente.user.id}/${props.docente.imgPerfil}`}
                                        style={{ width: '90px', height: '90px' }}
                                        className='img-circle elevation-2' 
                                    />
                                : 
                                <img src={`${RESOURCE_URL}/img/perfil.png`} style={{ width: '90px', height: '90px' }} className='img-circle elevation-2'></img>}
                
                            </div>
                            <div class="form-group col-lg-10">
                                <label for="imgPerfil">Foto del docente</label>
                                <div class="custom-file">
                                    <input type="file" className="custom-file-input" id="imgPerfil" name="imgPerfil" lang="es" onChange={props.changeImg} disabled={props.loading}/>
                                    <label class="custom-file-label" for="fileActa">
                                        {props.docente.imgPerfil !== null ? 
                                            props.docente.imgPerfil.name ? props.docente.imgPerfil.name : props.docente.imgPerfil
                                        : 'Seleccionar archivo'}
                                    </label>
                                </div> 
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-lg-4">
                                <label for="nombre">Nombre *</label>
                                <input type="text" className={`form-control ${props.docenteValidacion.nombre ? 'is-invalid' : ''}`} id="nombre" name="nombre" placeholder="Nombre" onChange={props.changeInput} value={props.docente.nombre !== null ? props.docente.nombre : ''}  disabled={props.loading}/>
                                {props.docenteValidacion.nombre ? 
                                <div className="text-danger text-sm">
                                Este campo es obligatorio.
                                </div>
                                : null }
                                
                            </div>
                            <div class="form-group col-lg-4">
                                <label for="apellidoP">Apellido Paterno *</label>
                                <input type="text" className={`form-control ${props.docenteValidacion.apellidoP ? 'is-invalid' : ''}`} id="apellidoP" name="apellidoP" placeholder="Apellido Paterno" onChange={props.changeInput} value={props.docente.apellidoP !== null ? props.docente.apellidoP : ''}  disabled={props.loading}/>
                                {props.docenteValidacion.apellidoP ? 
                                <div className="text-danger text-sm">
                                    Este campo es obligatorio.
                                </div>
                                : null}
                            </div>
                            <div class="form-group col-lg-4">
                                <label for="apellidoM">Apellido Materno</label>
                                <input type="text" class="form-control" id="apellidoM" name="apellidoM" placeholder="Apellido Materno" onChange={props.changeInput} value={props.docente.apellidoM !== null ? props.docente.apellidoM : ''}  disabled={props.loading}/>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group input-group col-lg-6">
                                <label for="correo">Correo electrónico</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fa fa-envelope"></i></span>
                                    </div>
                                    <input type="email" className={`form-control ${props.docenteValidacion.correo ? 'is-invalid' : ''}`} id="correo" name="correo" placeholder="Correo electrónico " onChange={props.changeInput} value={props.docente.correo !== null ? props.docente.correo : ''}  disabled={props.loading}/>
                                </div>
                                
                                {props.docenteValidacion.correo ? 
                                <div className="text-danger text-sm">
                                    Este campo es obligatorio.
                                </div>
                                : null}
                                {props.docenteValidacion.correoInvalido ? 
                                <div className="text-danger text-sm">
                                    Correo invalido.
                                </div>
                                : null}
                            </div>
                            <div class="form-group input-group col-lg-6">
                                <label for="telefono">Teléfono</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-phone"></i></span>
                                    </div>
                                    <input type="text" class="form-control" id="telefono" name="telefono" placeholder="Teléfono" 
                                    onChange={props.changeInput} 
                                    value={props.docente.telefono !== null ? props.docente.telefono : ''}  
                                    disabled={props.loading}
                                    data-inputmask='"mask": "(999) 999-9999"' data-mask
                                    maxlength="10"/>
                                </div>
                                
                            </div>
                            
                        </div>
                        <div class="form-group">
                            <label for="fileActa">Acta de nacimiento</label>
                            <div class="custom-file">
                                <input type="file" className="custom-file-input" id="fileActa" name="fileActa" lang="es" onChange={props.changeFiles}  disabled={props.loading}/>
                                <label class="custom-file-label" for="fileActa">
                                    {props.docente.fileActa !== null ? 
                                        props.docente.fileActa.name ? props.docente.fileActa.name : props.docente.fileActa
                                    : 'Seleccionar archivo'}
                                </label>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="fileCedula">Cédula</label>
                            <div class="custom-file">
                                <input type="file" className="custom-file-input" id="fileCedula" name="fileCedula" lang="es" onChange={props.changeFiles} disabled={props.loading}/>
                                <label class="custom-file-label" for="fileActa">
                                    {props.docente.fileCedula !== null ? 
                                        props.docente.fileCedula.name ? props.docente.fileCedula.name : props.docente.fileCedula
                                    : 'Seleccionar archivo'}
                                </label>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="fileCertificado">Certificado</label>
                            <div class="custom-file">
                                <input type="file" className="custom-file-input" id="fileCertificado" name="fileCertificado" lang="es" onChange={props.changeFiles} disabled={props.loading}/>
                                <label class="custom-file-label" for="fileActa">
                                    {props.docente.fileCertificado !== null ? 
                                        props.docente.fileCertificado.name ? props.docente.fileCertificado.name : props.docente.fileCertificado
                                    : 'Seleccionar archivo'}
                                </label>
                            </div>
                        </div>
                        
                    </form>  
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-danger " data-dismiss="modal" onClick={() => props.openClose(false)}><i className="fa fa-times"></i> Cancelar</button>
                    <button type="button" className="btn btn-success" onClick={ props.guardarDatosDocente } disabled={props.docenteValidacion.correoInvalido || props.loading}><i className="fa fa-save"></i> Guardar</button>
                </Modal.Footer>
            </Modal>
    );

    
}

export default DocentesEdit;
