import {
    SET_ASISTENCIA_BANDERA,
    SET_LOADING_ASISTENCIA,
    SET_DIAS_CALIFICACIONES,
    SET_LOADING_DIAS
} from '../../types';

const initialState = {
    banderaAsistencia: false,
    diasCalificaciones: null,
    loadingDias: false,
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_ASISTENCIA_BANDERA:
            return {
                ...state,
                banderaAsistencia: action.payload
            }
        case SET_LOADING_ASISTENCIA:
            return {
                ...state,
                loading: action.payload
            }
        case SET_DIAS_CALIFICACIONES: 
            return { 
                ...state, 
                diasCalificaciones: action.payload
            }
        case SET_LOADING_DIAS: 
            return {
                ...state,
                loadingDias: action.payload
            }
        default:
            return state;
    }
}