import React, { useEffect, useState } from 'react'
import { Card, Table } from 'react-bootstrap';
import { Button, Form, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux'
import { getCalificacionesProv, setCalificaciones } from '../../redux/actions/Materias.actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const CalificacionesPublico = () => {

    const dispatch = useDispatch();
    const list = useSelector(state => state.Materias.calificacionesList);
    const loading = useSelector(state => state.Materias.loadingCalificaciones);


    const buscar = (values) => {
        dispatch( getCalificacionesProv(values.email) );
    }

    useEffect(() => {
      
      return () => {
        dispatch( setCalificaciones([]) );
      }
      
    }, []);
    
  return (
    <>
     <div className="row justify-content-center animated fadeIn">
        <div className='col-lg-8 mb-3 mt-3 text-center'>
            <h3>Consulta tus calificaciones</h3>
        </div>
        <div className='col-lg-6 mb-3 mt-3 text-center'>
            <Form
                layout='horizontal'
                onFinish={buscar}
            >
                <Form.Item 
                    name='email' 
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingresa un correo electrónico!'
                        },
                        { 
                            type: 'email',
                            message: 'Por favor ingresa un correo electrónico valido!' 
                        }
                    ]}
                >
                    <Input placeholder="Ingresa tu correo electronico" size="large" /* loading */ />  
                    
                </Form.Item>
                <Form.Item>
                    <Button 
                        htmlType='submit' 
                        type="primary"
                    >
                        Consultar
                    </Button>
                </Form.Item>
                
            </Form>
            
        </div>
        <div className='col-lg-10 mb-3'>
            <Card>
                <Card.Body className='p-0'>
                    <Table bordered hover>
                    <thead>
                        <tr>
                            <th>Materia</th>
                            <th>Programa</th>
                            <th>Semestre</th>
                            <th>Calificación</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loading && (list.map(cal => {
                                const { programa, semestre, materia, calificacion } = cal;
                                return (
                                    <tr>
                                        <td>{materia ? materia.nombre_materia : ''}</td>
                                        <td>{programa ? programa.nombre_programa : ''}</td>
                                        <td>{semestre ? semestre.nombre : ''}</td>
                                        <td className='text-center'>{calificacion}</td>
                                    </tr>
                                )
                            })
                        )}
                        {loading ? (
                            <tr>
                                <td colSpan={4} className="text-center h6"><FontAwesomeIcon icon={faCircleNotch} spin/> Cargando información, por favor espere...</td>
                            </tr>
                        ) : list.length === 0 && (
                            <tr>
                                <td colSpan={4} className="text-center h6">No se encontraron resultados.</td>
                            </tr>
                        )}
                    </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </div>
     </div>
    </>
  )
}

export default CalificacionesPublico;